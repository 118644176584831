import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Exile_1() {

    return {
        "date": <span>125 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>3 млн.</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>3</span>,
        "g": <span>1.02 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span><TextInlineLink name={"bs_star_last_exodus"} text={"Последнего Исхода"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_fleur_sector"} text={"Флёр"}/></span>,
        "type": <span>землеподобная, логистический центр колонизаторов</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "imgs": [{"short": "bs_wiki_exile_1_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Исход-1</span> – перевалочная база
                    для колонистов, отправляемых Приоритетом в <TextInlineLink name={"bs_space_samborae_sector"}
                                                                               text={"Самбору"}/> и за ее пределы
                    (иногда). <TextInlineLink name={"bs_spec_vitruvianus"} text={"Витрувианы"}/> похитили здесь около 3
                    миллионов человек и пока это последний подобный инцидент из задокументированных.</p>
            </>
    }
}

