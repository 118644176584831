export function Shield_static() {
    return {
        "science": <span>физика, информатика</span>,
        "use": <span>охранные системы</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Статический щит</span> или <span
                    className="strong">ЭМ-обертка</span> – устройство для создания электромагнитного поля для
                    подавления радиосвязи или нарушения работы других ЭМ-систем. В зависимости от назначения, может
                    как блокировать работу фотоаппаратов, так и нарушать радиосвязь или портить любую другую
                    технику. Иногда на целой планете. Некоторые особо мощные щиты могут даже нарушать работу
                    человеческого мозга или всей нервной системы.</p>
            </>
    }
}
