import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";
import imgs from '../../../../../components/cms/images_repo.json';

export function DeLevineRosalie() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Розали де Левин",
        "fullname": <span>Розали <small>«Выбери-уже-второе-имя»</small> де Левин</span>,
        "born": <span>26 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>25</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <span><TextInlineLink name={"bs_char_the_whip"}/> и <TextInlineLink
            name={"bs_char_de_levine_clara"}/></span>,
        "dad_adopt": <span><TextInlineLink name={"bs_char_de_karma_ivar"}/></span>,
        "relatives": <span>нет</span>,
        "imgs": [
            {"short": "bs_char_de_levine_rosalie_1_png_500_t", "big": "bs_char_de_levine_rosalie_1_jpg_full"},
            {"short": "bs_char_de_levine_rosalie_2_jpg_200", "big": "bs_char_de_levine_rosalie_2_jpg_full"},
            {"short": "bs_char_de_levine_rosalie_3_jpg_200", "big": "bs_char_de_levine_rosalie_3_jpg_full"},
            {"short": "bs_char_de_levine_rosalie_fa1_jpg_200", "big": "bs_char_de_levine_rosalie_fa1_jpg_full"},
            {"short": "bs_char_de_levine_rosalie_fa2_jpg_200", "big": "bs_char_de_levine_rosalie_fa2_jpg_full"}
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                    <p>(та самая кидонианка)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"}/>
                </div>,
            ],
        "resume":
            <>
                <h2 className="title-bg">Резюме</h2>
                <div className="row">
                    <div className="col-lg-6"><h3 className="title-bg">Кратко о герое</h3>
                        <div className="timeline-item pb-0"><p className={"text-justify"}><em>Розали - главная
                            героиня <TextInlineLink name={"book_cydonian_girl"} text={"Кидонианки"}/> и <TextInlineLink
                                name={"book_cydonian_autumn"} text={"Кидонианской осени"}/>.
                            Дочь человека и представителя неизвестного вида, прозванного «перворожденными» (неясно,
                            в каком контексте они получили такое имя).
                            Технически Розали стоило бы называть «гибридом», но звучит это грубовато и можно получить в
                            зубы.
                            От обычного Homo sapiens отличается стереотипно-пришельческими характеристиками: крайне
                            сильна,
                            очень вынослива, пугающе-живуча, невероятно быстра и скора на гнев. Внешне при этом выглядит
                            как
                            обычный человек, что делает ее образ еще более подозрительным.
                        </em></p>
                        </div>
                        <h3 className="title-bg">Образование</h3>
                        <div className="timeline-item"><h4>Средняя школа</h4><h5>20 - 10 лет <TextInlineLink
                            name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p className={"text-justify"}><em>Поселение Холдрейг, <TextInlineLink
                                name={"bs_planet_sleeping_suns_maison"}/></em></p><p>Довольно некачественная
                                средняя школа с религиозным уклоном и телесными наказаниями. Ученица из Розали была так
                                себе по местным меркам
                                и большую часть знаний девушка получила из самостоятельных исследований библиотеки</p>
                        </div>
                    </div>
                    <div className="col-lg-6"><h3 className="title-bg">Хроника</h3>
                        <div className="timeline-item">
                            <h5>14-12 месяцев <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (<TextInlineLink
                                name={"book_cydonian_girl"}/>)</h5>
                            <p><em><TextInlineLink name={"bs_state_the_great_monarchy"}/>, <TextInlineLink
                                name={"bs_state_republic_of_tiara_minor"}/></em></p>
                            <ul>
                                <li>Путешествие на <TextInlineLink name={"bs_planet_agathon"}/>, знакомство с
                                    королевой <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритета"}/>
                                </li>
                                <li>Первая встреча с отцом на <TextInlineLink name={"bs_planet_earth"} text={"Земле"}/>
                                </li>
                                <li>Участие в вооруженном мятеже (<TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                                  text={"Михъельмское восстание"}/>)
                                </li>
                                <li>Знакомство с <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                 text={"Иваром де Кармой"}/>, <TextInlineLink
                                    name={"bs_char_browdy_adam"} text={"Адамом"}/> и <TextInlineLink
                                    name={"bs_char_can_beucan_gaereas"} text={"Гэри"}/></li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>25 лет - 14 месяцев <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_planet_sleeping_suns_maison"}/></em></p>
                            <ul>
                                <li>Ничем не примечательная жизнь в детском приюте при монастыре</li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>26 лет <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_planet_earth"}/></em></p>
                            <ul>
                                <li>Прибытие в Поместье Спящих солнц</li>
                                <li>Рождение и отправка в детдом</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>,
        "text":
            <>
                <img style={{"maxWidth": "300px", "float": "left"}} alt="Розали де Левин"
                     src={imgs["bs_char_de_levine_rosalie_fa2_png_300_transparent"].link} fetchpriority="low"/>
                <h4 className="title-bg">Юность в Поместье Спящих солнц</h4>
                <p className="card-text text-justify">
                    Биография Розали ничем не примечательна, если не считать врожденных силы и выносливости
                    как у ходячего танка. Ее отец, <TextInlineLink name={"bs_char_the_whip"}/>, он же Последний
                    из <TextInlineLink name={"bs_spec_firstborns"} text={"перворожденных"}/> (что бы это ни значило),
                    оставил девушке множество неестественных для землян генов. Благодаря этому Розали очень быстро
                    поняла, что сильно отличается от сверстников. К сожалению недостаточно быстро, чтобы вовремя скрыть
                    необычные способности, из-за чего стала изгоем в замкнутом коллективе на
                    практически незаселенной планете. Поэтому большую часть времени девушка проводила одна
                    в библиотеке, подвергаясь нападкам сверстников.
                </p>
                <p className="card-text text-justify">
                    До совершеннолетия Розали жила в <TextInlineLink name={"bs_planet_sleeping_suns_maison"}/>, на
                    окраине сектора <TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"}/>, и никогда не знала
                    о своей истинной природе. Лишь после встречи с де Кармой она смогла добраться до
                    планеты с нормальной медициной и выяснить причину своих особенностей.
                </p>
                <h4 className="title-bg">Революция в <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                                     text={"Монархии"}/> (<TextInlineLink
                    name={"book_cydonian_girl"}/>)</h4>
                <p className="card-text text-justify">
                    На Ивара Розали набрела в лесах Поместья, в самом начале «Кидонианки».
                    Тот заприметил ее таланты во время рукопашного боя с пиратами, а затем предложил работу и бесплатные
                    приключения.
                    Розали как раз стукнуло 25, совершеннолетие по меркам ее времени, и она восприняла такое
                    предложение как подарок судьбы. Убраться из мира, где тебя презирают и боятся, чтобы колесить
                    галактику в поисках неприятностей... кто от такого откажется?
                </p>
                <p className="card-text text-justify">
                    Девушка почти сразу поняла, в какую передрягу попала. Ей довелось поучаствовать в Михъельмском
                    мятеже,
                    пережить несколько ужасающих воздушных битв, жутких перестрелок и даже орбитальную бомбардировку
                    на <TextInlineLink name={"bs_planet_scellurae"} text={"Сцеллуре"}/>.
                </p>
                <p className="card-text text-justify">
                    В итоге события «Кидонианки» всего за пару
                    месяцев
                    привели Розали к попаданию
                    в список самых разыскиваемых преступников галактики.
                </p>
                <p className="card-text text-justify">
                    Кроме этого, девушка встретила отца, хотя и мимолетом. Как и ожидалось, он не проявил особой
                    радости от этого события. Более того: оказалось, что его враги знали о существовании Розали,
                    следили за ней по всей галактике и в любой момент могли навредить девушке. И она совершенно
                    не представляла, что это за существа и как им противостоять.
                </p>
                <p className="card-text text-justify">
                    Были и положительные моменты: за пару месяцев после отлета из Поместья Розали обзавелась
                    друзьями, а скорее даже боевыми товарищами. <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                                text={"Отставной кидонианский адмирал"}/>, <TextInlineLink
                    name={"bs_char_esora_eve"} text={"агатонская коммандос"}/>, <TextInlineLink
                    name={"bs_char_can_beucan_gaereas"} text={"бывший собиратель костей"}/> и <TextInlineLink
                    name={"bs_char_browdy_adam"} text={"двинутый на всю голову аламарси"}/> пообещали
                    помочь девушке справиться
                    с неприятностями и разобраться с происходящим в галактике.
                </p>
                <h3 className="title-bg">Эволюция образа</h3>
                <img className="img-thumbnail-v2"
                     style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}} alt="Розалі де Левин"
                     src={imgs["bs_char_de_levine_rosalie_6_jpg_500"].link} fetchpriority="low"/>
                <p className="card-text text-justify">
                    Пожалуй, Розали как персонаж прошла самый простой путь: она изначально и задумывалась как
                    сверхчеловек. Хотя версий ее происхождения было несколько.
                </p>
                <p className="card-text text-justify">
                    Согласно первой, в <TextInlineLink name={"bs_planet_sleeping_suns_maison"}
                                                       text={"Поместье"}/> всегда жили необычные люди с такими же
                    способностями,
                    но они редко контактировали с внешним миром. Розали должна была отправиться в путешествие по планете
                    на корабле <TextInlineLink name={"bs_char_de_karma_ivar"} text={"де Кармы"}/> и после нападения
                    пиратов попасть к нему в команду, чтобы бесплатно колесить галактику (или как там правильно в
                    отношении космических кораблей?). У ее народа даже был специальный обряд для пробуждения силы –
                    «абхайя»: детей оставляли в морозном лесу наедине с дикими зверями и те через какое-то время
                    возвращались с тушей какого-нибудь снежного леопарда.
                </p>
                <p className="card-text text-justify">
                    Такая история оказалась ненужной, даже в чем-то глупой. На тот момент мир еще не был продуман и имел
                    внутренне противоречивую логику.
                    Я пришел к выводу, что такой народ должен стать объектом исследований и неизбежно влиться в
                    галактическое общество. Ведь тот же капитан <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                                text={"де Карма"}/> неоднократно
                    наведывался к ним с целью торговли. А мир, где есть <TextInlineLink name={"bs_tech_grn"}/>, не может
                    долго скрывать подобные необычности.
                </p>
                <div className="bg-image"
                     style={{"padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                </div>
                <p className="card-text text-justify">
                    Затем была первая версия <TextInlineLink
                    name={"book_cydonian_girl"} text={"«Кидонианки»"}/> со множеством довольно глупых решений с моей
                    стороны. Главная героиня там представала как отличный боец на мечах, а <TextInlineLink
                    name={"bs_char_de_karma_ivar"} text={"де Карма"}/> уже перевоплотился в адмирала михъельмского
                    флота. Но в той версии ее необычность почти не раскрывалась и мир никак на это не реагировал. Даже
                    не знаю, зачем ей были даны все эти способности, если они все равно не использовались.
                </p>
                <p className="card-text text-justify">
                    В процессе переделки сюжета появилось еще две версии: Розали – пришелец из будущего и Розали – часть
                    народа из потерянной тысячи лет назад колонии, где поселенцы каким-то образом объединили свою ДНК с
                    местными видами. Обе показались банальными.
                </p>
                <p className="card-text text-justify">
                    И обе выброшены прочь, когда в сюжете появился <TextInlineLink name={"bs_char_the_whip"}/>. Решено
                    было сделать Розали его родственницей уже просто потому, что два необычных народа на одну книгу –
                    это перебор и разбираться во всех этих нюансах в какой-то момент станет тяжело.</p>
                <p className="card-text text-justify">
                    Так девушка стала дочерью пришельца из другой галактики и <TextInlineLink
                    name={"bs_char_de_levine_clara"} text={"Клары де Левин"}/>, подарив название первой книге серии.
                </p>
            </>
    }
}
