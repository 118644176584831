import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Selurja() {
    return {
        "date": <span>869 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>3</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} text={"Старый космос"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"}/></span>,
        "owned": <span>Республика Акулы</span>,
        "people": <span>земляне, михъельмцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>6 млн.</span>,
        "imgs": [{"short": "bs_wiki_selurja_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Селурья</span> – желтый карлик класса
                    G в глубине сектора Акулы. Дом <TextInlineLink name={"bs_planet_vitvatersand"}
                                                                   text={"Витватерсанда"}/> и пары толком не заселенных
                    планет.</p>
            </>
    }
}

