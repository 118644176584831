import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Earth() {

    return {
        "date": <span>~206 000 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"}/></s>, неизвестно</span>,
        "habitats": <span>281 млрд. (79 на поверхности)</span>,
        "people": <span>земляне (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>Месяц</span>,
        "g": <span>1.0 земной *саркастически улыбается*</span>,
        "atmosphere": <span><span className='badge bg-success'>подходит для людей</span>, загрязнена</span>,
        "biosphere": <span>мертва</span>,
        "system": <span><TextInlineLink name={"bs_star_sun_zero"} text={"Нулевого солнца"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_sector_zero"} text={"Нулевой"}/></span>,
        "type": <span>землеподобная (иронично) <TextInlineLink name={"bs_universe_planet_types"}
                                                               text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{"short": "bs_wiki_earth_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears": [
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_cydonian_girl"}/>
            </div>
        ],
        "text": <>
            <h3 className="title-bg">История</h3>
            <p className='card-text text-justify'><span className='strong'>Земля</span> – прародина людей как
                вида. За последние тысячи лет обросла огромными мегаполисами и лишилась всех источников пресной
                воды – пить приходится фильтрованные отходы. Полярные шапки давно растаяли, некогда бескрайние
                леса Амазонии и Азии стали парковыми зонами, а горные гряды Гималаев теперь похожи на изъеденный
                мышами сыр. Если бы мыши оставляли после себя бетонные тоннели и стеклянные фасады.</p><p
            className='card-text text-justify'><span className='strong'>Последствия «Кидонианки»:</span></p><p
            className='card-text text-justify'>После нарушения работы энергосетей в районе Милана, правительство
            Земли вложило колоссальные суммы в резервные блоки питания по всей планете.</p><p
            className='card-text text-justify'><TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                               text={"Восстание Михъельма"}/> и других пограничных
            территорий, последующая опустошительная гражданская война и осада
            столицы привели к тому, что на Земле начался дефицит товаров первой необходимости и резко возросли
            цены практически на все. Работы при этом лишились миллиарды людей. Экономика метрополии и так
            балансировала на грани краха, но конфликт и падение курса земной валюты окончательно ее добили.
            Скорее всего землянам не удастся сохранить за собой даже часть прошлых территорий. Повезет, если
            останется хотя бы пара аграрных планет для выращивания еды.</p>
        </>
    }
}

