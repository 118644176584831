import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Breeze_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "capital": <span>нет</span>,
        "people": <span>бризеанцы, агатонцы</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Бриз</span> – формально
                    независимая территория под боком у <TextInlineLink name={"bs_people_agathonians"}
                                                                       text={"агатонцев"}/>. Не
                    контролируется единым правительством, которое заменяют корпоратократы из транснациональных компаний,
                    некогда выкупившие непригодные для жизни миры за бесценок. Теперь там кипит жизнь, но особо хорошей
                    ее не назвать – тотальная нищета и принуждение к тяжелому труду сделали Бриз чем-то вроде ада при
                    жизни. Как <TextInlineLink name={"bs_state_free_worlds"}/>, только наоборот.
                    Например, отсюда не так-то просто улететь – законы часто запрещают покидать планету без специального
                    разрешения.</p><p className='card-text text-justify'> Считается одним из самых непривлекательных
                мест в галактике еще и по причине царящего там беззакония (государственный контроль очень низкий, почти
                все проблемы решаются через частные военные компании).</p><p className='card-text text-justify'>Получил
                название в честь археологического объекта «Колония Бриз», найденного во время разработки рудных
                месторождений на Мертвом Игариоте. Это место, предположительно, было древним горнорудным комплексом,
                построенным на <TextInlineLink name={"bs_timeline_first_colonies_time"}
                                               text={"заре Световой эпохи"}/> неизвестными
                переселенцами с <TextInlineLink name={"bs_planet_earth"} text={"Земли"}/>. Кто и как сумел забраться так
                далеко от
                родной планеты в эпоху, когда корабли развивали скорость едва ли в несколько световых - неизвестно.</p>
            </>
    }
}

