import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_black_sun() {
    return {
        "type": <span>неизвестно</span>,
        "use": <span>неизвестно</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Черное солнце</span> – нечто
                    необъяснимо опасное, способное, со слов <TextInlineLink name={"bs_char_the_whip"} text={"Бича"}/>,
                    стирать цивилизации. Каким образом
                    оно это делает и сколько энергии затрачивает - загадка. Форма, размеры и другие параметры тоже
                    пока неизвестны.</p>
                <p className='card-text text-justify'>В первый раз это название
                    попалось <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ивару де Карме"}/>, когда он
                    обыскивал покои
                    свергнутого Монарха на <TextInlineLink name={"bs_planet_earth"} text={"Земле"}/>. Следующим о Солнце
                    узнал <TextInlineLink name={"bs_char_skarsgard_oliver"}/>, помогавший Бичу
                    на <TextInlineLink name={"bs_planet_tajbennae"} text={"Тайбенне"}/>.</p>
            </>
    }
}
