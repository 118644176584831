import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Javet_bejsenaer() {
    return {
        "name": "Бейсенар Явет",
        "fullname": <span>Бейсенар Явет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} text={"Айлирэн"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"}/></span>,
        "born": <span>211 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>210</span>,
        "children": <span><TextInlineLink name={"bs_char_javet_eijenora"}/>, <TextInlineLink
            name={"bs_char_javet_erbees"}/></span>,
        "niece": <span><TextInlineLink name={"bs_char_javet_pauline"}/></span>,
        "work": <span>Император <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                text={"Айлирэнской Доминации"}/></span>,
        "imgs": [
            {"short": "bs_char_javet_bejsenaer_1_jpg_500", "big": "bs_char_javet_bejsenaer_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Бейсенар</span> – типичный консерватор и поборник традиционных ценностей.
                    За время его правления в Доминации положение полусвободных квиритов заметно ухудшилось, а
                    многие по-настоящему талантливые люди не смогли получить высоких постов из-за имущественного и
                    социального ценза.
                </p>
                <p className="card-text text-justify">
                    Бейсенар не самый популярный и любимый император не только из-за этих сомнительных решений, но и
                    благодаря участию Доминации в <TextInlineLink name={"bs_timeline_galactic_war"}
                                                                  text={"Галактической"}/>. Именно по его решению айлири
                    ввязались в войну и
                    понесли огромные потери, отстаивая интересы, фактически, земного <TextInlineLink
                    name={"bs_state_the_great_monarchy"} text={"Великого Монарха"}/>. У Айлирэна
                    был шанс избежать конфликта, заняв нейтральную позицию, но Бейсенар знал, что без его помощи
                    земляне не удержатся на троне <TextInlineLink name={"bs_space_the_old_space"}
                                                                  text={"Старого космоса"}/> и Монархия просто
                    развалится под ударами <TextInlineLink name={"bs_people_agathonians"} text={"агатонцев"}/>.
                    Император боялся, что после этого у него не останется сильных союзников (<TextInlineLink
                    name={"bs_state_cydonian_priority"} text={"Приоритет"}/> не в счет, он
                    всегда в нейтралитете). Под конец войны ему даже удалось убедить кидонианцев в лице королевы <TextPopup name={"bs_char_ivetta_sheridan"} text={"Иветты Шеридан"}/>, помочь землянам и эффект был
                    достигнут - война не закончилась полным разгромом
                    Монархии.
                </p>
                <p className="card-text text-justify">
                    Бейсенар считал, что выполнил долг своей жизни: закрутил как следует гайки традиционализма в своей
                    стране и помог сохранить соседнюю крупную монархическую державу от распада. Он хотел отойти от дел
                    и в старости заняться искусствами, как это было принято у прошлых поколений Яветов. Однако
                    наследная принцесса <TextInlineLink name={"bs_char_javet_eijenora"} text={"Эйенора"}/> разочаровала
                    его: во время Галактической она начала множество
                    либеральных реформ, которые императору пришлось показательно свернуть, чтобы наказать дочь. Он
                    даже попытался найти другого приемника, хотя и в тайне, но достойных кандидатов просто не было.
                    Даже младший сын, <TextInlineLink name={"bs_char_javet_erbees"} text={"Эрбис"}/>, оказался
                    совершенно негодным для трона.
                </p>
                <p className="card-text text-justify">
                    Поэтому Бейсенар простил то, что считал предательством со стороны дочери. Однако она не усвоила урок
                    и во время <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Михъельмского восстания"}/>,
                    которое привело к уничтожению Великой Монархии, бездействовала.
                    Бейсенар отдал четкий приказ: выслушать Монарха и помочь всем, что ему необходимо. Однако принцесса
                    нашла <TextInlineLink name={"bs_char_javet_pauline"} text={"союзников в военном флоте"}/> и
                    специально тянула время, давая мятежникам укрепить свои силы и
                    найти союзников. План принцессы сработал - за считанные недели все было окончено и
                    земной <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Монарх"}/>
                    подписал отказ от престола.
                </p>
                <p className="card-text text-justify">
                    Падение величайшей династии, Алканарра, подкосило Явета-старшего. Он понял, что ошибся
                    в выборе приемницы и занялся поисками замены. Бейсенар попытался скрыть это от принцессы, поскольку
                    знал, что ее поддерживает немало либерально настроенных чиновников и офицеров. Император планировал
                    сделать смену наследника внезапной, чтобы у принцессы не осталось законных возможностей занять трон,
                    поскольку законы Доминации требуют постоянного наличия действующего императора и объявленного им
                    преемника. Отлучить Эйенору без назначения нового кандидата он просто не мог и при этом боялся
                    снова ошибиться в выборе.
                </p>
            </>
    }
}
