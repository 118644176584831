import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Brienne_cluster() {
    return {
        "date": <span>-</span>,
        "type": <span>регион галактики</span>,

        "capital": <span><TextInlineLink name={"bs_planet_cydonia"}/></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/>, <TextInlineLink
            name={"bs_people_ajliree"} text={"айлири"}/>, <TextInlineLink name={"bs_people_samborians"}
                                                                          text={"самборианцы"}/></span>,
        "bs_universe_currencies": <span><TextInlineLink name={"bs_currency_charmo"}
                                                        text={"кидонианский шарм"}/>, <TextInlineLink
            name={"bs_currency_dalmatia"} text={"далмация"}/></span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Кластер Бриэнн</span> – огромный
                    кусок населенного космоса по ту сторону <TextInlineLink name={"bs_space_the_rift"}
                                                                            text={"Разлома"}/> от <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Монархии"}/>. Здесь можно найти
                    Приоритет, Доминацию, Свободные миры и пару сотен планет-государств. В Бриэнн ходят в
                    основном, <TextInlineLink name={"bs_currency_charmo"} text={"кидонианские шармы"}/>, а пиратов
                    можно встретить гораздо чаще, чем хочется.</p><p className='card-text text-justify'>Назван в
                честь <TextInlineLink name={"bs_char_brienne_de_estrada"} text={"Бриэнны де Эстрада"}/>, первого
                пилота, доведшего корабль поколений до Кидонии на заре <TextInlineLink
                    name={"bs_timeline_light_travel_times"} text={"Световой эпохи"}/>.</p>
            </>
    }
}

