import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Nomad_syndrome() {
    return {
        "top": <>
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Класс: </strong> заболевание нервной
                            системы
                        </li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Группа риска:</strong>
                            <span>люди, чья нервная система развивалась в условиях замкнутого пространства</span></li>
                    </ul>
                </div>
            </div>
        </>,
        "text":
            <>
                <h4 className="title-bg">О болезни</h4>
                <p className="card-text text-justify">
                    <span className="strong">Синдром кочевника</span> – отклонение в развитии, вызванное отсутствием
                    доступа к большим открытым пространствам (в большинстве случаев из-за жизни на корабле) и
                    скоплениям людей во время развития нервной системы человека. Чаще всего диагностируется
                    у <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"}/>,
                    хотя встречается и у «планетников», которые в силу обстоятельств провели детство в узких
                    помещениях.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Предупредить</span> развитие заболевания можно тремя способами: регулярно
                    посещать с ребенком поверхность планет (в частности города с большим населением), либо перевезти
                    его на корабль с большими рекреационными и обзорными палубами, желательно с минимальным
                    количеством перекрытий и предметов мебели. Можно также регулярно выводить его в открытый космос, но
                    обязательно с наличием искусственной гравитации.
                    Мозг должен
                    привыкнуть к факту, что
                    открытые пространства – это нормально.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Лечение</span> синдрома – строго при помощи психотерапии. Медикаментозное
                    исцеление возможно, но существует риск рецидивов или возникновения зависимости / других фобий.
                    Наибольшую эффективность показывает регулярное посещение поверхности планет с постепенным
                    повышением времени нахождения в открытом и многолюдном пространстве. Терапия обычно занимает до
                    нескольких лет с периодическими слабыми рецидивами.
                </p>
                <p className="card-text text-justify">
                    Существует также экстремальное лечение, когда больного привозят на людные площади или в
                    большие университетские аудитории, где ему необходимо продержаться как можно дольше. Этот способ
                    показал большую эффективность, однако чреват психологическими травмами, депрессией и кошмарами.
                    Побочные эффекты могут длиться годами и стать причиной других расстройств.
                </p>
                <h4 className="title-bg">Выдержки из отчета о медосмотре <TextInlineLink name={"bs_char_ursula_adams"}
                                                                                         text={"Урсулы Адамс"}/> дежурным
                    роботом-врачом, 47
                    год <TextInlineLink
                        name={"bs_timeline"} text={"ДТ"}/></h4>
                <p className="card-text text-justify">
                    Симптомы: агорафобия, проблемы с дыханием и координацией во время нахождения на открытом воздухе
                    и в аудиториях университета, потеря концентрации в присутствии большого числа людей. Депрессия,
                    кошмары, навязчивые обсессии и компульсии (также
                    диагностировано ОКР).
                </p>
                <p className="card-text text-justify">
                    Пациент не желала обращаться в больницу и пыталась силой воли и самоубеждением ослабить симптомы.
                    Успешно на 36%. Анализ активности мозга показывает эффективность самолечения. Однако есть признаки
                    нервного срыва: скорее всего дальнейшее самолечение приведет к психологическим проблемам.
                </p>
                <p className="card-text text-justify">
                    Выдана рекомендация принимать нейростимуляторы класса D и блокаторы паразитных нервных активностей.
                    Однако пациент не желает «тратить деньги на волшебные пилюли». Ввиду отсутствия возможности
                    применить другие типы лечения, пациенту выдано предупреждение о потенциальных проблемах и
                    предложение
                    добровольно отчислиться из университета до момента, пока симптомы станут менее сильными.
                    Получен отказ (в грубой форме).
                </p>
            </>
    }
}











