import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Samborae() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"}/></span>,
        "habitats": <span>77 млрд. (56 на поверхности)</span>,
        "people": <span>самборианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>6</span>,
        "g": <span>1.1 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Метрополия Хнаасор</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "imgs": [{"short": "bs_wiki_samborae_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Самбора</span>, она же <span
                    className='strong'>Метрополия Хнаасор</span> – одна из трех штаб-квартир Лиги свободной
                    торговли. Дом могущественного синдиката <TextInlineLink name={"bs_comp_feathered_serpent"}
                                                                            text={"Пернатый змей"}/>, который много лет
                    держит в страхе пути снабжения агатонских и айлирэнских военных баз в секторе и считается
                    главной ударной силой Лиги в силовых операциях.</p>
            </>
    }
}

