import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Tamanrasett() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"}/></span>,
        "habitats": <span>12 млн. на поверхности + 0,2 млн. в системе</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>5</span>,
        "g": <span>1.1 земной</span>,
        "atmosphere": <span><span className="badge bg-success">подходит для людей</span></span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Диконир</span>,
        "sector": <span>-</span>,
        "type": <span>землеподобная, вяло колонизируется</span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_ternerian_end"}/></span>,
        "imgs": [{"short": "tamanrasett_500", "big": "tamanrasett"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_ru"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "margin-right": "1em"}}
                         alt="Таманрасетт" src={imgs["bs_wiki_tamanrasett_map_ru"].link} fetchpriority="low"/>
                </a>

                <p className="card-text text-justify"><span className="strong">Таманрасетт</span> – мир
                    на самом краю Тернерианского предела, в системе звезды Диконир. Планета малоизучена и
                    почти не населена. На ней есть несколько небольших агатонских колоний, в которых
                    готовят людей к жизни на периферии цивилизации. После новостей о массовых
                    исчезновениях ульев <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"}/> в
                    соседних секторах, на Таманрасетт перебросили дивизию <TextInlineLink
                        name={"bs_weaponry_space_force"} text={"космопехоты"}/>, а в
                    систему стал заглядывать патрульный флот. Многих местных это расстроило, потому что
                    города перестали быть тихими и безлюдными.
                </p>
                <p className="card-text text-justify">Людские поселения здесь огорожены бетонными заборами
                    с вооруженной охраной, а за пределами владений Homo sapiens дикий и совершенно необжитый мир.
                </p>
                <p className="card-text text-justify">Известно, что на Таманрасетт неоднократо бывали аламарси,
                    еще до ее заселения в позапрошлом веке. Земные автоматические терраформеры прибыли сюда
                    примерно за 3600 лет до <TextInlineLink name={"bs_timeline_late_colonies_time"}
                                                            text={"всем известных событий"}/> и
                    кочевники активно использовали мир для пополнения ресурсов, так как долгие тысячелетия вокруг просто
                    не
                    было пригодных планет.
                </p>
                <p className="card-text text-justify">При этом в фольклоре аламарси этой планете отведена
                    очень неприятная роль: капитаны старались не задерживать корабли на орбите дольше чем нужно,
                    а без необходимости вообще не приближались к системе Диконира. Точных причин они не указывали
                    в своих дневниках, поэтому наверняка понять суть суеверий не представляется возможным.
                    Тем не менее, из отрывистых заметок можно сделать вывод, что первые аламарси, добравшиеся
                    до планеты, решили основать там постоянное поселение и забросить кочевую жизнь.
                    Но через несколько десятилетий, после прибытия другого флота аламарси, выяснилось,
                    что колонисты в буквальном смысле сошли с ума. Суть сумасшествия не совсем ясна, но
                    их поведение другие кочевники трактовали как "безумие чистой воды".
                </p>
                <p className="card-text text-justify">Поначалу ученые считали это страшилкой и не верили в
                    попытки колонизировать планету, пока военный флот во время рутинного сканирования не
                    наткнулся на аномалию, которая позднее оказалась древним полуразрушенным кораблем. Машина,
                    по конструкции идентичная первым судам кочевников, открылась сканерам в результате
                    геологических процессов, которые разрушили ее экранированный ангар глубоко под землей.
                </p>
                <p className="card-text text-justify">С тех пор на планете ведутся непрерывные археологические
                    исследования, которые уже дали немало информации о культуре и уровне технологий древних
                    аламарси.
                </p>
            </>
    }
}
