import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_science_metadimensional_mechanics() {
    return {
        "knowledge_area": <span>физика</span>,
        "use": <span>перемещение в пространстве, изучение других вселенных</span>,
        "text": <>
            <h4 className="title-bg">«Краткий конспект по работе с червоточинами» Тиберия де Рохади, 135
                год <TextInlineLink
                    name={"bs_timeline"} text={"ПТ"}/></h4>
            <p className="card-text text-justify">
                ...изучением же других мирозданий занимается <span
                className="strong">метапространственная механика</span>, наука,
                которая лежит в основе привычных, скорее даже незаменимых для нас вещей: межпланетной связи и
                навигации в космосе. Без нее невозможно ни позвонить в другой мир (за приемлемое время), ни долететь
                туда
                без шанса пропасть навеки в черной пустоте.
            </p>
            <p className="card-text text-justify">
                Что такое «мета»? Это значит, что наука работает не только с нашим пространством, но и с другими.
                В м. механике есть набор терминов, которые стоит определить заранее, дабы в будущем не делать
                множества сносок. Поэтому я приведу их ниже.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Мироздание</span> – то же самое, что и вселенная. Но что такое вселенная? Мы
                так привыкли использовать это
                слово
                в отношении космоса, что и не понимаем его истинного смысла. Вселенная – это набор единиц энергии,
                обладающих одинаковой сигнатурой и образующих единую волновую функцию, который подчиняется единым
                физическим и математическим законам. А если вы вспомните из школьного
                курса физики, что энергия заключается в том числе и в материи, то станет ясно: мы с вами относимся
                к этим «единицам» энергии. Поэтому вселенная – не просто звезды и планеты. Вселенная – это все мы.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Пространство</span> – конечный набор осей координат, по которым возможно
                перемещение энергии в отдельно взятой вселенной.
                К ним относится в том числе и время. Разумеется, пространство - вещь гораздо более сложная и по сути
                является одной из характеристик вселенной, имеет свойства, особую математику и физику. Однако для нас
                такая сложность будет неактуальна.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Брана</span> – разница между энергетическими сигнатурами вселенных, благодаря
                которой из взаимодействие невозможно. Брану также можно представить как физически плотную стену,
                которую можно «пробить», приложив достаточно много энергии в определенную точку определенным образом.
            </p>
            <p className="card-text text-justify">
                Теперь мы можем смело говорить о том, как же работает и что изучает м. механика. Первым ее интересом
                является непосредственно брана: что это, как функционирует и как с ней взаимодействовать. Благодаря
                м. механике мы сумели преодолеть браны своей и соседней вселенной (<TextInlineLink
                name={"bs_tech_abspace"} text={"Надпространства"}/>),
                создав <TextInlineLink name={"bs_tech_grn"} text={"Ретрансляторную сеть"}/>.
            </p>
            <p className="card-text text-justify">
                Второй интерес – законы физики. Разница между нашими законами и правилами поведения соседнего мироздания
                может быть губительной. Разные значения фундаментальных физических констант приводят к разным формам
                существования материи. Или к ее полной аннигиляции в случае перехода в другую вселенную.
            </p>
            <p className="card-text text-justify">
                Третий – пространство координат. Полагаясь на знание о сущности границ вселенной и разницу между
                законами физики, мы можем выяснить, как устроено соседнее пространство и куда будет вести тоннель,
                который мы откроем. Именно точное понимание координат Надпространства позволяет нам использовать тоннели
                в него как способ моментальной доставки радиоволн быстрее света: отправлять их через более сложную
                геометрию другой вселенной, чтобы они возвращались обратно в нашу, проделав физически меньший путь.
            </p>
        </>
    }
}
