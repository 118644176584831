export function The_key() {
    return {
        "science": <span>неизвестно</span>,
        "use": <span>неизвестно</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Ключ</span> – некий объект, способный
                    взаимодействовать с сингулярностями. Возможно, назначение напрямую отражено в названии.</p>
            </>
    }
}
