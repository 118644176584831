import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Engine_atmospheric() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"астромеханика"} /></span>,
        "use": <span>охрана окружающей среды</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Атмосферные двигатели</span> –
                    маломощные и относительно чистые двигатели для перемещения в атмосфере. Потребляют меньше
                    топлива и выделяют меньше вредных испарений, чем космические.</p>
            </>
    }
}
