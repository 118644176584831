import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Humans() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_earth"} text={"Земля"}/>,
        "state": <span>множество</span>,
        "langs": <span>множество</span>,
        "size": <span>-</span>,
        "type": <span>разумное</span>,
        "bs_universe_biom": <span>земной</span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify">
                    <span className="strong">Люди</span> – существа, почти
                    покорившие галактику, но так и не научившиеся делать вкусный плавленый сыр и негадкий
                    растворимый кофе. Разочарование для Вселенной.
                </p>
            </>
    }
}
