import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_science_astromechanics() {
    return {
        "knowledge_area": <span>физика, механика</span>,
        "use": <span>движение в космосе</span>,
        "text": <>
            <h4 className="title-bg">Отрывок из речи, произнесенной ректором Хеллесианской академии торгового и
                военного флота перед первокурсниами в 12 <TextInlineLink
                    name={"bs_timeline"} text={"ДТ"}/></h4>
            <p className="card-text text-justify">
                «...отдельно отмечу такую дисциплину, как <span
                className="strong">астромеханика</span>. Не имеет значения, какую специальность вы планируете получить:
                если она связана с космосом – вы будете учить астромеханику. Здесь не может быть исключений.
            </p>
            <p className="card-text text-justify">
                Разумеется, скажете вы, у него ведь докторская степень по небесным наукам – конечно он будет говорить
                нам об этой скучной механике! Но нет, друзья, все совсем не так просто. Как только вы покидаете внешнюю
                орбиту своего мира, старые правила больше не работают. Интуитивные понятия о том, как двигаются объекты,
                сколько силы нужно приложить и в какую сторону – все это теряет смысл. Пилоту, инженеру-интерсетевику,
                который в глаза штурвал не видел, даже матросу на военном фрегате, всем! Всем нужно знать, как
                двигаются планеты, астероиды, звезды, и разумеется металлические консервные банки, которые мы гордо
                зовем «кораблями». Потому что все это генерирует осколки, влияет на другие объекты и может внезапно
                возникнуть на вашем пути. Да, планеты могут появляться внезапно – любой пилот, завершивший торможение
                на секунду позже необходимого, это подтвердит.
            </p>
            <p className="card-text text-justify">
                Рано или поздно вы попадете в ситуацию, когда именно знание астромеханики спасет вам жизнь. Понимание
                инерции, перегрузок, релятивизма... все это рано или поздно пригодится. Потому что космос - это не среда
                для человека. Мы даже к планетам не очень хорошо приспособены: чего стоят наши вечные проблемы со
                спиной, которые возникают, даже если вы живете на Земле. А уж казалось бы, какой мир будет более
                подходящим для наших спин, если не родина нашего вида? Увы, даже там у наших тел возникают проблемы.
            </p>
            <p className="card-text text-justify">
                А уж когда вы попадаете в космос: место без воздуха, тяготения, сопротивления... будь вы
                хоть первоклассным пилотом атмосферных машин, все равно растеряетесь. Наша интуиция, органы чувств и
                тела нас подводят за пределами планеты. Поэтому учите астромеханику, стремитесь познать чем эта
                черная пустота вокруг каменных шариков отличается от привычной среды, и вы обязательно преуспеете.
                Даже если потом выберете другую профессию, все равно механика пригодится. Уже после первого семестра у
                вас в запасе будет миллиард логических задач, которыми можно впечатлять людей на вечеринках...»
            </p>
        </>
    }
}
