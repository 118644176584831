import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Kyonmar() {

    return {
        "date": <span>-</span>,
        "owned": <span>Кёнмарская берг-коллегия</span>,
        "habitats": <span>-</span>,
        "people": <span>бризеанцы, агатонцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>нет</span>,
        "g": <span>1.06 земной</span>,
        "atmosphere": <span><span className='badge bg-success'>подходит для людей</span></span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Газардина</span>,
        "sector": <span><TextInlineLink name={"bs_space_breeze_sector"} text={"Бриз"}/></span>,
        "type": <span>землеподобный <TextInlineLink name={"bs_universe_planet_types"}
                                                    text={"промышленный центр"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "area": <span></span>,
        "imgs": [{"short": "bs_wiki_kyonmar_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Кёнмар</span> – небольшой
                    промышленный мир в Бризе. Добыча ресурсов здесь идет не очень активно, поэтому биосфера пока не
                    успела окончательно испортиться под влиянием людей.</p>
            </>
    }
}

