import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Vitvatersand() {
    return {
        "date": <span>869 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span>Республика Акулы</span>,
        "governor": <span></span>,
        "habitats": <span>6 млн.</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} text={"Земляне"}/>, михъельмцы (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>нет</span>,
        "mother": <span></span>,
        "g": <span>~1.0 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span><TextInlineLink name={"bs_star_selurja"} text={"Селурьи"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"}/></span>,
        "type": <span>землеподобная, стратегического назначения</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "imgs": [{"short": "bs_wiki_vitvatersand_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Витватерсанд</span> – планета
                    стратегического назначения в секторе Акулы, на которой до Восстания хранился
                    антиматериальный арсенал Паучьего Эшелона.</p>
                <p className="card-text text-justify">
                    <span className="strong">Последствия <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"}/>:</span>
                </p>
                <p className="card-text text-justify">
                    Никаких.
                </p>
            </>
    }
}

