import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hedwig() {
    return {
        "date": <span>3922 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>16</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Бриэнн"}/></span>,
        "sector": <span>Кирикия</span>,
        "area": <span>Туманность Лабиринт Ришелье</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "people": <span>айлири (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>2.2 млрд.</span>,
        "imgs": [{"short": "bs_wiki_hedwig_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Ядвига</span> – звезда в Доминации,
                    недалеко от <TextInlineLink name={"bs_space_the_rift"} text={"Разлома"}/> и границы
                    с <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритетом"}/> и <TextInlineLink
                        name={"bs_state_free_worlds"} text={"Свободными мирами"}/>.</p><p
                className="card-text text-justify">Недавно ученые обнаружили в пространстве вокруг Ядвиги множество
                аномалий: пространственных, временных, электромагнитных - на любой вкус. В теории, если устроить в
                этом месте большой выброс энергии (вне зависимости от источника), можно в буквальном смысле
                разорвать континуум, и тогда последствия действительно будут катастрофическими.</p><p
                className="card-text text-justify">Источник аномалий отследить не удалось: расчеты показывают, что
                некий очень массивный объект или источник энергии словно бы продавливают ткань мироздния из соседней
                вселенной. Но как такое возможно и что это вообще может быть, пока остается загадкой.</p><p
                className="card-text text-justify">Сейчас в системе находится множество научных станций, которые
                регулятрно делают прорывные открытия в физике, в частности - прикладной метапространственной
                механике.</p>
            </>
    }
}

