import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Sleeping_suns_maison() {
    return {
        "date": <span>~5800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span>Республика Акулы</span>,
        "habitats": <span>немного</span>,
        "people": <span>михъельмцы, земляне (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>2</span>,
        "g": <span>1.01 земной</span>,
        "atmosphere": <span><span className='badge bg-success'>подходит для людей</span></span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Спящих солнц</span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"}/></span>,
        "type": <span>землеподобный <TextInlineLink name={"bs_universe_planet_types"} text={"аграрный мир"}/></span>,
        "imgs": [{"short": "bs_wiki_sleeping_suns_maison_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">О мире</h3>
                <p className="card-text text-justify">
                    <span className="strong">Поместье Спящих солнц</span> – еще
                    одна земная колония досветовых времен. Долгое время была заселена небольшими общинами, считалась
                    идеальным миром для отшельников. Но в итоге все поселения вымерли с открытием световых
                    перелетов, потому что молодежь массово улетела в метрополию. С тех пор там остался только замок
                    Холдрейг, чьи славные деньки давно канули в лету.
                </p>
                <p className="card-text text-justify"><span
                className="strong">Последствия «Кидонианки»:</span>
                </p>
                <p className="card-text text-justify">Здесь
                вообще мало кто понимает, что случилось с сектором Акулы и Монархией. Пока что отлет <TextInlineLink
                    name={"bs_char_de_levine_rosalie"} text={"Розали"}/> с таинственными оборванцами
                для местных жителей самый актуальный повод посплетничать. Время обсуждения революции еще придет.
                </p>
            </>
    }

}

