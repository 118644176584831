import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Shark_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"}/></span>,
        "owned": <span>Республика Акулы</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_michelm"} /></span>,
        "people": <span>земляне, михъельмцы</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Акулы</span> – окраина
                    Монархии, как раз рядом с <TextInlineLink name={"bs_space_the_rift"} text={"Разломом"}/>. Всегда был
                    одним из беднейших регионов, даже в годы экономического подъема. Эдакая страна третьего мира
                    размером в сотню световых лет в поперечнике.</p><p className='card-text text-justify'><span
                className='strong'>Последствия «Кидонианки»:</span></p><p className='card-text text-justify'>Сектор
                Акулы вышел из состава Монархии и теперь считается территорией Республики Акулы, хотя она еще не до
                конца признана галактикой.</p>
            </>
    }
}

