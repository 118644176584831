import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ardemar_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "capital": <span><TextInlineLink name={"bs_planet_helena"}/></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Ардемар</span> – крупная
                    территория на краю Приоритета со столицей на Хелене. Граничит с <TextInlineLink
                        name={"bs_state_ajliraenian_domination"} text={"Доминацией"}/> по направлению к ядру
                    галактики и с <TextInlineLink name={"bs_space_samborae_sector"} text={"Самборой"}/> по направлению
                    к, собственно, Самборе.</p>
            </>
    }
}

