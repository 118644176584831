import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Aethurians() {
    return {
        "homeworld": <span>Этурия</span>,
        "state": <span><TextInlineLink name={"bs_state_aethurian_republic"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys": "",
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"}/>, <TextInlineLink name={"bs_lang_earth"}
                                                                                              text={"земной"}/></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Этурийцы (этурианцы)</span> – жители
                    Этурийской республики. Новостей из этого клочка космоса не так уж и много, так что никто не
                    знает, нравится им именование всего народа в честь одной планеты или нет.</p>
            </>
    }
}
