import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Galactic_democratic_alliance() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "form": <span>конфедерация</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"агатонский терос"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"}/>, <TextInlineLink name={"bs_lang_earth"}
                                                                                              text={"земной"}/></span>,
        "leader": <span>канцлер <TextInlineLink name={"bs_char_callisto_kindrace"}/></span>,
        "region": <span>вся галактика</span>,
        "sectors": "-",
        "worlds": "-",
        "people": <span>множество</span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Галактическое демократическое содружество
            </span>
                    – конфедерация из огромного числа демократических стран и планет-государств, охватывает почти все
                    сектора
                    галактики. Вступая в нее, страны подписывают пакт о взаимовыручке и другие документы, которые
                    фактически
                    делают их союзниками Агатона и обязывают выполнять все распоряжения <TextInlineLink
                        name={"bs_comp_scs"}
                        text={"Совета коллективной безопасности"}/>.
                </p>
            </>
    }
}

