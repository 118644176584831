import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Karuon() {

    return {
        "date": <span>145</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "habitats": <span>217 млн. на поверхности + 126 млн. в системе</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>2</span>,
        "g": <span>0.87 земной</span>,
        "atmosphere": <span className="text-danger">нет</span>,
        "biosphere": <span>нет</span>,
        "system": <span>-</span>,
        "sector": <span>-</span>,
        "type": <span><span>землеподобный <TextInlineLink name={"bs_universe_planet_types"} text={"промышленный мир"}/></span></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "imgs": [{"short": "bs_planet_karuon_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">О мире</h3>
                <p className="card-text text-justify">
                    <span className="strong">Каруун</span> - небольшая промышленная планетка на окраине Доминации в
                    рукаве <TextPopup name={"bs_popups_scutum_centaurus_arm"} text={"Щита-Центавра"} />. Здесь добывают и производят <TextInlineLink
                    name={"bs_tech_space_steel"} text={"сталь"}/> среднего качества и другие материалы,
                    идущие на постройку кораблей и космических сооружений.
                </p>
                <p className="card-text text-justify">
                    У Карууна никогда не было атмосферы или биосферы, а большая часть поверхности давно покрылась
                    провалами карьеров и гигантскими отвалами породы. Население - вахтовые рабочие-квириты, работающие
                    не больше года за один раз.
                </p>
                <p className="card-text text-justify">
                    За несколько лет до <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"}/> <
                        TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ивар"}/>, <TextInlineLink
                    name={"bs_char_can_beucan_gaereas"} text={"Гэри"}/> и <
                        TextInlineLink name={"bs_char_browdy_adam"} text={"Адам"}/> устроили здесь перестрелку с пиратской
                    шайкой и едва сумели уйти от погони на неуправляемой
                    спасательной шлюпке. Гэри позже признался, что у него в процессе "вспотели трусы".
                </p>
            </>
    }
}
