import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Lenisaad_victor() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>,
        "name": "Виктор Ленисаад",
        "born": <span>56 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>55</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_esghariot_en_nuvnish"}/></span>,
        "fullname": <span>Виктор <small>«Дерзкий бородач», «Ленни»</small>Ленисаад</span>,
        "nation": <span><TextInlineLink name={"bs_people_breezeans"} text={"бризеанец"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "relatives": <span>погибли вместе с родной планетой</span>,
        "work": <span><s>представитель <TextInlineLink name={"bs_comp_free_trade_league"}
                                                       text={"Лиги свободной торговли"}/> в <TextInlineLink
            name={"bs_space_the_old_space"} text={"Старом космосе"}/></s></span>,
        "img_trans": false,
        "imgs": [
            {"short": "bs_char_lenisaad_victor_1_jpg_500", "big": "bs_char_lenisaad_victor_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Виктор</span> родился за 18 лет до <TextInlineLink
                    name={"bs_timeline_galactic_war"} text={"Галактической"}/>, на планете Исгариот-ин-Нувниш,
                    на окраине сектора <TextInlineLink name={"bs_space_breeze_sector"} text={"Бриз"}/>. Исгариот был
                    грязным
                    промышленным центром, с закопченными небесами, кислотными дождями и практически мертвой биосферой.
                    Ему прочили еще пару десятилетий, после чего планета стала бы полностью непригодной для жизни. Но
                    агатонских промышленников это не останавливало: в Бризе нет единых законов и они имели полное право
                    делать с планетой все, что вздумается.
                </p>
                <p className='card-text text-justify'>
                    Виктор очень быстро
                    понял, что галактике плевать на его родной регион и несчастных бризеанцев. Поэтому он твердо решил
                    во
                    что бы то ни стало разбогатеть и освободить хотя бы свою планету от гнета корпоратократов. Затем
                    грянула
                    Галактическая и он ушел добровольцем, понимая, что земляне ведут себя даже хуже агатонцев. А когда
                    война
                    была окончена, то с ужасом обнаружил, что родной мир выжжен орбитальными ударами земного флота в
                    отчаянной попытке хоть немного отсрочить неминуемое поражение. С тех пор Виктор
                    возненавидел <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                 text={"Монархию"}/>, <TextInlineLink name={"bs_planet_earth"}
                                                                                      text={"Землю"}/> и все, что с ними
                    связано. Он стал пиратом, потому
                    что за жизнь ничему так и не научился. А чтобы не чувствовать угрызений совести за свой род занятий,
                    отправился именно на Землю, где ни к кому не испытывал жалости.
                </p>
                <p className="card-text text-justify">
                    Примерно за 13 лет до Таманрасетт познакомился с <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                                     text={"Иваром де Кармой"}/>,
                    который затем ушел на вольные хлеба, но часто подкидывал Виктору необычные заказы.
                </p>
                <p className="card-text text-justify">
                    После событий <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"}/> он хотел создать бизнес по торговле контрабандными культурными ценностями, но внезапный контакт с неизвестными существами нарушил его планы.
                </p>
                <p className="card-text text-justify">
                    Кроме этого, беднягу разыскивает и <TextInlineLink name={"bs_comp_free_trade_league"}
                                                                       text={"Лига свободной торговли"}/> за то, что он
                    устроил
                    на Земле: Виктор привлек внимание к местным контрабандистам, когда помог беглому чиновнику улететь с
                    планеты, и тем самым подставил под удар цепочки доставки нелегальных товаров. На фоне этого Лига
                    понесла
                    безумные убытки и назначила даже большую награду за его поимку, чем агатонское правительство.
                </p>
                <h4 className="title-bg">Эволюция героя</h4>
                <p className='card-text text-justify'>
                    У Виктора самая печальная эволюция в сюжете.
                    Он начинал как могущественный суперчеловек жил уже тысячу лет и управлял целой планетой с
                    армией генетически модифицированных сверхлюдей.
                </p>
                <p className='card-text text-justify'>
                   Потом
                    обмельчал: сюжет стал перегружен, все эти истории про суперменов и прочих мутантов показались
                    уныло-однообразными и слишком напоминающими сюжетную линию Розали. Виктор был понижен сначала до
                    лидера
                    преступного синдиката под названием «Международная наемная армия» (люблю пафос), а затем и до
                    обычного
                    пирата, которым и предстает перед читателем в «Кидонианке».
                </p>
                <p className='card-text text-justify'>
                    При
                    этом сама «Армия» была вырезана уже просто потому, что в галактике не осталось места для таких
                    могущественных организаций: по легенде она должна была иметь связи чуть ли не во всех правительствах
                    и
                    отправлять наемных головорезов в любую точку космоса. Но это сделало бы достижение целей героев
                    чересчур
                    простым, да и сам Виктор получился бы эдаким бородатым мистером Сью, что не очень хорошо для образа.
                </p>
            </>
    }
}
