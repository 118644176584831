import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";
import imgs from "../../../../../components/cms/images_repo.json";

export function Avvaline() {

    return {
        "date": <span>1200 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>130 млн.</span>,
        "people": <span><span>кидонианцы (<TextInlineLink name={"bs_spec_humans"}
                                                          text={"Homo sapiens"}/>)</span></span>,
        "moons": <span>1</span>,
        "g": <span>0.97 земной</span>,
        "atmosphere": <span className="text-success">подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span><TextInlineLink name={"bs_star_anubis_gate_alpha"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"}/></span>,
        "type": <span>землеподобный туристический центр</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "imgs": [
            {"short": "avvaline_500", "big": "avvaline"},
            {"short": "bs_wiki_avvaline_map_ru", "big": "bs_wiki_oecumene_map_ru"}
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <div className="row">
                    <div className="col-lg-4">
                        <a data-fancybox="true" data-caption="" href={imgs["avvaline_landscape_1"].link}>
                            <img className="img-thumbnail-v2"
                                 style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}} alt="Аввалин"
                                 src={imgs["avvaline_landscape_1"].link} fetchpriority="low"/>
                        </a>
                        <a data-fancybox="true" data-caption="" href={imgs["avvaline_landscape_2"].link}>
                            <img className="img-thumbnail-v2"
                                 style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}} alt="Аввалин"
                                 src={imgs["avvaline_landscape_2"].link} fetchpriority="low"/>
                        </a>
                    </div>
                    <div className="col-lg-8">
                        <h3 className="title-bg">История</h3>
                        <p className='card-text text-justify'><span className='strong'>Аввалин</span> –
                            малозаселенный мир, терраформированный земными автоматическими станциями на заре
                            колонизации. Известен в первую очередь своими курортами и рекреационными водными
                            источниками (хотя технологии давно позволяют любую «волшебную водичку» синтезировать
                            на ближайшем химзаводе). Постоянный туристический поток обеспечен в первую очередь
                            активной рекламой, представляющей Аввалин как «самый популярный курорт сектора
                            Ардемар».
                        </p>
                    </div>
                </div>
            </>
    }
}
