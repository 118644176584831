import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Samborians() {
    return {
        "homeworld": <span>сектор <TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "state": <span>множество</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "langs": <TextInlineLink name={"bs_lang_samborian"} text={"самборианский"}/>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_hector_salinger_1_jpg_200"}/></li>
        </ul>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><strong>Самборианцы</strong> – жители
                    сектора <TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/>, в «цивилизованной
                    галактике» это
                    равносильно слову «пираты».</p><p className='card-text text-justify'>Самборианская кухня
                славится на всю галактику вкусовыми излишествами в виде чересчур перченых, соленых и кислых блюд, от
                которых желудок незадачливого туриста встанет намертво.</p>
                <h3 id={"language"} className="title-bg">Язык</h3>
                <p
                    className="card-text text-justify"><span className="strong">Самборианский</span> – традиционный
                    язык <TextInlineLink name={"bs_state_free_worlds"} text={"Свободных миров"}/>. Как правило
                    жители <TextInlineLink name={"bs_space_samborae_sector"} text={"Самборы"}/> используют его лишь в
                    своих родных
                    поселениях, а при полете на другие планеты говорят на земном или аруме. Причина – изолированность
                    многих самборианских обществ и планет-государств друг от друга. Из-за этого местные диалекты
                    зачастую настолько отличаются, что граждане соседних звездных систем не способны понять друг друга
                    без перехода на земной (хотя это часто только ухудшает ситуацию).</p>
            </>
    }
}
