export function Ils() {
    return {
        "science": <span>физика, сопромат</span>,
        "use": <span>охрана труда</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">КИЖ </span> – Костюм индивидуального
                    жизнеобеспечения. По сути, скафандр.</p>
            </>
    }
}
