import {GetConnections, TextInlineLink} from "../../../components/cms/wiki_functions";
import {ImgRound3EM} from "../../../components/imgs/imgRound";
import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";

function Book_Bonecollectors() {
    let repo = "book_bonecollectors"
    let name = wiki[repo].name
    document.title = "Сергей Крехно - " + name
    return (
        <>
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"/>  Собиратели костей</span>}/>
                </div>
                <div className="row row-alt">
                    <div className="col-lg-6">
                        <a data-fancybox={true} href={imgs["book_bonecollectors_jpg_ru"].link}>
                            <img className="img-max-full-width img-thumbnail-v2" alt="Обложка Собиратели костей Сергея Крехно"
                                 src={imgs["book_bonecollectors_jpg_ru"].link}/>
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <div className="row"><h1>Собиратели костей<small><br/>или Путеводитель по Млечному пути для
                            перелетных птиц (часть I)</small></h1></div>
                        <div className="row">
                            <blockquote>
                                <p>Ближе к публикации здесь будут цитаты, а пока лишь далекий звериный вой нарушает
                                    тишину...</p>
                                <small><TextInlineLink name={"bs_char_skarsgard_oliver"}/></small>
                            </blockquote>
                        </div>
                        <div className="row">
                            <div className="alert alert-danger text-justify" role="alert">Рассказ пока не готов
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="21"
                                         aria-valuemin="0" aria-valuemax="100" style={{"width": "27%;"}}>Прогресс: 27%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_the_whip_1_jpg_200"}/></li>
                                <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"}/></li>
                                <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                                <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"}/></li>
                            </ul>
                        </div>
                        <div className="row">
                            <ul className="book-info">
                                <li><i className="fas fa-feather-alt"/> <h6>Статус:</h6> <strong><span
                                    className="text-info">в работе</span></strong></li>
                                <li><i className="far fa-calendar-alt"/> <h6>Дата выхода:</h6> ~2023</li>
                                <li><i className="far fa-list-alt"/> <h6>Цикл:</h6> <TextInlineLink
                                    name={"bs_series"} text={"Черное солнце #4"}/></li>
                                <li><i className="fas fa-angle-double-left"/> <h6>Предыдущая:</h6> <TextInlineLink
                                    name={"book_cydonian_autumn"} text={"Кидонианская осень"}/></li>
                                <li><i className="fas fa-angle-double-right"/> <h6>Следующая:</h6> <TextInlineLink
                                    name={"book_helleon_cold"} text={"Холодное солнце"}/></li>
                                <li><h6>Связанные страницы:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row row-alt">
                    <div className="col-lg-8">
                        <h3 className="title-bg">Аннотация</h3>
                        <div className=" well">
                            <p className="card-text text-justify">Рассказ - переформатированная часть большого
                                труда <TextInlineLink name={"bs_char_ursula_adams"}
                                                      text={"Урсулы Адамс"}/> (<TextInlineLink
                                    name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                    text={"«Путеводителя по Млечному Пути для перелетных птиц»"}/>).
                                Сюжет будет состоять из нескольких историй, одна из которых представлена в виде объемных
                                цитат из «Путеводителя». Чтобы пощадить читателя,
                                большая часть текста Урсулы будет вырезана, особенно та, где она с пугающей
                                щепетильностью описывает
                                количество кислорода в атмосфере никому не интересных планет.</p>

                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <h3 className="title-bg">Галерея</h3>
                        <ul className="characters-bar">
                            <li>Галерея пока пуста</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Book_Bonecollectors};
