import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Inter_branal_positioning_beacon() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
                                         text={"метапространственная механика"}/></span>,
        "use": <span>перемещение в пространстве</span>,
        "text": <>
            <h4 className="title-bg">«Краткий конспект по работе с червоточинами» Тиберия де Рохади, 135
                год <TextInlineLink name={"bs_timeline"} text={"ПТ"}/></h4>
            <p className="card-text text-justify">
                <span className="strong">Позиционный маяк</span> – класс устройств, которые могут создавать червоточины
                между разными частями вселенной и таким образом переправлять энергию или материю быстрее света. К таким
                устройствам относят, например, маяки <TextInlineLink name={"bs_tech_grn"}
                                                                     text={"Галактической ретрансляторной сети"}/>.
            </p>
            <p className="card-text text-justify">
                Непосредственно позиционный маяк в чистом виде люди пока изобрести не сумели. Законы физики делают
                его создание крайне энергозатратным, а побочные эффекты - непредсказуемыми. Поэтому вместо "чистой"
                версии маяка используется более дешевая и простая в эксплуатации - межбранальный позиционный маяк.
            </p>
            <p className="card-text text-justify">
                Брана - это потенциальный барьер мироздания. Ее можно представить как невидимую стену, отделяющую
                соседние
                вселенные, а можно - как разность энергетических сигнатур. Некий математический параметр, который не
                позволяет материи из разных мирозданий соприкасаться (в норме).
            </p>
            <p className="card-text text-justify">
                Идея браны хороша тем, что ее можно преодолеть и если за ней вселенная с более лояльными законами, то
                ее пространство можно использовать, чтобы ускорить перемещение в текущей. Межбранальный маяк занимается
                тем, что продавливает пространство-время в одном месте и создает червоточину не внутри одной вселенной,
                а сразу в двух. По сути, он делает дыру в бране и поддерживает ее стабильной. Самые совершенные
                устройства
                могут не только создавать тоннель в другую вселенную, но и продлевать его до нужных координат, чтобы
                затем открыть выход снова в нашей вселенной. Из известных человечеству устройств так умеет
                только <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"} text={"Северная звезда Нираданхи"}/>.
            </p>
            <p className="card-text text-justify">
                У межбранальных маяков есть простая классификация: приводные маяки и смещающие якори.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Приводной маяк</span> способен лишь обсчитать координаты и создать
                червоточину, которую затем необходимо расширить иными средствами. Он зовется приводным именно потому,
                что берет на себя только задачу проложить путь через пространство-время и не способен осуществить
                перенос
                материи сам по себе. Он словно бы "продавливает" пространство в нужных местах, позоляя легко обнаружить
                разлом и создать тоннель. После его активации в соседней вселенной появляется аномалия, которую
                можно засечь и расширить до стабильного тоннеля (при наличии нужных технологий). Внешне она может
                выглядеть
                как черная/белая дыра, область искаженного времени или просто место с аномальной электромагнитной
                активностью - все зависит от локальных законов физики.
            </p>
            <p className="card-text text-justify">
                Смысл применения таких устройств в том, что они делают самую "грязную" работу - нарушают целостность
                континуума. Тому, кто будет использовать созданную аномалию, достаточно лишь направить в нее поток
                энергии или частиц с нужными свойствами. Никакого обсчета координат и поправок на законы другой
                вселенной
                не потребуется.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Смещающий якорь</span> куда более совершенен по конструкции и способен не
                только найти нужное физическое местоположение во вселенной, но и создать стабильную червоточину.
                Он нарушает континуум и расширяет аномалию до размеров тоннеля, через который можно совершить переход.
                Якорь "цепляется" за две точки
                в мультимерной плоскости и создает между ними полноценные ворота. По сути он уничтожает разницу
                между координатами и на время работы устройства две точки мультивселенной оказываются в одном физическом
                месте.
            </p>
            <p className="card-text text-justify">
                Оба типа устройств могут оказывать непредсказуемые воздействия на вселенную, как нашу, так и соседнюю.
                Поэтому их использование строго регламентировано. Хотя... давайте будем честными: в гараже вы такое
                не соберете.
            </p>
        </>
    }
}
