import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Galactic_war() {
    document.title = "Галактическая война || Кидонианка и Черное солнце"
    return (<>
        <CrumbsBlackSunTimeline name={"Галактическая война"}/>

        <section id="about">
            <div className="about-me container">
                <div className="section-title"><h2>Галактическая война</h2></div>
                <div className="row">
                    <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <GetQuotes name={"bs_timeline_galactic_war"}/>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    <li>
                                        <i className="fas fa-chevron-right"/> <strong>Даты: </strong>
                                        <span>~37-25 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li>
                                        <i className="fas fa-chevron-right"/> <strong>Место:</strong>
                                        <span><TextInlineLink name={"bs_space_the_old_space"}/>, <TextInlineLink
                                            name={"bs_space_democratic_space"}/></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <i className="fas fa-chevron-right"/> <strong>Участники:</strong>
                                <span><TextInlineLink name={"bs_state_cydonian_priority"}/>, <TextInlineLink
                                    name={"bs_state_the_great_monarchy"}/>, <TextInlineLink
                                    name={"bs_state_republic_of_tiara_minor"}/>, <TextInlineLink
                                    name={"bs_state_tribalon_republic"}/>, <TextInlineLink
                                    name={"bs_state_aethurian_republic"}/>, <TextInlineLink
                                    name={"bs_state_ajliraenian_domination"}/>, <TextInlineLink
                                    name={"bs_state_galactic_democratic_alliance"}/> и другие</span>
                            </li>
                        </ul>
                        <p><strong>Связанные
                            статьи:</strong> <>{GetConnections("bs_timeline_galactic_war").map(conn => (conn))}</>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <div className="row row-alt">
            <div className="col-lg-12">
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Первая Галактическая война</span> –
                    мерзкая заварушка, которую начал предыдущий <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                                                text={"Монарх"}/> (да будет забыто
                    его имя). Он
                    использовал как формальный повод вмешательство <TextInlineLink name={"bs_comp_cms"}
                                                                                   text={"СБК"}/> в дела пары мелких
                    королевств на границе
                    нейтральной зоны между сферами влияния демократов и монархистов. Война шла двенадцать лет: убила
                    миллионы людей по всей галактике и стерла с лица Вселенной неисчислимое количество других видов
                    живых существ. По большей части Театр военных действий раскинулся в <TextInlineLink
                        name={"bs_space_the_old_space"} text={"Старом космосе"}/>, в глубине владений
                    землян. Монарх может и объявил войну, но как ее вести – не имел ни малейшего понятия, поэтому
                    быстро потерял инициативу из-за ряда глупых решений и потом двенадцать лет просто пытался выжить
                    и не растерять империю. В этом он тоже не преуспел.</p>
            </div>
        </div>
    </>)
}
