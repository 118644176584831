import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Light_travel_times() {
    document.title = "Световая эпоха || Кидонианка и Черное солнце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Световая эпоха"}/>

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Световая эпоха</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_light_travel_times"}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"/> <strong>Начало: </strong>
                                            <span>~5500 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"/> <strong>Заря:</strong>
                                            <span>~5500 – ~2800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (времена Первых и Поздних колоний)</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"/> <strong>Высокая световая
                                            эпоха:</strong>
                                            <span>~2800 - 0 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p><strong>Связанные
                                статьи:</strong> <>{GetConnections("bs_timeline_light_travel_times").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">История</h3>
                    <p className="card-text text-justify"><span className="strong">Эпоха сверхсветовых</span> или
                        просто <span className="strong">Световая эпоха</span> – примерная дата начала современного
                        летосчисления. Первые световые двигатели изобрели еще пять-шесть тысяч лет назад или около того,
                        но их мощности хватало только чтобы <span className="strike">очень быстро отправиться на тот свет</span> немного
                        обогнать фотоны в вакууме. Ученые до сих пор пытаются вычислить точную дату изобретения
                        самого-самого первого двигателя, но скорее всего у них не получится. Технологию выдумывали,
                        вычисляли, изобретали и переизобретали множество раз во всех частях человеческой ойкумены,
                        независимо друг от друга. Зачастую прототипы убивали своих создателей, но факт преодоления
                        скорости все же был.</p>
                </div>
            </div>
        </>
    )
}
