import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_univ_cydonian_autumn() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Кидонианская осень</span> – нечто
                    вроде Масленицы, только идет три недели. Начинается в первый день календарной осени в северном
                    полушарии <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонии"}/>
                    в <TextInlineLink name={"bs_universe_doab"} text={"День всех бомб"}/>. Учитывая, что смены времен
                    года
                    там почти нет, визуально ты отличить не сможешь, даже не пытайся. Если попадаешь на Кидонию и
                    видишь, что все вокруг раскрашивают лица в зеленый цвет, упиваются чаем и чересчур радуются
                    незнакомцам – это как раз Кидонианская осень. Обычно в это время каждую ночь бьют салюты, шумят
                    концерты и почти все, что требует человеческого вмешательства, намертво парализуется. Если бы не
                    развитая робототехника, кидоницанцы вымерли бы уже на вторую неделю, от голода и пьянства.</p>
                <p
                    className="card-text text-justify">Примечательно, что изначально Осень задумывалась как праздник
                    длиной всего три дня, но несколько веков назад случился невероятный урожай одного из сортов
                    традиционного чая. Его было принято пить только на этот праздник. В какой-то момент стало ясно, что
                    листья не доживут до следующего года, а выпить все за один день просто невозможно. Чтобы не нарушать
                    традиции и не дать любимому напитку прийти в негодность, королева объявила, что Осень будет длиться
                    столько, сколько потребуется для употребления всех запасов чая. Вызов был принят.</p>

            </>
    }
}
