export function Oaac() {
    return {
        "type": <span>орудийная платформа</span>,
        "use": <span>большие или низкоманевренные цели</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">ЗОК</span> – зенитно-орбитальный
                    комплекс. Грубо говоря, космическая станция с орудийными батареями. Хорошо справляется с
                    большими неповоротливыми целями, но против быстрых и маневренных проигрывает. От пиратов не
                    спасет, т.к. перемещать быстро такую штуку по орбите не получится, а налетчики не пользуются
                    тяжелыми кораблями. Обычно цепи из ЗОКов вешают над экватором или рядом с важными объектами,
                    чтобы поддержать основной флот.</p>
            </>
    }
}
