import { Alert } from 'antd';

export function HasSpoilers(props){
    let s = [<>Ниже могут быть спойлеры для книг: </>]
    s.push(props.list)
    if (props.list !== undefined){
        return (
            <Alert
                message={<strong style={{color: "#842029", fontSize: "1rem"}}>Спойлеры!</strong>}
                description={s}
                type="error"
            />
        )
    }

    return (
        <Alert
            message={<strong style={{color: "#842029"}}>Спойлеры!</strong>}
            description="There may be some spoilers below"
            type="error"
        />
    )
}