import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Shield_kinetic() {
    return {
        "science": <span>физика</span>,
        "use": <span>охрана труда</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Кинетический щит</span> или <span
                    className="strong">поле кинетической регрессии (ПКР)</span> – штука, похожая на атмосферный щит,
                    но не пропускает вообще ничего, кроме света. Требует слишком много энергии, поэтому на время
                    действия <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"}/> существуют лишь
                    маломощные прототипы.
                    Но зато потенциал огромен: если таким снабдить космический корабль или атмосферный транспорт,
                    или тем более космопехоту, то количество жертв от войны и несчастных случаев на гражданке
                    сократится в разы.</p>
            </>
    }
}
