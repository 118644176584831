import imgs from "../cms/images_repo.json";

export function PinterestLink(props){
    return <a target="_blank" rel="noreferrer" href={"https://www.pinterest.co.uk/pin/"+props.link}
              className="characters-image-link text-center">
        <img alt="Pinterest post" src={imgs["icon_pinterest"].link} style={{"maxWidth": "2em"}} />
    </a>
}

export function ImgurLink(props){
    return <a target="_blank" rel="noreferrer" href={"https://imgur.com/"+props.link}
              className="characters-image-link text-center">
        <img alt="Imgur post" src={imgs["icon_imgur"].link} style={{"maxWidth": "2em"}} />
    </a>
}

export function ImgurGalleryLink(props){
    return <a target="_blank" rel="noreferrer" href={"https://imgur.com/gallery/"+props.link}
              className="characters-image-link text-center">
        <img alt="Imgur post" src={imgs["icon_imgur"].link} style={{"maxWidth": "2em"}} />
    </a>
}

export function PdfLink(props){
    return <a target="_blank" rel="noreferrer" href={props.link}
              className="characters-image-link text-center">
        <img alt="Download PDF" src={imgs["icon_pdf"].link} style={{"maxWidth": "2em"}} />{props.text}
    </a>
}

export function PNGLink(props){
    return <a target="_blank" rel="noreferrer" href={imgs[props.pic].link}
              className="characters-image-link text-center">
        <img alt="Pure png" src={imgs["icon_png"].link} style={{"maxWidth": "2em"}} />
    </a>
}

export function LitresLink(props){
    return <a target="_blank" rel="noreferrer" href={props.link} className="characters-image-link text-center">
        <img alt="" src={imgs["pubhouse_lr_t"].link} />
        <br />
        ЛитРес
    </a>
}

export function MyBookLink(props){
    return <a target="_blank" rel="noreferrer" href={props.link} className="characters-image-link text-center">
        <img alt="" src={imgs["pubhouse_mb_t"].link} />
        <br />
        Mybook
    </a>
}

export function OzonLink(props){
    return <a target="_blank" rel="noreferrer" href={props.link} className="characters-image-link text-center">
        <img alt="OZON" src={imgs["pubhouse_ozon_t"].link} />
        <br />
        OZON
    </a>
}