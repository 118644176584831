import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vikoveera_mineralworks() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>частная корпорация</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                        <span>геологоразведка и добыча ископаемых в <TextInlineLink name={"bs_space_democratic_space"}
                                                                                    text={"ДРК"}/></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">Об организации</h3>
                <p className="card-text text-justify">
                    Виковира Минералворкс - агатонская рудная корпорация, одна из крупнейших в Демкосмосе. Занимается
                    разведкой ископаемых в мирах <TextInlineLink name={"bs_space_vikoveera"}
                                                                 text={"Виковиры"}/>, <TextInlineLink
                    name={"bs_space_breeze_sector"} text={"Бриза"}/> и территорий рукава Персея между <TextInlineLink
                    name={"bs_planet_agathon"} text={"Агатоном"}/> и <TextInlineLink name={"bs_planet_telenmark"}
                                                                                     text={"Теленмарком"}/>. Одно из
                    мест работы
                    родителей <TextInlineLink name={"bs_char_ursula_adams"} text={"Урсулы Адамс"}/>.
                </p>
                <p className="card-text text-justify">
                    Корпорация также крупный игрок на рынке обработки добытых материалов: на 1 год ДТ около 10% всей
                    корабельной стали, произведенной агатонцами, приходилось на филиалы ВМ.
                </p>
            </>
    }
}
