import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_science_cosmography() {
    return {
        "knowledge_area": <span>науки о космосе</span>,
        "use": <span>зонирование галактики</span>,
        "text": <>
            <h4 className="title-bg">
                О науке
            </h4>
            <p className="card-text text-justify">
                <span className="strong">Космография</span> – комплекс естественных и общественных наук, изучающих
                структуру космоса, его зонирование и распределение спорных территорий. Именно благодаря согласованности
                космологических законов и методов удалось остановить масштабные конфликты между крупными державами за
                приграничные территории.
            </p>
            <p className="card-text text-justify">
                Самыми важными ветвями космографии принято считать:
            </p>
            <p className="card-text text-justify">
                <span className="strong">Графическую космографию</span> как науку, формулирующую законы разделения
                космоса на сектора, зоны приоритетного развития (подконтрольные, но не включенные в состав государства
                территории) и области влияния конкретных планет, звездных систем и государств.
                Основной документ, на котором базируется графическая космология – это «Секторальный
                конгресс», подписанный политическими и научными лидерами
                галактики в 1242 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/>. Документ состоит из правил, по
                которым определяются четкие границы секторов и входящих
                в их состав территорий и считается основой международных отношений. Он регулярно уточняется, а примерно
                раз в столетие в него вносят существенные правки, отражающие современное видение галактического
                устройства. Государство, отказавшееся подписать документ или пересматривающее его правила в
                одностороннем порядке, фактически выпадает из международной политики. Такими являются большинство
                планет-государств в <TextInlineLink name={"bs_space_samborae_sector"} text={"Самборе"}/>.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Экономическую космологию</span> как науку, изучающую экономические ресурсы
                галактики, их распределение и рациональное использование. Под ресурсами здесь подразумеваются не
                полезные ископаемые, а звездные системы, планеты и территории. Экономическая космология помогает
                формировать устойчивые экономические связи с заделом на будущее, поскольку изучает законы
                распределения населения и предсказывает образование <
                TextInlineLink name={"bs_universe_planet_types"}
                               text={"метрополий и промышленных агломераций"}/> в
                конкретных участках космоса. Без накопленных ею знаний крайне сложно создать долгосрочный инвестиционный
                план, охватывающий периоды дольше столетия.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Физическую космологию</span> – науку, изучающую свойства и эволюцию физических
                объектов в галактике: спиральных рукавов, звездных систем, планет и др.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Космостатистику</span> – науку, собирающую и обрабатывающие статистические
                данные обо всех событиях, явлениях и свойствах в галактике. Космостатисты стремятся накопить как можно
                больше «больших данных» о любых, даже самых незначительных вещах во вселенной. Бюро космостатистики
                как правило независимы от государства и существуют за счет коммерческих проектов, предоставляя свои
                знания любому желающему за умеренную плату. Они также поддерживают в актуальном состоянии крупнейшие
                публичные энциклопедии и базы данных.
            </p>
        </>
    }
}
