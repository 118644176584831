import {ImgRound3EM} from "../../../../../components/imgs/imgRound";
import imgs from "../../../../../components/cms/images_repo.json";

export function Bs_org_Bonecollectors() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>частная организация</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Участники: </strong>
                        <span>
                                                <ul className="characters-bar">
                                                    <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"}/></li>
                                                </ul>
                                            </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                        <span>спасение людей за кругленькую сумму</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <img style={{"maxWidth": "300px", "float": "left"}} alt="Эмблема Собирателей"
                     src={imgs["bs_wiki_bonecollectors"].link}
                     fetchpriority="low"/>
                <p className="card-text text-justify"><span className="strong">Собиратели костей</span> –
                    международная коммерческая организация, помогающая искать людей в нейтральных территориях и
                    пустых мирах. Услуги Собирателей обойдутся недешево: поиски одного человека в неосвоенном мире
                    требуют больших ресурсов и опыта. К тому же, спасатели часто натыкаются на пиратов или
                    агрессивную местную живность - на незаселенных планетах может поджидать что угодно.</p><p
                className="card-text text-justify">«Кости» в названии, потому что в большинстве случаев пропавших
                людей находят уже мертвыми: слабо подготовленные туристы-дикари часто оказываются один на один с
                опасностями, которых не ожидали и вызовами, к которым не готов даже опытный искатель
                приключений.</p>
            </>
    }
}
