import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function De_vaal_leon() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"} text={"«Похитители маяков»"}/>,
        "name": "Леон де Вааль",
        "fullname": <span>Леон де Вааль</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} text={"Эсквариат"}/> <TextInlineLink
            name={"bs_state_cydonian_priority"} text={"Приоритета"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_helena"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианец"}/></span>,
        "img_trans": false,
        "imgs": [
            {"short": "bs_char_de_vaal_leon_1_jpg_500", "big": "bs_char_de_vaal_leon_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                    (НЕ нянька для <TextInlineLink name={"bs_char_hartrey_kellays"} text={"Келлеса Хартри"}/>)
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">О герое</h3>
                <p className="card-text text-justify">
                    <span className="strong">Леон</span> – уроженец Хелены, боевой офицер кидонианского флота и
                    эсквайр Её Величества <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Адель ван Глории"}/>.
                    Юность он провел в цветущей метрополии, беззаботно теряя время за подростковой ерундой.
                    Однако <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактическая война"}/> потрясла
                    парня до глубины души. Наблюдая, как горят целые миры в соседних
                    государствах, он решил, что приложит все усилия, чтобы не допустить этого в Приоритете.
                </p>
                <p className="card-text text-justify">
                    Леон записался в <TextInlineLink name={"bs_weaponry_space_force"} text={"космопехоту"}/>, но участия
                    в Галактической не принимал. После нескольких лет
                    подготовки
                    он отправился на границу <TextInlineLink name={"bs_state_free_worlds"} text={"Свободных миров"}/>,
                    сражаться против <TextInlineLink name={"bs_comp_free_trade_league"}
                                                     text={"Лиги свободной торговли"}/>.
                </p>
                <p className="card-text text-justify">
                    Боевое крещение Леона прошло во времена сражения между <TextInlineLink
                    name={"bs_state_cydonian_priority"} text={"Приоритетом"}/> и <TextInlineLink
                    name={"bs_comp_feathered_serpent"} text={"Пернатым змеем"}/> за <
                        TextInlineLink name={"bs_space_samborian_traverse"}/> (около 19 лет <TextInlineLink
                    name={"bs_timeline"} text={"ДТ"}/>). Рядовой космопехотинец, он
                    стал одним из тех,
                    кто высаживался
                    во многочисленных мирах Траверса и надирал зад пиратам в ближнем бою. Всего за несколько месяцев
                    Леон
                    трижды был ранен: терял руки, ноги и даже часть внутренних органов. Однако каждый раз возвращался в
                    строй.
                </p>
                <p className="card-text text-justify">
                    В ряды Эсквариата его пригласила <TextInlineLink name={"bs_char_colbert_simone"}/>, за несколько лет
                    до <TextInlineLink
                    name={"bs_timeline_tajbennae_incident"} text={"Инцидента на Тайбенне"}/>. После этого он стал ее
                    протеже и неформальным заместителем «по делам набивания морд».
                </p>
            </>
    }
}
