import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Helena() {

    return {
        "date": <span>4730 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "governor": <span>лорд <TextInlineLink name={"bs_char_scalligan_lloyd"}/></span>,
        "habitats": <span>167 млрд. (27 млрд. в системе)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>2</span>,
        "mother": <span></span>,
        "g": <span>1.07 земной</span>,
        "atmosphere": <span><span className="text-success">подходит для людей</span></span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Тэллаусы</span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"}/></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "area": <span></span>,
        "imgs": [{"short": "bs_wiki_helena_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Хелена-у-Тэллаусы</span> – столица
                    сектора Ардемар, крупнейшая метрополия в регионе.</p>
            </>
    }
}

