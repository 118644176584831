import {CrumbsBlackSunPeople} from "../../../../../components/Bread_Crumbs";
import {BsOecumeneMapLink} from "../../../../../components/links/imgLinks";
import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections} from "../../../../../components/cms/wiki_functions";
import {Ajliree} from "./ajliree";
import {Alamarsie} from "./alamarsie";
import {Agathonians} from "./agathonians";
import {Aethurians} from "./aethurians";
import {Breezeans} from "./breezeans";
import {Cydonians} from "./cydonians";
import {Samborians} from "./samborians";
import {Earthlings} from "./earthlings";
import wiki from "../../../../../components/cms/wiki.json";
import cats from "../../../../../components/cms/wiki_cats.json";
import {Bs_people_hellesians} from "./bs_people_hellesians";
import {HasSpoilers} from "../../../../../components/spoilers";

let list = {
    "ajliree": Ajliree,
    "alamarsie": Alamarsie,
    "agathonians": Agathonians,
    "aethurians": Aethurians,
    "breezeans": Breezeans,
    "cydonians": Cydonians,
    "earthlings": Earthlings,
    "samborians": Samborians,
    "hellesians": Bs_people_hellesians,
}


/*
return {
        "homeworld": ,
        "state": ,
        "guys":"",
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "langs": ,
        "text":
            <>
            </>
    }
 */

export function People(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_peoples"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Кидонианка и Черное солнце"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunPeople name={name}/>

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>{name}</h2></div>
                    <div className="row">
                        <div className="col-lg-4 text-center" data-aos="fade-right">
                            <BsOecumeneMapLink alt={name} name={repos[repo]}/>
                        </div>
                        <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="fas fa-chevron-right"></i>
                                            <strong>Родина: </strong>{data["homeworld"]}</li>
                                        <li><i className="fas fa-chevron-right"></i>
                                            <strong>Государство:</strong>{data["state"]}</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="fas fa-chevron-right"></i> <strong>Биологический
                                            вид:</strong>{data["species"]}</li>
                                        <li><i className="fas fa-chevron-right"></i>
                                            <strong>Язык:</strong>{data["langs"]}</li>
                                    </ul>
                                </div>
                            </div>
                            {conns.length > 0 ? <p><strong>Связанные статьи:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                            {data["guys"] !== "" ? data["guys"] : ""}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}

