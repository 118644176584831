import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Javet_eijenora() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>,
        "name": "Эйенора Явет",
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} text={"Айлирэн"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"}/></span>,
        "born": <span>74 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>73</span>,
        "fullname": <span>Эйенора Явет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "dad": <span><TextInlineLink name={"bs_char_javet_bejsenaer"}/></span>,
        "brother": <span><TextInlineLink name={"bs_char_javet_erbees"}/></span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_pauline"}/></span>,
        "work": <span>наследная принцесса <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                          text={"Айлирэнской Доминации"}/></span>,
        "imgs": [
            {"short": "bs_char_javet_eijenora_1_jpg_500", "big": "bs_char_javet_eijenora_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Юность</h3>
                <p className="card-text text-justify">
                    <span className="strong">Эйенора</span>, вопреки ожиданиям, не была золотой девочкой. Родители
                    воспитывали наследную принцессу в строгости. Император лично следил за успехами дочери в учебе,
                    практике и физической подготовке. Как и принято в династии Яветов, Эйенора была назначена
                    официальной наследницей в момент совершеннолетия и с тех пор подвергалась постоянным испытаниям
                    и «жизненным» урокам от отца и нанятых им многочисленных учителей.
                </p>
                <p className="card-text text-justify">
                    В детстве девушка пыталась сопротивляться такому отношению, но уже к 16 осознала, что особого выбора
                    нет: никто не позволит ей отказаться от предстоящей власти и уж точно не удастся сбежать. Ресурсы
                    и влияние <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Айлирэна"}/> в галактике
                    настолько
                    велики, что ни одно государство не решится укрыть у себя
                    сбежавшую принцессу. Даже в Свободных мирах не найдется достаточно наивного и самоуверенного типа.
                </p>
                <p className="card-text text-justify">
                    Поэтому Эйенора пообещала себе, что выдержит надоедливые уроки экономики, юриспруденции и прочие
                    скучные вещи, чтобы стать настолько хорошей императрицей, насколько получится. И ей удалось.
                    Когда девушке исполнилось 37 лет и она едва успела окончить базовый «курс начинающего монарха»,
                    грянула <TextInlineLink name={"bs_timeline_galactic_war"}/>.
                </p>
                <p className="card-text text-justify">
                    Отец принял стратегическое (и ошибочное по мнению многих) решение поддержать землян, хотя союзники
                    и советники уговаривали его соблюдать нейтралитет. По сути, именно вмешательство Айлирэнской
                    Доминации и сделало войну Галактической с большой буквы.
                </p>
                <p className="card-text text-justify">
                    Бейсенар, будучи человеком возрастным, повидал немало войн, в том числе глобальных. Ему на тот
                    момент было уже 174 года, за которые Айлирэн неоднократно ввязывался в конфликты и даже начинал их.
                    Поэтому император принял решение сосредоточиться на управлении войсками, а старшую дочь оставил
                    за главную по гражданской части. Это стало первым и самым серьезным испытанием для Эйеноры.
                </p>
                <h3 className="title-bg">Конфликт с престолом и революция в Монархии (<TextInlineLink
                    name={"book_cydonian_girl"}/>)</h3>
                <p className="card-text text-justify">
                    Тогда же стало понятно, что у них с отцом крайне разные видения светлого будущего для империи. Как
                    и можно ожидать, отец был консерватором и считал, что сохранение вековых устоев традиционалистского
                    общества в Доминации – залог выживания государства. Он отказывался от малейших послаблений для
                    квиритов («полуграждан», как их иногда зовут в Доминации) и лишь туже закручивал гайки в законах,
                    закреплявших социальное расслоение в империи. Кроме него в галактике так действовали только земные
                    монархи: возможно, именно поэтому он и попытался спасти земную империю от краха – в ней он видел
                    единственного истинного союзника. Тираны тянутся к тиранам.
                </p>
                <p className="card-text text-justify">
                    Дочь же, воспользовавшись свободой действий (пусть и относительной), начала понемногу ослаблять
                    удавку на шее народа. Когда отец обнаружил это, то пришел в ярость, однако позволил дочери
                    действовать так, как она считала нужным. Лишь чтобы после окончания войны демонстративно свернуть
                    все перемены,
                    которые
                    она начала – он назвал это «жестоким уроком» и «первым и единственным предупреждением». Такое
                    поведение посеяло зерно раздора между императором и его наследницей.
                </p>
                <p className="card-text text-justify">
                    Тем не менее Эйенора продолжала оставаться официальной приемницей, поскольку более достойных
                    кандидатов просто не имелось. Бейсенар неоднократно искал их среди многочисленных ветвей семьи
                    Яветов, однако так и не смог найти никого более образованного и, самое главное, приверженного
                    традициям. Вся их ложь и лизоблюдство сходили на нет после первой же инъекции сыворотки правды.
                    Все они оказывались либералами.
                </p>
                <p className="card-text text-justify">
                    Примерно к 1 году ДТ напряжение между отцом и дочерью достигло предела. Когда в земном государстве
                    разразилась <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"гражданская война"}/>,
                    Бейсенар отправил дочь на помощь <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Монарху"}/>,
                    однако она не поддержала
                    «законную» власть в соседнем государстве. Она просто соврала отцу, что готовит экспедиционный флот
                    для подавления мятежа и даже подговорила кузину, <TextInlineLink name={"bs_char_javet_pauline"}
                                                                                     text={"Паулину"}/>, начать обманные
                    приготовления, чтобы
                    тянуть время. В крайнем
                    случае Эйенора планировала просто навести хаос, чтобы помешать землянам эффективно бороться с
                    мятежниками.
                </p>
                <p className="card-text text-justify">
                    Однако этого не потребовалось: михъельмцы, при помощи <TextInlineLink name={"bs_comp_cms"}
                                                                                          text={"агатонской разведки"}/>,
                    смогли сами справиться
                    с земным флотом. Узнав об этом, отец пришел в ярость и начал новый виток поисков другого приемника.
                    Он пытался держать это в тайне, но через лояльных людей Эйенора узнала, что Бейсенар даже планирует
                    обвинить ее в госизмене, когда найдет подходящего кандидата. И в этот раз планка будет гораздо
                    ниже.
                </p>
            </>
    }
}
