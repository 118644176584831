import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Skarsgard_oliver() {
    return {
        "name": "Оливер Скошгард",
        "fullname": <span>Оливер <small>«Оцелот»</small> Скошгард</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} text={"Эсквариат"}/> <TextInlineLink
            name={"bs_state_cydonian_priority"} text={"Приоритета"}/></span>,
        "imgs": [
            {"short": "bs_char_skarsgard_oliver_1_png_500", "big": "bs_char_skarsgard_oliver_1_jpg_full"},
            {"short": "bs_char_skarsgard_oliver_2_jpg_500", "big": "bs_char_skarsgard_oliver_2_jpg_full"},
        ],
        "born": <span>62 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>61</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_hellesians"} text={"хеллесианец"}/></span>,
        "parents": <span>Ксавьер и Камилла Скошгард</span>,
        "parents_adopt": <span>Даврон де Карма</span>,
        "relatives": <span><TextInlineLink
            name={"bs_char_skarsgard_vivien"}/>, <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ивар"}/>,
            Равенна и Карлайл де Карма</span>,
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                    (заплутавший космодесантник)
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"}/>
                </div>
            ],
        "text":
            <>
                <h4 className="title-bg">Детство и юность</h4>
                <p className="card-text text-justify">
                    <span className="strong">Оливер</span> родился на Хеле, за 62 года
                    до <TextInlineLink name={"book_tamanrasett_contact"} text={"Контакта у Таманрасетт"}/>. Раннее
                    детство провел там же, под рубиновыми небесами Дафа-Хару. Из-за весьма высокой гравитации ему
                    и младшей сестре Вивьен делали регулярные инъекции стимуляторов, чтобы тело лучше функционировало
                    в недружелюбных условиях Рубинового лиса. Благодаря этому Оливер заметно сильнее и выше
                    среднестатистического
                    Homo sapiens.
                </p>
                <p className="card-text text-justify">
                    С раннего детства Оливер мечтал пойти по стопам отца, боевого офицера кидонианского военного флота.
                    Однако его смерть в <TextInlineLink
                    name={"bs_state_free_worlds"} text={"Самборе"}/>, когда Скошгард был еще ребенком, больно ударила по
                    психике: мальчик отказался
                    от мечты о флоте. Он бы не вернулся к мыслям о службе, если бы мать, Камилла Скошгард, не вышла замуж
                    за Даврона де Карму, другого кидонианского офицера. Семья переехала
                    на <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонию"}/>,
                    в фамильное гнездо де Карма и там произошло судьбоносное знакомство Оливера с <TextInlineLink
                    name={"bs_char_de_karma_ivar"} text={"Иваром"}/>, который стал
                    для него не просто сводным братом, но и примером для подражания.
                </p>
                <p className="card-text text-justify">
                    Решительность, дерзость, изобретательность Ивара изменили жизненные приоритеты Оливера. Он рос,
                    наблюдая за старшим
                    братом и со временем начал следовать его
                    примеру.
                </p>
                <p className="card-text text-justify">
                    Тем не менее, первые годы после переезда
                    дались тяжело: в среде сверстников Оливер слыл тихоней, иногда даже мальчиком для битья. Скошгарда
                    отправили в дорогую частную школу, поскольку семья де Карма была весьма
                    состоятельна и не желала, чтобы ее новый член "прозябал в бюджетном дурдоме". Там не обладающий
                    соответствующими манерами и социальными навыками Оливер часто становился объектом издевательств и
                    насмешек.
                </p>
                <p className="card-text text-justify">
                    Видя это, Ивар постепенно приучал младшего брата давать сдачи и со временем превратил скромного
                    тихоню в грозу школьных хулиганов. К последнему году учебы Оливер подошел уже самым настоящим
                    верзилой
                    и твердо знал, что свяжет жизнь исключительно со службой на флоте.
                </p>
                <p className="card-text text-justify">
                    Высшее образование он получил в <TextInlineLink name={"bs_state_cydonian_priority_science"} text={"«Бриэнке»"}
                                                popup={"Хеллесианская академия торгового и военного флота имени Бриэнны де Эстрада"}/>,
                    поступить
                    в которую смог только со второго раза. Причиной отказа стало практически полное незнание математики,
                    которое сумел компенсировать только год углубленного курса на <TextInlineLink
                    name={"bs_planet_bassian"} text={"Бассиане"}/> (как раз когда <TextInlineLink
                    name={"bs_char_van_gloria_adel"}/>,
                    будущая королева <TextInlineLink name={"bs_state_cydonian_priority"}
                                                     text={"Приоритета"}/>, писала там свою <TextPopup
                    name={"bs_popups_van_gloria_bachelor_work"} text={"бакалаврскую"}/>).
                </p>
                <h4 className="title-bg">Служба во флоте и Контакт на Тайбенне</h4>
                <p className="card-text text-justify">
                    В академии Оливер, похоже, наконец-то смог раскрыть свой потенциал: несмотря на проблемы с
                    успеваемостью
                    в точных науках, тактические навыки, лидерские качества и физическая форма у него были на высоте.
                    Поэтому еще до окончания учебы он успел побывать в нескольких горячих точках Самборы, "на практике",
                    как это принято называть в кидонианском военном флоте.
                </p>
                <p className="card-text text-justify">
                    Из командировок вернулся новый Скошгард: смелый, упорный и знающий, как вести себя на поле боя.
                    С тех пор его карьера пошла в гору и лишь в 13 ДТ, во время <TextInlineLink name={"bs_planet_tiora"}
                                                                                                text={"Тиорийского инцидента"}/>,
                    он получил свое первое взыскание. Его сводный брат поплатился за этот случай увольнением и лишением
                    всех званий и наград, поэтому Оливер счел последствия для себя даже чересчур мягкими.
                </p>
                <p className="card-text text-justify">
                    Отчасти он оказался прав: в ситуации, когда другие офицеры навсегда лишаются карьерного роста,
                    Оливер начал подниматься по флотской иерархии подозрительно быстро. В этом помогли не столько
                    способности, сколько авторитет, который он заработал в армейских кругах после Инцидента. На фоне
                    постоянно растущей угрозы из Свободных миров, кидонианские адмиралы желали видеть рядом с собой
                    людей, готовых на все ради страны, а не тех, кто думает в первую очередь о новом звании.
                </p>
                <p className="card-text text-justify">
                    Это помогло Скошгарду получать "самые интересные", как он называл их, задания. Офицер провел
                    несколько
                    лет в самых безумных командировках по Свободным мирам, <TextInlineLink name={"bs_space_the_rift"}
                                                                                           text={"Разлому"}/> другим
                    частям галактики, а затем
                    был повышен до эсквайра Её Величества лично Адель ван Глорией. И уже в 3 ДТ возглавил
                    ударное крыло Эскавариата
                    на базе «Объект №111 «Полигон» в системе <TextInlineLink name={"bs_star_schiaparelli"}/>.
                </p>
                <p className="card-text text-justify">
                    Через 2 года после этого назначения случился <TextInlineLink name={"bs_timeline_tajbennae_incident"}
                                                                                 text={"Контакт на Тайбенне"}/> (<TextInlineLink
                    name={"book_hyperbeacons"} text={"«Похитители маяков»"}/>): Оливер первым вошел в контакт с другой
                    разумной жизнью и даже смог убить одного ее представителя. <TextInlineLink
                    name={"bs_spec_vitruvianus"} text={"Витрувианы"}/> не оценили такой подход и
                    уничтожили всю группу высадки, кроме самого Скошгарда.
                </p>
                <p className="card-text text-justify">
                    Там же он познакомился с <TextInlineLink name={"bs_char_the_whip"} text={"Бичом"}/>, которого в
                    шутку прозвал Мартином Сью. Пришелец рассказал ему о
                    надвигающемся вторжении и пообещал помочь людям, но замен потребовал от Оливера не рассказывать
                    собратьям о происходящем, чтобы не спровоцировать витрувианов на более агрессивные действия. Этим
                    закончились «Похитители».
                </p>
            </>
    }
}
