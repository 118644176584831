import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {TextInlineLink} from "../../../components/cms/wiki_functions";
import imgs from "../../../components/cms/images_repo.json";

function Book_Helleon_cold() {
    document.title = "Сергей Крехно - Холодное солнце"
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"/>  Холодное солнце</span>}/>
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_helleon_cold_jpg_ru"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Обложка Холодное солнце Сергея Крехно"
                             src={imgs["book_helleon_cold_jpg_ru"].link}/>
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Холодное солнце</h1></div>
                    <div className="row">
                        <blockquote>
                            <p>Ближе к публикации здесь будут цитаты, а пока лишь снег тихонько скрипит под ботинками
                                врагов...</p>
                            <small><TextInlineLink name={"bs_char_skarsgard_oliver"}/></small>
                        </blockquote>
                    </div>
                    <div className="row">
                        <div className="alert alert-danger text-justify" role="alert">Книга пока не готова
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="21" aria-valuemin="0"
                                     aria-valuemax="100" style={{"width": "27%;"}}>15%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"/> <h6>Статус:</h6> <strong><span
                                className="text-info">готовится к работе</span></strong></li>
                            <li><i className="far fa-list-alt"/> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Черное солнце #5"}/></li>
                            <li><i className="fas fa-angle-double-left"/> <h6>Предыдущая:</h6> <TextInlineLink
                                name={"book_bonecollectors"} text={"Собиратели костей"}/></li>
                            <li><i className="fas fa-angle-double-right"/> <h6>Следующая:</h6> <TextInlineLink
                                name={"book_tamanrasett_contact"} text={"Контакт у Таманрасетт"}/></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Аннотация</h3>
                    <div className=" well">
                        <p className="card-text text-justify">Рано для спойлеров 😬</p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li>Галерея пока пуста</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export {Book_Helleon_cold};
