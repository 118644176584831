import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Firstborns() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>, <TextInlineLink
            name={"book_hyperbeacons"} text={"«Похитители маяков»"}/></>,
        "homeworld": <span>???</span>,
        "state": <span>???</span>,
        "langs": <span>???</span>,
        "size": <span>-</span>,
        "type": <span>разумное</span>,
        "bs_universe_biom": <span>???</span>,
        "text":
            <>
                <h3 className="title-bg">О существах</h3>
                <p className='card-text text-justify'>Перворожденные - название вида существ или цивилизации, которая
                    исчезла примерно за 6 тысяч лет до Таманрасетт. Едиственный выживший представитель, Бич,
                    с тех пор колесил по вселенной без внятной цели.
                </p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'><strong>Больше информации
                        будет после выхода следующих частей <TextInlineLink name={"bs_series"}
                                                                            text={"«Черного солнца»"}/></strong>
                    </p>
                </div>
            </>
    }
}
