import {CrumbsBlackSunWiki} from "../../../../components/Bread_Crumbs";
import {GetWikiArticlesAll} from '../../../../components/cms/wiki_functions';
import {Tooltip} from "antd";

export function GlossaryFullList() {
    document.title = "Кидонианка и Черное солнце"

    let list = GetWikiArticlesAll()
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunWiki name={<span><i className="fa-solid fa-w"/>  Полный список страниц</span>}/>
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12">
                    <div className='row'>
                        <div className='well'>
                            <h4 className='text-center'>ПОЛНЫЙ СПИСОК СТРАНИЦ</h4>
                            <table className="table centered">
                                <thead>
                                <tr>
                                    <th scope="col">Название</th>
                                    <th scope="col">Категория</th>
                                </tr>
                                </thead>
                                <tbody>
                                {list.map(item => (
                                    <tr>
                                        <td>
                                            <Tooltip placement="top" title={item.popup}>
                                                <a href={item.link}>{item.name}</a>
                                            </Tooltip>
                                        </td>
                                        <td>{item.cat_display}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


