import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Talla() {
    return {
        "date": <span>~5200 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_tallesian_principality"}/></span>,
        "habitats": <span>103 млрд. (27 на поверхности)</span>,
        "people": <span>таллесианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>3</span>,
        "g": <span>1.06 земной</span>,
        "atmosphere": <span><span className='badge bg-success'>подходит для людей</span></span>,
        "biosphere": <span>мертва</span>,
        "system": <span>-</span>,
        "sector": <span>-</span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{"short": "bs_wiki_talla_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Талла</span> – столица Княжества
                    Таллесианского, мелкого монархического государства, которое Галактическая каким-то чудом обошла
                    стороной. Нечто вроде Швейцарии, только ее валюта не так ценится в галактике (да вообще не
                    ценится, мало кто даже знает ее название, я в том числе).</p>
            </>
    }
}

