import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Vustain() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "people": <span></span>,
        "moons": <span>17</span>,
        "mother": <span></span>,
        "system": <span><TextInlineLink name={"bs_star_lovecraft_zeta"} text={"Дзеты Лавкрафта"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Грация"}/></span>,
        "type": <span>газовый гигант</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "imgs": [{"short": "bs_wiki_vustain_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                </div>
            ],
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Вустен</span> – малоизвестный мир на
                    окраине Приоритета. Вся система Дзеты не заселена, лишь несколько станций «Кидонианких
                    надпространственных коммуникаций» разбавляют безжизненный пейзаж небольшим количеством
                    разумных существ.
                </p>
            </>
    }
}

