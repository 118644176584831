import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Skarsgard_vivien() {
    return {
        "name": "Вивьен Скошгард",
        "fullname": <span>Вивьен <small>«Заноза-1»</small> Скошгард</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>588-й космоштурмовой полк <TextPopup
            name={"bs_org_cydonian_sof"}
            text={"ССО"}/> <TextInlineLink
            name={"bs_state_cydonian_priority"}
            text={"Приоритета"}/> <TextInlineLink
            name={"bs_comp_esquirete"} text={"«Гётеборгские ведьмы»"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"}/></span>,
        "born": <span>60 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "parents": <span>Ксавьер и Камилла Скошгард</span>,
        "age": <span>59</span>,
        "nation": <span><TextInlineLink name={"bs_people_hellesians"} text={"хеллесианка"}/></span>,
        "parents_adopt": <span>Даврон де Карма</span>,
        "relatives": <span><TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Оливер Скошгард"}/>, <TextInlineLink
            name={"bs_char_de_karma_ivar"} text={"Ивар"}/>,
            Равенна и Карлайл де Карма</span>,
        "imgs": [
            {"short": "bs_char_skarsgard_vivien_1_jpg_500", "big": "bs_char_skarsgard_vivien_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Юность</h3>
                <p className="card-text text-justify">
                    Вивьен родилась на Хеле и все детство провела именно там. Высокая гравитация родины подарила ей
                    крепкие мышцы
                    и большую выносливость, а бойкий характер, похоже, развился как-то сам.
                </p>
                <p className="card-text text-justify">
                    Отца она потеряла в детстве: как и многие кидонианские офицеры, он погиб в <TextInlineLink
                    name={"bs_state_free_worlds"} text={"Свободных мирах"}/>, в одной
                    из многочисленных стычек с пиратами. В отличие от брата, Оливера, который из-за этого впал в
                    депрессию,
                    Вивьен потеря только закалила. Она мечтала пойти на флот, чтобы отомстить <TextInlineLink
                    name={"bs_people_samborians"} text={"самборианцам"}/> и к этой
                    мечте двигалась неуклонно.
                </p>
                <p className="card-text text-justify">
                    После того, как мать вышла замуж за другого офицера, Даврона де Карму, семья переехала на Кидонию.
                    Там Вивьен вымахала на голову выше, чем следовало бы при ее комплекции: стимуляторы роста, которые
                    дают
                    юным хеллесианцам для поддержания тонуса при повышенной гравитации, сыграли с ней и братом злую
                    шутку.
                    Однако это лишь сделало Вивьен более самоуверенной. Мало кто смел пошутить над этой юной особой и не
                    расстаться с зубами.
                </p>
                <p className="card-text text-justify">
                    После школы она поступила в <
                    TextInlineLink name={"bs_state_cydonian_priority_science"}
                                   text={"Академию торгового и военного флота"}
                                   popup={"Хеллесианская академия торгового и военного флота имени Бриэнны де Эстрада"}/> в
                    родной системе <TextInlineLink name={"bs_star_hela_gemini"} text={"Хела-Джемини"}/>. Там Вивьен
                    проучилась примерно год, прежде чем понять, что не хочет
                    возиться с громоздкими неповоротливыми тяжелыми кораблями - ей подавай быстроту и маневренность.
                    Поэтому девушка сменила направление и перешла в разряд <TextInlineLink
                    name={"bs_ships_ship_classes"} text={"пилотов-штурмовиков"}/>. Ей потребовалось немало
                    времени, чтобы подготовиться и проштудировать всю хоть немного значимую литературу на тему
                    пилотирования легких кораблей. В конечном счете Вивьен сдала тесты и после подготовительных курсов
                    попала в особую программу Эсквариата по набору перспективных юных пилотов.
                </p>
                <h3 className="title-bg">Кидонианский военный флот</h3>
                <p className="card-text text-justify">
                    Спустя всего два года Вивьен уже совершила первый боевой вылет и доказала себе и окружающим, что
                    у нее действительно есть талант к управлению легкой техникой.
                </p>
                <p className="card-text text-justify">
                    После шести лет службы в погранфлоте, где она проходила "стажировку" (в Эсквариат не берут новичков,
                    даже если они прошли программу подготовки самого Эсквариата), Вивьен наконец попала на службу в
                    самый настоящий экспедиционный флот, направленный в Свободные миры для "наведения порядка в
                    пограничных с Приоритетом системах. Там она получила огромный опыт штурма укрепленных пиратских
                    баз
                    и маневренного космического боя.
                </p>
                <p className="card-text text-justify">
                    Служба в Свободных мирах начисто отрезала ее от семьи, с которой Вивьен виделась не чаще раза
                    в три месяца. Поэтому никто не удивился, когда она перевелась в элитное подразделение
                    непосредственно
                    Эсквариата и окончательно исчезла с радаров близких людей. В 15 <TextInlineLink name={"bs_timeline"}
                                                                                                    text={"ДТ"}/> девушка
                    попала в 588-й космоштурмовой полк <TextPopup
                    name={"bs_org_cydonian_sof"}
                    text={"ССО"}/>, где получила позывной «Заноза» из-за своего
                    вредного и насмешливого нрава. Когда спустя 10 лет ей доверили командовать звеном, все подразделение
                    получило имя «Заноза» как дань уважения опытному пилоту.
                </p>
            </>
    }
}
