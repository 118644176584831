import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function AdamsUrsula() {
    return {

        "name": "Урсула Адамс",
        "fullname": <span>Урсула Лореллин Юнона Адамс</span>,
        "born": <span>68 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>67</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_tribalon_at_alamein"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <>Лорель Сорли и Юнона Адамс</>,
        "relatives": <span>нет</span>,
        "work": <span>писатель, частный предприниматель, <strike><TextInlineLink
            name={"bs_comp_bonecollectors"}/></strike></span>,
        "imgs": [
            {"short": "bs_char_ursula_adams_1_jpg_500", "big": "bs_char_ursula_adams_1_jpg"},
            {"short": "bs_char_ursula_adams_2_jpg_500", "big": "bs_char_ursula_adams_2_jpg_full"}
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                    <p>(упоминается)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_the_free_traders"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                Урсула - героиня Собирателей костей, Вольных торговцев и (возможно) нескольких пока только планируемых произведений. Автор знаменитого «Путеводителя по Млечному Пути для перелетных птиц», в котором отчасти в документальном стиле, отчасти в художественном, описала свои приключения по всей галактике. Прозвища «Птичка» и «Перелетная» получила за неспособность задержаться в одном месте дольше, чем на пару месяцев.
                </p>
                <p className="card-text text-justify">
                Девушка родом с Трайбалона, что в Трайбалонской республике, в окрестностях Туманности Малой короны (сектор Виковира). Ее родители были агатонскими горнорудными инженерами и геологоразведчиками. Семья много колесила по сектору, разыскивая месторождения полезных ископаемых и с самого детства Урсула прониклась любовью к путешествиям, исследованиям, поискам разного рода диковинок.
                </p>
                <p className="card-text text-justify">
                Нормального школьного обучения и социализации она не получила. Базовым предметам училась удаленно, проведя почти все детство и юношество рядом с родителями, в экспедициях галактического «Союза терраформеров» и агатонского горно-рудного концерна Vikoveera MineralWorks. Успеваемость при таком формате учебы была низкой и репетиторов Урсула меняла как перчатки, ударяя по семейному бюджету тем сильнее, чем старше становилась.
                </p>
                <p className="card-text text-justify">
                Однако родители поощряли ее любознательность и награждали за нестандартное мышление во всем, что выходило за рамки стандартного школьного курса. Уже в возрасте 10 лет она начала осваивать оборудование, применявшееся агатонскими геологоразведчиками и быстро перешла от использования приборов к изучению их работы. Карманные деньги в условиях космического флота сложно потратить, поэтому девушка использовала накопленные (и весьма немаленькие) суммы, чтобы купить и понять принципы работы разного рода сканеров, детекторов и радаров.
                </p>
                <p className="card-text text-justify">
                    Урсула нашла немало месторождений редких металлов и ценных руд, воспринимая это как
                    развлечение, а не работу. Со временем она стала более серьезно относиться к делу и даже
                    завела своеобразное портфолио, которое затем помогло поступить в университет по упрощенной
                    программе.
                </p>
                <p className="card-text text-justify">
                    Разведфлот, частью которого был корабль родителей Урсулы, включал не только инженерные
                    корабли, но и военные машины. Его приходилось регулярно защищать от пиратов,
                    желавших поживиться ценными образцами, найденными разведкой на просторах галактики.
                    Во флоте было мало сверстников Адамс, поэтому большая часть друзей девушки состояла
                    из охранявших ее агатонских космопехотинцев. Адамс вынесла из этой дружбы немало
                    полезных для выживания уроков: от приемов владения рельсовым оружием, до знания
                    болевых точек на теле человека.
                </p>
                <p className="card-text text-justify">
                    Среди образцов, найденных геологами, попадались не только драгоценные руды, но и диковинные
                    артефакты ушедших эпох.
                    Мощные сканеры геологоразведки работают иначе, чем военные, и настроены на химические
                    соединения, часто игнорируемые военными судами. Именно поэтому родители Урсулы неоднократно
                    засекали древние произведения искусства, погребенные на забытых планетах, разного рода
                    тайники аламарси и даже целые корабли кочевников, рухнувшие на неизученные миры
                    тысячи лет назад.
                </p>
                <p className="card-text text-justify">
                    Такие находки вызывали у Урсулы живой интерес и помогли выбрать жизненный путь. Она
                    пыталась понять историю каждого подобного объекта и со временем осознала, что
                    больше всего в жизни тяготеет именно к археологии и древней истории человечества.
                </p>
                <p className="card-text text-justify">
                    Именно поэтому Урсула первым делом подала документы на кафедру археологии Агатонского гуманитарного
                    университета,
                    кампус которого стал ее первым постоянным домом за много лет. Пришлось трудно: жизнь на планете
                    угнетала Адамс, привыкшую к тесным коридорам корабля и фильтрованному воздуху, а учеба отбирала
                    буквально все время.
                    Тем не менее, Урсула получила диплом археолога с отличием. И еще до окончания учебы успела
                    побывать во многих экспедициях, куда ее брали благодаря внушительному списку оборудования,
                    с которым девушка работала с самого детства.
                </p>
                <p className="card-text text-justify">
                    Через год после начала учебы она также поступила на заочное обучение в Аламейнский
                    институт развединженерии и периодически наведывалась на родной Трайбалон, чтобы оттачивать
                    уже имевшиеся навыки. Развединженерия - специальность близкая к военному флоту, так как
                    базовые умения, полученные студентами, часто нужны и на боевых кораблях. Ведь работа со
                    сканерами мало отличается в зависимости от типа оборудования: гражданские,
                    полицейские, военные или научные приборы почти идентичны по принципу функционирования.
                    Поэтому, чтобы подготовить студентов к работе в любой сфере,
                    программа включала дисциплины военного толка, связанные с разного рода оружием,
                    системами защиты и полевой медициной.
                </p>
                <p className="card-text text-justify">
                    К 30-ти годам Урсула получила свою первую официальную работу: попала в команду агатонских
                    археологов, работавших преимущественно в <TextInlineLink name={"bs_space_democratic_space"}
                                                                             text={"Демкосмосе"}/> (чаще всего
                    в <TextInlineLink name={"bs_space_breeze_sector"} text={"Бризе"}/> и <TextInlineLink
                    name={"bs_space_ternerian_end"} text={"Тернерианском пределе"}/>).
                    Тогда же началась и <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактическая война"}/>,
                    размах которой в первые годы был настолько пугающим, что Адамс едва не записалась
                    добровольцем на военный флот.
                </p>
                <p className="card-text text-justify">
                    Когда боевые действия сместились <TextInlineLink name={"bs_space_the_old_space"}/>, Урсула
                    неоднократно
                    участвовала в восстановлении разрушенных землянами миров. Она на общественных началах
                    помогала изучать здания на предмет скрытых от глаз повреждений и искать неразорвавшиеся снаряды
                    на орбитах планет и гипертрассах.
                </p>
                <p className="card-text text-justify">
                    Примерно с 36 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (с 32 лет) Юнона вела
                    блог в <TextInlineLink name={"bs_tech_grn"} text={"ИнтерСети"}/>, где рассказывала об
                    археологических площадках, на которых побывала
                    и о процессе послевоенного восстановления Демкосмоса. Она старательно документировала
                    практически все, чему была свидетелем: от обнаружения древних захоронений аламарси, до
                    изучения подозрительных сигналов на сканерах глубокого космоса вокруг Туманности Малой Короны.
                </p>
                <p className="card-text text-justify">
                    После 15 лет блоггинга и 17 лет работы археологом, в 21 <TextInlineLink name={"bs_timeline"}
                                                                                            text={"ДТ"}/>, она
                    неожиданно уволилась с работы и исчезла
                    из публичного поля.
                </p>
                <h3 className="title-bg">Эволюция героя</h3>
                <p className="card-text text-justify">Урсула прошла путь от абстрактной идеи
                    виртуального персонажа, до вполне себе настоящего действующего лица. Под виртуальным
                    здесь стоит понимать героя, который везде только упоминается, но нигде не появляется.
                </p>
                <p className="card-text text-justify">Она должна была стать просто автором известной книги,
                    чьи цитаты будут использованы в реальных книгах о <TextInlineLink name={"bs_weaponry_the_black_sun"}
                                                                                      text={"Черном солнце"}/> и в этой
                    небольшой
                    энциклопедии. Затем стало ясно: она настолько проработана, что достойна большего,
                    особенно в условиях расширяющейся вселенной, которую не получится показать через призму
                    одной только <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Розали"}/> и ее товарищей.
                </p>
            </>
    }
}
