import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Languages() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Аламарси</span> – язык кочевников,
                    никогда не ступающих на планеты. Можно найти <TextInlineLink name={"bs_lang_alamarsie"}
                                                                                 text={"тут"}/>.</p>
                <p className="card-text text-justify">
                    <span className="strong">Арум (arum linga – золотой язык)</span> или <span
                    className="strong">«агатонский»</span> описан в истории об <TextInlineLink name={"bs_lang_arum"}
                                                                                               text={"агатонцах"}/>.</p>
                <p className="card-text text-justify"><span
                    className="strong">Земной</span> теперь в статье про <TextInlineLink name={"bs_lang_earth"}
                                                                                         text={"землян"}/>.</p>
                <p className="card-text text-justify"><span className="strong">Кидонианский</span> переехал
                    в <TextInlineLink name={"bs_lang_cydonian"} text={"статью о Приоритете"}/>.</p>
                <p
                    className="card-text text-justify">Про <span className="strong">Самборианский</span> можно почитать
                    в <TextInlineLink name={"bs_lang_samborian"} text={"статье о самборианцах"}/>.</p>
            </>
    }
}
