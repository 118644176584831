export function Antimatter() {
    return {
        "science": <span>квантовая физика</span>,
        "use": <span>выработка энергии, оружие</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Антиматерия</span> – злой
                    брат-близнец материи. Если их столкнуть, то оба аннигилируют с выбросом, грубо говоря,
                    радиоактивного света.</p>
            </>
    }
}
