import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Alamarsie() {
    return {
        "homeworld": <span>нет</span>,
        "state": <span>нет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_alamarsie"} text={"аламарси"}/></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_browdy_adam_1_png_200"}/></li>
            <li><ImgRound3EM img={"bs_char_kaory_nazil_1_jpg_200"}/></li>
        </ul>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Аламарси</span> – кочевники, не
                    признающие жизнь на планетах и обитающие в гигантских <TextInlineLink name={"bs_tech_hiveship"}
                                                                                          text={"кораблях-ульях"}/>.
                    Первыми аламарси были
                    обычные земляне, покинувшие дом в поисках новых планет, но вынужденные состариться и умереть на
                    кораблях. Иногда сменялись целые поколения, прежде чем машина достигала нового дома, а
                    технология крио-сна появилась гораздо позже вылета первых колонистов.</p><p
                className="card-text text-justify">Так что зачастую новоприбывшие, проведя десятки лет в космосе и
                никогда не видевшие планет, просто отказывались туда спускаться. Они оставляли корабль на орбите и
                пользовались миром внизу как источником сырья. Постепенно туда подтягивались новые колонисты и
                привозили с собой продвинутые технологии, в том числе световые двигатели. Так аламарси получили
                возможность относительно быстро перемещаться от планеты к планете, если предыдущая была бедной на
                ресурсы.</p>
                <p className="card-text text-justify">
                    Сейчас их традиционно считают бандитами и
                    стараются избегать. Мало кому из кочевников удалось прижиться среди планетников, даже при большом
                    желании. Из-за предрассудков, они до конца жизни остаются изгоями и вынуждены для выживания нарушать
                    закон – порочный круг.
                </p>
                <p className="card-text text-justify">
                    После начала <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактической"}/> большинство
                    аламарси перебралось на территории за пределами населенных систем. Они и раньше старались
                    контактировать
                    с цивилизацией только по особым случаям, но после того, как сумасшедшие планетники
                    устроили безумную бойню по всему Старому и Демкосмосу, многие кочевники окончательно разорвали
                    с ними связи. К середине войны больше сотни ульев переместилось из центральных
                    частей <TextInlineLink name={"bs_maps_oecumene"} text={"ойкумены"}/> в
                    такие места как Тернерианский предел, Вернонская пустота и даже отдаленные зоны рукавов Лебедя и
                    Щита-Центавра.
                    Многие из них исчезли из информационного пространства навсегда и, возможно, были уничтожены
                    в результате несчастных случаев в неизведанных частях галактики. В пользу этой теории говорит тот
                    факт, что ионные следы многих флотов в какой-то момент оборвались и больше нигде не
                    регистрировались.
                </p>
                <p className="card-text text-justify">
                    Примерно к 12-10 ДТ некоторые кочевники все же вернулись в населенные части галактики, но
                    как правило не контактировали больше с жителями планет. Те, с кем удалось поговорить на камеру,
                    рассказывали пугающие истории о неведомых тварях из глубин космоса, что вот-вот нападут на
                    человечество. Некоторые твердили о "Том самом", его "Черном-Оружии-Решающим-Все" и читали
                    старые артурианские молитвы, которые родители-аламарси напевают своим детям вместо колыбельных.
                    Интерпретировать и понять их рассказы планетникам не удалось, но достверно известно, что после таких
                    вестей еще больше ульев устремилось прочь из населенных областей космоса. Некоторые даже
                    вознамерились покинуть
                    галактику, хотя точного плана, как это сделать, у них не было.
                </p>
                <h3 id={"language"} className="title-bg">Язык</h3>
                <p className="card-text text-justify"><span className="strong">Язык кочевников</span> тоже зовется
                    аламарси. Несет в себе следы почти всех земных диалектов, поскольку
                    исторически аламарси не имели этнической принадлежности и формировали общества из выходцев со
                    всех регионов Старой Земли. Со временем, по мере формирования отдельных этносов уже среди
                    кочевников, язык тоже стал расслаиваться на множество диалектов, сохранив лишь базовые сходства
                    с языками предков.</p>
            </>
    }
}
