import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ajliraen() {

    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "habitats": <span>57 млрд. на поверхности + 198 млрд. в системе</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>нет</span>,
        "g": <span>1.01 земной</span>,
        "atmosphere": <span className="text-success">подходит для людей</span>,
        "biosphere": <span>мертва</span>,
        "system": <span><TextInlineLink name={"bs_star_raasal_ajlyrien_dominion"} text={"Доминион Раасал"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Травалсар"}/></span>,
        "type": <span><span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_ackron_nebulae"}/></span>,
        "imgs": [{"short": "bs_wiki_ajliraen_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify">Колонию на <span className="strong">Айлирэне</span> основали
                    почти одновременно с Кидонией. На тот момент для землян путь к системе Доминиона был одним из самых
                    сложных и долгих,
                    но путешествие того стоило: Айлирэн имел свою биосферу, которую пришлось лишь слегка
                    подкорректировать для Homo sapiens.
                </p>
                <p className="card-text text-justify">
                    Первым колонистам пришлось несладко, но высадка и обустройство прошли гораздо лучше,
                    чем у <TextInlineLink name={"bs_char_brienne_de_estrada"} text={"Бриэнны де Эстрада"}/> во время
                    колонизации <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонии"}/>.
                    Новоиспеченные айлири быстро разорвали все связи с <TextInlineLink name={"bs_planet_earth"}
                                                                                       text={"Землей"}/> и другими
                    колониями - начали выстраивать
                    свое общество и старались не повторить ошибок предков. Если почитать историю Айлирэнской
                    Доминации, станет ясно - ничего не вышло.
                </p>
                <p className="card-text text-justify">
                    Отречься от бывших соотечественников оказалось легко: путь до Земли и до Кидонии
                    занимал десятилетия, некоторые корабли вообще не долетали. Связи тоже было, а потому
                    лишь <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"}/> были спутниками айлирэнской
                    цивилизации в первые века
                    освоения планеты.
                </p>
                <p className="card-text text-justify">У Айлирэна три солнца: <TextInlineLink
                    name={"bs_star_raasal_ajlyrien_dominion"} text={"Раасал, Айлирэн и Доминион"}/>. Первые два – желтые
                    карлики, совсем как Земное, а третье – красный
                    гигант. Здесь всегда царят белые ночи и некогда была очень буйная флора, причудливая
                    и красивая. Но айлири, как и многие другие людские общества,
                    выбрали путь урбанизации и забетонировали все к чертям.</p>
            </>
    }
}
