import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Cydonian_adimensional_communications() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>частная корпорация</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                        <span>обслуживание ИнтерСети в Приоритете и Самборе</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span
                    className="strong">Офис КНК переехал в <strike>новое здание</strike> <TextInlineLink
                    name={"bs_state_cydonian_adimensional_communications"} text={"новую статью"}/>.</span></p>

            </>
    }
}
