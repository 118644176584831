import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_hydra() {
    return {
        "homeworld": <span>неизвестно</span>,
        "size": <span>неизвестно</span>,
        "type": <span>мифологическое</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} text={"Артурианство"}/></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Стальная Гидра</span> или
                    просто <span className="strong">Гидра</span> – космическое существо в ранних мифах аламарси (ок.
                    5000 - 4000 лет до Таманрасетт) и по совместительству (некому мистическому стечению
                    обстоятельств) самое распространенное ругательство. Некоторые аламарси верили, что космос полон
                    живых существ, исполинов, способных перемещаться без помощи техники, словно живые космолеты. Они
                    якобы пожирали различные минералы из толщи планет, но при случае могли полакомиться и консервами
                    (людскими кораблями, то бишь). Именно на них аламарси возлагали основную вину за пропажу
                    большинства своих кораблей, а вовсе не на радиацию, метеориты, криворуких штурманов или, на
                    худой конец, злых (не)гуманоидов.</p>
            </>
    }
}
