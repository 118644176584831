export function Mpg() {
    return {
        "science": <span>физика</span>,
        "use": <span>охрана труда, космические полеты</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Компенсатор инерции (он же ГМТ – генератор материальной точки)</span> –
                    штука, которая <span className="strike">компенсирует инерцию</span> угадай, что делает? Верно,
                    превращает корабль или любое другое транспортное средство в единый физический объект
                    (материальную точку): при ускорении машины инерция не действует на объекты внутри корпуса.
                    Поэтому люди не ощущают перегрузок, а предметы не летают по каютам во время ускорения или
                    торможения. Без этого световые полеты невозможны: чтобы достичь и превысить скорость света за
                    приемлемое время, потребуется очень большое ускорение, которое убьет экипаж за считанные
                    секунды, если не включить ГМТ.</p><p className="card-text text-justify">В основе технологии
                лежит самый обычный генератор искусственной гравитации, но хитро модифицированный: гравиполе
                направляется внутрь корпуса и фиксирует все объекты, словно бы зажимая их в неосязаемые тиски. Таким
                образом люди, предметы и части корпуса не ощущают перегрузок, находясь в условном гравитационном
                пузыре, гасящем воздействие внешних сил.</p><p className='card-text text-justify'>На большинстве
                кораблей генератор гравитации и ГМТ – это одна и та же система, поэтому при выходе из строя одной ее
                части, моментально перестает работать вторая. Справедливо и другое: если отключить искусственную
                гравитацию, эффекты ГМТ станут ощутимее и уйти на свет получится заметно быстрее. <s>На круизном
                    лайнере так лучше не делать: после пары приступов тошноты люди потребуют вернуть деньги за
                    билеты.</s></p>
            </>
    }
}
