import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function EsghariotEnNuvnish() {

    return {
        "date": <span>~5100 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span>нет</span>,
        "habitats": <span>0</span>,
        "people": <span></span>,
        "moons": <span>нет</span>,
        "g": <span>0.96 земной</span>,
        "atmosphere": <span className="badge bg-danger">опасна для жизни</span>,
        "biosphere": <span className="badge bg-danger">уничтожена</span>,
        "system": <span>Исгариота</span>,
        "sector": <span><TextInlineLink name={"bs_space_breeze_sector"} text={"Бриз"}/></span>,
        "type": <span>землеподобная</span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "imgs": [{"short": "bs_wiki_esghariot_en_nuvnish_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">О мире</h3>
                <p className="card-text text-justify">
                    <span
                    className="strong">Исгариот-ин-Нувниш (Мертвый Исгариот)</span> – промышленный мир на окраине
                    сектора Бриз. Название переводится с аламарси как «камень с полезной рудой». Кочевники открыли
                    ее огромное количество лет назад, первые следы добычи ископаемых тут датируются всего парой
                    веков после распространения световых перелетов. Судя по всему, аламарси останавливались здесь
                    для починки кораблей и синтеза топлива, сохранились даже древние навигационные радиомаяки. Во
                    время <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактической"}/> земляне пытались
                    захватить Исгариот, чтобы ослабить промышленность демократов, но не успели до прибытия
                    агатонского подкрепления.</p><p className="card-text text-justify">В итоге, <TextInlineLink
                name={"bs_char_mereglis_sonya"} text={"Соня Мергелис"}/>, командовавшая операцией,
                отдала приказ разрядить в планету весь арсенал <TextInlineLink name={"bs_ships_nyorun_dreams"}
                                                                               text={"«Грез Ньёрун»"}/>. За несколько
                часов
                там погибло девяносто восемь процентов населения, а планета стала непригодной для жизни на миллионы
                лет вперед.
                </p>
            </>
    }
}

