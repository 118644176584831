import {TextInlineLink} from "../../../../../components/cms/wiki_functions";
import {Appearance} from "../../../../../components/appearance";
import imgs from '../../../../../components/cms/images_repo.json';

export function Cydonia() {

    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>23 млрд. на поверхности + 169 млрд. в системе</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>планетарное кольцо</span>,
        "g": <span>1.04 земной</span>,
        "atmosphere": <span className="badge bg-success">подходит для людей</span>,
        "biosphere": <span>терраформирована минимально, фауны почти нет, очень буйная флора</span>,
        "system": <span><TextInlineLink name={"bs_star_mirage"} text={"Мираж"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ариадны"}/></span>,
        "type": <span>землеподобная, <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"}/></span>,
        "imgs": [{"short": "cydonia_500", "big": "cydonia"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_ru"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}}
                         alt="Кидония" src={imgs["bs_wiki_cydonia_map_ru"].link} fetchpriority="low"/>
                </a>

                <p className="card-text text-justify"><span className="strong">Кидония</span> (кид. Kydon'á или Kidonna
                    или Cidonne) –
                    планета-сад в <TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластере Бриэнн"}/>.
                    Названа в честь древнего греческого полиса на северо-западе Крита. Заселена после
                    начала сверхсветовых перелетов, но долгое время не имела связи с <TextInlineLink
                        name={"bs_planet_earth"} text={"Землей"}/> из-за низкой скорости
                    перемещения людей по галактике.</p>
                <p className="card-text text-justify">На планете строго лимитированы размеры и количество
                    построек, недвижимость ценится на вес золота, а большую часть Кидонии покрывают
                    бескрайние поля, сады, парки, леса и луга. Здесь нет пустынь и практически нет
                    животных, но зато есть невероятное количество видов растений, которые дают пищу и
                    кров в основном насекомым.</p>
                <p className="card-text text-justify">На Кидонии приживается любая флора с землеподобных
                    планет (со схожим составом, плотностью атмосферы, температурным режимом, параметрами
                    почвы и т.д.), нужно только выбрать место. Родные для Кидонии виды не знают, что
                    такое зима или осень – из-за отсутствия наклона к эклиптике, смена времен года не
                    происходит. Различается только среднесуточная температура в разных широтах. Кидония
                    заметно более влажная и холодная планета, чем большинство родственниц Земли, даже в
                    своих засушливых и тропических областях.</p>

                <p className="card-text text-justify">Кроме этого, на Кидонии практически нет фауны, которая
                    представлена
                    в основном рукокрылыми (летучими мышами и некоторыми уникальными для Кидоини существами).
                    Большая часть из них питается растительной пищей, но есть и виды, поедающие насекомых, которых
                    на Кидонии невероятно много: отсутствие птиц и обилие цветущих растений плодит разного рода
                    мошкару в экстремальных количествах.</p>
                <h3 className="title-bg">Занимательные факты</h3>
                <p className="card-text text-justify">
                    На Кидонии бывают лепестковые вихри и даже дожди с цветочными лепестками и семенами. Многие
                    растения именно так и размножаются. Из-за отсуствия смены времен года, у большей части местной
                    флоты цветение и плодонос происходят несколько раз в году. И обилие этой флоры часто приводит к тому,
                    что ураганы поднимают в воздух целые облака лепестков, семян и соцветий, обрушивая их позднее в
                    других регионах.
                </p>
                <p className="card-text text-justify">
                    Насекомых на Кидонии настолько много, что они часто сбиваются в стаи размером в несколько десятков
                    километров. Периодические миграции местного эквивалента саранчи даже включены в прогнозы погоды.
                </p>
                <p className="card-text text-justify">
                    Место, где несколько тысяч лет назад высадились
                    первые колонисты, зовется горой Панмор. Это высокое скалистое образование, поросшее
                    холодными влажными джунглями, на вершине которого можно найти небольшой мемориальный
                    комплекс и несколько памятников.
                </p>
            </>
    }
}
