import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Daydaris() {

    return {
        "date": <span>~543 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "habitats": <span>117 млн.</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>1</span>,
        "g": <span>1.02 земной</span>,
        "atmosphere": <span>загрязнена, <span className="text-danger">опасна для людей</span></span>,
        "biosphere": <span>почти уничтожена</span>,
        "system": <span><TextInlineLink name={"bs_star_leeroy_jenkins"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_megasthenes"} text={"Мегасфен"}/></span>,
        "type": <span>землеподобный <TextInlineLink name={"bs_universe_planet_types"}
                                                    text={"промышленный мир"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_vataary_vector"}/></span>,
        "imgs": [{"short": "bs_wiki_daydaris_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Дейдарис </span> –
                    планета, на которой снег воняет старыми носками, а в остальном ничего особенного. Находится в
                    Доминации, четвертый мир в системе Лирой-Дженкинс.
                </p>
                <p className="card-text text-justify"><TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                      text={"Ивар"}/>, <TextInlineLink
                    name={"bs_char_browdy_adam"} text={"Адам"}/> и <TextInlineLink name={"bs_char_can_beucan_gaereas"}
                                                                                   text={"Гэри"}/> выполняли там работу
                    для
                    принцессы <TextInlineLink name={"bs_char_javet_eijenora"} text={"Эйеноры Явет"}/>, незадолго до
                    начала «Кидонианки». С тех пор бедняге-аламарси иногда снится, что он идет по бесконечному
                    заснеженному полю и судорожно ищет в рюкзаке респиратор.
                </p>
                <p className="card-text text-justify">
                    Принято считать, что именно с Дейдариса ведет свою историю печально известный <TextInlineLink
                    name={"bs_comp_feathered_serpent"} text={"Пернатый змей"}/>.
                </p>

                <div className="alert alert-danger">
                    <p className='card-text text-justify'><strong>Больше информации
                        будет после выхода следующий частей <TextInlineLink name={"bs_series"}
                                                                            text={"«Черного солнца»"}/></strong>
                    </p>
                </div>
            </>
    }
}
