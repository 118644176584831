import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_rift() {
    return {
        "date": <span>-</span>,
        "type": <span>область космоса</span>,
        "owned": <span>нет</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "people": <span>земляне, михъельмцы, айлири, кидонианцы, самборианцы (<TextInlineLink name={"bs_spec_humans"}
                                                                                              text={"Homo sapiens"}/>)</span>,
        "imgs": [{"short": "bs_wiki_the_rift_map_ru", "big": "bs_wiki_oecumene_map_ru"}],

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p class="card-text text-justify"><span class="strong">Разлом</span>
                    – большущая дыра между кластерами. Работает как естественная граница между Бриэнн и <TextInlineLink
                        name={"bs_space_the_old_space"} text={"Старым космосом"}/>. Звезд и планет тут маловато, поэтому
                    на карте он действительно смотрится как разлом. Правда, она должна быть двухмерная и под
                    определенным
                    углом, иначе увидишь просто черную кляксу.
                </p>
            </>
    }
}

