import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink,TextPopup} from "../../../../../components/cms/wiki_functions";

export function Torvalds_tomas() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Томас Торвальдс",
        "fullname": <span>Томас Торвальдс</span>,
        "born": <span>179 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>178</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"}/></span>,
        "nation": <span>михъельмец</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>и.о. премьера <TextPopup name={"bs_popups_state_shark_republic"} text={"Михъельмской Республики Акулы"} /></span>,
        "imgs": [
            {"short": "bs_char_tom_torvalds_1_jpg_500", "big": "bs_char_tom_torvalds_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    Том Торвальдс - идейный вдохновитель и один из лидеров <TextInlineLink
                    name={"bs_timeline_the_shark_revolt"} text={"Восстания Акулы"}/> 1 г. ДТ. Коренной михъельмец.
                </p>
                <p className="card-text text-justify">
                    Он родился в 179 г. ДТ на Михъельме, в не очень благополучном районе южного полушария. До своего
                    шестого десятка Торвальдс не имел постоянного заработка и провел много лет, скитаясь по сектору
                    Акулы в
                    качестве матроса, оператора грузовых роботов и даже обычного грузчика (что большая редкость для
                    развитых частей галактики). В 131 ДТ, после нескольких неудачных попыток, смог сдать экзамен
                    и получить место в одном из земных университетов, на кафедре Истории Старого космоса.
                </p>
                <p className="card-text text-justify">
                    Программа "Перспективные кадры для Монархии", которая оплатила его обучение, собирала молодых людей
                    по всем земным территориям, чтобы затем вырастить из них управленцев для окраинных секторов.
                    Именно окраины тогда нуждались в талантливых, амбициозных и, самое главное, лояльных чиновниках:
                    земной
                    режим регулярно шатался от локальных бунтов и отчаянно искал способ изменить мнение местных.
                </p>
                <p className="card-text text-justify">
                    По иронии, именно времена учебы на Месяце, земной луне, Торвальдс в будущем назвал поворотным для
                    своего мировоззрения. Там он осознал, насколько плохо земляне относятся к зависимым планетам и
                    насколько низко ценят гигантский вклад Михъельма в экономику страны.
                </p>
                <p className="card-text text-justify">
                    Спустя много десятилетий, когда Монарх развязал <TextInlineLink name={"bs_timeline_galactic_war"}
                                                                                    text={"Галактическую"}/>, Торвальдс
                    был одним из первых, кто
                    задумался о независимости для своей планеты. На тот момент это было невозможно: адмиралы Паучьего
                    Эшелона, охранявшего Михъельм, потерпели сокрушительное поражение при попытке отстоять право на
                    самоопределение и надежда была утрачена. Для всех, кроме Торвальдса.
                </p>
                <p className="card-text text-justify">
                    После окончания войны он тайно связался с <TextInlineLink name={"bs_comp_cms"}
                                                                              text={"агатонской разведкой"}/> и с ее
                    помощью создал целую сеть
                    заговорщиков. По его замыслу эти люди должны были одновременно выступить против Монарха и открыто
                    отказаться выполнять его приказы, но из-за решительных действий <TextInlineLink
                    name={"bs_char_mereglis_sonya"} text={"гросс-адмирала Мергелис"}/> план
                    провалился:
                    она открыла охоту на сторонников Торвальдса и лишь <TextInlineLink name={"bs_char_browdy_adam"}
                                                                                       text={"удача"}/> и <TextInlineLink
                    name={"bs_char_de_karma_ivar"} text={"профессионализм"}/> <TextInlineLink
                    name={"bs_char_esora_eve"} text={"агатонских диверсантов"}/>
                    помогли молодому восстанию выжить.
                </p>
                <p className="card-text text-justify">
                    В 1 году ДТ Торвальдс стал первым лидером Республики Акулы и буквально галактическим героем,
                    выстоявшим под натиском морально отставшей земной автократии.
                </p>
            </>
    }

}