import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ship_classes() {
    return {
        "is_an_article": true,
        "text":
            <>
                <h3 className="title-bg">Гражданские</h3>
                <p
                    className="card-text text-justify"><span className="strong">Атмосферный катер</span> – машина с
                    атмосферной двигательной установкой, не способная выходить в космос или не предназначенная для
                    долгого нахождения вне магнитосферы. Как правило состоит из хрупких материалов, которые легко
                    повреждает межзвездная радиация. К таким относится множество типов кораблей: от роботов-такси до
                    летающих скутеров.</p>
                <p className="card-text text-justify"><span className="strong">Балкер</span> –
                    крайне вместительный грузовой корабль, предназначенный в первую очередь для перевозки природных
                    ресурсов. В большинстве случаев у балкера нет ангаров или трюмов: это просто огромная грузовая
                    платформа, на которую выгружается руда или лес, а затем накрывается неким подобием крышки. Удобство
                    погрузки при этом нивелируется проблемами при разгрузке: для каждого типа товара требуется
                    специализированный терминал. Кроме этого, балкер вынужден поддерживать особые условия хранения для
                    некоторых грузов, что в случае с обычным грузовиком берет на себя автономный контейнер, помещаемый в
                    трюм.</p>
                <p className="card-text text-justify"><span
                    className="strong">Гражданская канонерка</span> – машина с оружием на борту, которой владеет
                    гражданское лицо. Канонеркой может быть и лайнер, и яхта - это не имеет принципиального значения,
                    т.к. такие машины, независимо от размеров и машрута, проходят специальную сертификацию и стоят на
                    учете.</p>
                <p className="card-text text-justify">
                    <span
                        className="strong">Инженерное судно</span> – корабль с оборудованием для проведения технических
                    работ
                    в космосе или в атмосфере. Это может быть как продвинутый сканер для поиска пропавших людей, так
                    и приспособления для ремонта других машин. Инженерное судно подлежит особому обращению и правила его
                    пролета через некоторые зоны или даже нахождения на орбите могут отличаться в разных частях галактики.
                    Причина в том, что продвинутое оборудование может служить как законопослушным целям, так и помогать
                    преступникам или шпионам. Поэтому если на вашем судне стоит нетипичный радар или особый сканер
                    минералов, вам лучше изучить законы страны, в которую летите.
                </p>
                <p
                    className="card-text text-justify">
                    Примеры: <TextInlineLink name={"bs_ships_redeyed"}/>, <TextInlineLink name={"bs_ships_plato"}/>.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Лайнер</span> – судно для
                    перевозки пассажиров. Любой корабль, выполняющий рейсы между планетами и имеющий заранее
                    установленный маршрут, считается лайнером (независимо от количества мест). При этом такой маршрут
                    всегда согласовывается в местными властями, чтобы избежать потенциально опасных областей или
                    пиратских налетов.
                </p>
                <p className="card-text text-justify"><span className="strong">Улей</span> – корабль для поддержания
                    жизни большого количества людей (сотни тысяч и даже миллионы). Подробнее
                    в <TextInlineLink name={"bs_tech_hiveship"} text={"отдельной статье"}/>.</p>
                <p className="card-text text-justify"><span
                    className="strong">Фрейтер</span> или <span className="strong">фрахтовик</span> – классический
                    грузовой корабль, перевозящий товары в трюме или ангаре. Название происходит от слова «фрейт» –
                    груз, фрахт. От балкера отличается не только типом погрузки, но и стоимостью перевозки: фрахтовик
                    имеет стандартные крепления для контейнеров и стыковочные модули, которые позволят легко выгружать
                    товары в любом порту, без необходимости строить специальные терминалы для конкретных грузов.</p>
                <p
                    className="card-text text-justify"><span className="strong">Яхта</span> – частный пассажирский
                    корабль. От лайнера отличается форматом полета: яхта не имеет заранее установленного маршрута и
                    выполняет рейсы на свой страх и риск. Хозяин яхты не обязан согласовывать свой маршрут с властями, а
                    потому несет полную ответственность за возможный ущерб от пиратов или опасных космических объектов.
                    Если яхта несет на борту оружие, то переходит в класс граждансих канонерок, не теряя права на
                    свободное перемещение.</p>
                <h3 className="title-bg">Военные</h3>
                <p className="card-text text-justify"><span
                    className="strong">Брандер</span> – корабль-самоубийца. Чаще всего старое или поврежденное судно,
                    которое загружается взрывчаткой, а реактор переводится в режим перегрузки. Такую машину можно
                    отправить в гущу вражеских кораблей или столкнуть с планетой, но в первом случае потребуется хорошая
                    броня или очень маневренные двигатели.</p>
                <p className="card-text text-justify"><span
                    className="strong">Бриг</span> – легкая боевая машина, по конструкции несущая небольшое количество
                    вооружения.
                    Используется для конвоев и охраны торговых маршрутов в местах наименьшей опасности. В полноценных
                    сражениях, как правило, не участвует и боя с превосходящим противником не выдержит.</p>
                <p
                    className="card-text text-justify"><span className="strong">Десантный корабль</span> – небольшой,
                    зачастую безоружный транспорт, состоящий из кабины пилотов и десантного отсека. Нужен для доставки
                    людей или груза на планету или между другими судами.</p>
                <p className="card-text text-justify"><span
                    className="strong">Истребитель</span> – маленький маневренный корабль, предназначенный в первую
                    очередь для уничтожения легкой техники врага и поражения уязвимых частей более крупных машин.
                    Делятся на:</p>
                <p className="card-text text-justify">Фронтовые: завоевывают господство в космосе и
                    атмосфере, подавляя силы врага и обеспечивают прикрытие тяжелых кораблей или десанта.</p>
                <p
                    className="card-text text-justify">Многоцелевые: уничтожают как корабли противника, так и наземные
                    объекты. Могут оснащаться стелс-системами, чтобы изебежать обнаружения сенсорами дальнего действия
                    (на небольших расстояниях никакие стелс-системы не смогут обмануть военный сканер - они позволяют
                    лишь выиграть время для первого удара).</p>
                <p
                    className="card-text text-justify">Истребители-перехватчики: защищают космические или наземные
                    объекты от малых кораблей, ракет и сейсмического оружия (разогнанных астероидов).</p>
                <p
                    className="card-text text-justify"><span className="strong">Канонерка</span> – корабль, превышающий
                    размерами истребитель и предназначенный как для работы в составе флота, так и для самостоятельных
                    боевых действий против машин своего класса или наземных соединений.
                </p>
                <p
                    className="card-text text-justify">
                    Канонерки часто используют в качестве десантных кораблей.
                </p>
                <p
                    className="card-text text-justify">
                    Примеры: <TextInlineLink name={"bs_ships_fidget"}/>.
                </p>
                <p
                    className="card-text text-justify"><strong>Корвет (сторожевой корабль ближней зоны)</strong> –
                    относительно крупная машина,
                    предназначенная, в основном, для уничтожения целей своего или более низкого класса. Часто
                    используется для сторожевой и конвойной службы.
                </p>
                <p className="card-text text-justify"><span className="strong">Крейсер</span> – машина,
                    предназначенная в первую очередь для выполнения задач отдельно от флота. Способна самостоятельно
                    противостоять большому числу противников, относительно быстро маневрировать и шустро «уходить на
                    свет». Крейсера хорошо бронированы и несут на борту любое мыслимое вооружение: столкновение
                    корабля любого другого класса с крейсером считается самоубийством.
                </p>
                <p
                    className="card-text text-justify"><span
                    className="strong">Линкор (линейный корабль)</span> – неповоротливая, тяжело бронированная и
                    вооруженная под завязку военная машина. По уровню вооружения сопоставима с крейсером или
                    превышает его, но обладает меньшей маневренностью и скоростью.
                </p>
                <p
                    className="card-text text-justify">
                    Примеры: <TextInlineLink name={"bs_ships_nyorun_dreams"}/>, другие корабли типа <TextInlineLink
                    name={"bs_ships_grime"}/>.
                </p>
                <p
                    className="card-text text-justify"><span className="strong">Рейдер</span> – корабль
                    неопределенного класса, обычно пиратский. Сделан без оглядки на стандарты или шаблоны.
                    Назван от слова «рейд», как «налет», то есть звено рейдеров – это кучка разных по виду
                    кораблей с оружием и плохими намерениями.
                </p>
                <p className="card-text text-justify"><span
                    className="strong">Фрегат (сторожевой корабль дальней зоны)</span> – машина, предназначенная
                    для поддержки основного флота или для самостоятельных операций отдельно от него. Несет
                    приличное вооружение и, как правило, хорошо бронирована.
                </p>
                <p
                    className="card-text text-justify"><span
                    className="strong">Эсминец (эскадренный миноносец)</span> – тяжелый боевой корабль с
                    внушительным арсеналом на борту. Не крейсер, но тоже страшный противник: одновременного
                    залпа всех его батарей хватит, чтобы превратить Эльбрус в холмик.</p>
                <p
                    className="card-text text-justify"><span className="strong">Штурмовик</span> – небольшой
                    многофункциональный корабль, действующий преимущественно против наземных сил и сооружений
                    врага или в атмосфере. Вне атмосферы способен выполнять функции многоцелевого истребителя.
                    Может нести рельсовое, магнетронное, звуковое, противоракетное, ракетное и бомбовое оружие.
                </p>
            </>
    }
}
