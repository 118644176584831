import {Appearance} from "../../../../../components/appearance";
import {TextPopup, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hartrey_kellays() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"} text={"«Похитители маяков»"}/>,
        "name": "Келлес Хартри",
        "fullname": <span>Келлес Хартри</span>,
        "born": <span>68 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>67</span>,
        "homeworld": <span><TextPopup name={"bs_popups_planet_noabia"} text={"Ноэбия"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианец"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <span>нет в живых</span>,
        "relatives": <span>не общается</span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} text={"Эсквариат"}/> <TextInlineLink
            name={"bs_state_cydonian_priority"} text={"Приоритета"}/></span>,
        "imgs": [
            {"short": "bs_char_hartrey_kellays_1_png_500", "big": "bs_char_hartrey_kellays_1_jpg_full"},
            {"short": "bs_char_hartrey_kellays_2_jpg_200", "big": "bs_char_hartrey_kellays_2_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Юность и работа в КНК</h3>
                <p className="card-text text-justify">
                    <span className="strong">Келлес</span> - уроженец Ноэбии, небольшого аграрного мира в
                    секторе <TextInlineLink name={"bs_space_fleur_sector"} text={"Флёр"}/>.
                    Родители Келлеса были инженерами-гидропониками, а семья Хартри владела приличных размеров бизнесом
                    в родном мире. С самого детства он учился разбирать технику: роботов, колесные машины и разного
                    рода фермерские устройства. И несмотря на попытки родителей привить мальчку любовь к аграрному
                    хозяйству, после окончания школы он покинул планету и отправился на <TextInlineLink
                    name={"bs_planet_cassini"} text={"Кассини"}/>, изучать
                    работу <TextInlineLink name={"bs_tech_grn"} text={"ИнтерСети"}/>.
                </p>
                <p className="card-text text-justify">
                    Его специальностью была <span className='code'>«Информационная
                    безопасность ИнтерСетевых коммуникаций»</span> -
                    очень
                    востребованная профессия в <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритете"}/>.
                    Тем не менее, найти работу мечты после университета оказалось
                    не так просто. А мечтал Келлес о <TextInlineLink
                    name={"bs_state_cydonian_adimensional_communications"} text={"КНК"}/>: туда желают попасть все
                    любители информационных технологий в Приоритете и <TextInlineLink name={"bs_state_free_worlds"}
                                                                                      text={"Самборе"}/>, поэтому
                    огромный конкурс оставил мало
                    шансов
                    парню без опыта.
                </p>
                <p className="card-text text-justify">
                    Однако Келлес не стал отчаиваться и отправился работать в одну из фирм-подрядчиков КНК в
                    секторе <TextInlineLink name={"bs_space_grace_sector"} text={"Грация"} />.
                    Там ему приходилось орудовать сварочным аппаратом и следить за роботами-кабелеукладчиками, а вовсе
                    не мониторить безопасность ИТ-инфраструктуры,
                    как парень мечтал.
                </p>
                <p className="card-text text-justify">
                    Потребовалось несколько лет тяжелой работы, чтобы перейти в рязряд полноценных инженеров и попасть
                    в штат КНК. За эти годы Келлес нашел настоящего друга - Лумара, с которым в будущем проработал
                    плечом к плечу больше трех десятилетий. Они прошли путь от сварщиков до инженеров, а затем
                    администраторов
                    сегмента ИнтерСети - крайне ответственная и весьма хорошо оплачиваемая должность.
                </p>
                <h3 className="title-bg">Служба в Эсквариате (<TextInlineLink name={"book_hyperbeacons"}/>)</h3>
                <p className="card-text text-justify">
                    Примерно за год до Таманрасетт Келлес и Лумар стали свидетелями исчезновения жителей целой планеты
                    - <TextInlineLink name={"bs_planet_exile_1"} text={"Исхода-1"}/> в системе <TextInlineLink
                    name={"bs_star_last_exodus"} text={"Последнего Исхода"}/>. Хартри пришел в ярость от того, что все
                    проигнорировали безумие
                    ситуации, ограничившись лишь сухими отчетами военных. Кто мог похитить столько людей одновременно?!
                </p>
                <p className="card-text text-justify">
                    Желая найти ответ, Келлес отправился на <TextInlineLink name={"bs_planet_cydonia"}
                                                                            text={"Кидонию"}/>, прямиком в Эсквариат Её
                    Величества. Там его уже
                    ждала <TextInlineLink name={"bs_char_colbert_simone"}/>, которая высоко оценила навыки Хартри и
                    предложила ему работу на правительство
                    Приоритета. Предстояло решить задачу,
                    до сих пор непосильную для людей - понять, как
                    обезвредить
                    и украсть маяк гиперсвязи, который защищен лучше королевского дворца.
                </p>
                <p className="card-text text-justify">
                    Работая над этой задачей, Келлес стал свидетелем первого <TextInlineLink
                    name={"bs_timeline_tajbennae_incident"} text={"контакта"}/> между людьми и <TextInlineLink
                    name={"bs_spec_vitruvianus"} text={"витрувианами"}/>,
                    осуществленного <TextInlineLink name={"bs_char_skarsgard_oliver"}
                                                    text={"Оливером Скошгардом"}/> на <TextInlineLink
                    name={"bs_planet_tajbennae"} text={"Тайбенне"}/>.
                </p>
            </>
    }
}
