import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Salibar_kardenos() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>,
        "name": "Салибар Карденос",
        "fullname": <span>Салибар <small>«Арман»</small> Карденос</span>,
        "born": <span>73 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>72</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонец"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "relatives": <span><TextInlineLink name={"bs_char_xavier_kardenos"}/></span>,
        "work": <span>полковник <TextInlineLink name={"bs_comp_cms"} text={"СБК"}/></span>,
        "status": <span className={"text-danger"}>нет в живых</span>,
        "imgs": [
            {"short": "bs_char_salibar_kardenos_1_jpg_500", "big": "bs_char_salibar_kardenos_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Работа в СБК и <TextInlineLink name={"book_cydonian_girl"}
                                                                        text={"«Кидонианка»"}/></h3>
                <p className="card-text text-justify">
                    <span className="strong">Салибар</span> – младший брат Ксавьера Карденоса, всю жизнь мечтавший
                    идти по его стопам. Вслед за ним он пошел в СБК и попытался стать полевым агентом в Свободных
                    мирах, однако навыки и физические данные этому не способствовали. Поэтому Салибар провел много
                    лет в отделе аналитики и далеко не с первого раза смог сдать нужные экзамены. Когда ему наконец
                    удалось получить задачу «в поле» в Самборе, люди Лиги свободной торговли быстро раскрыли его и едва
                    не убили. СБК пришлось
                    снаряжать целый отряд спецназа, чтобы вытащить его с Новой Гаваны, а затем Ева Эсора
                    вывезла парня с планеты в потайном отделении трюма.
                </p>
                <p className="card-text text-justify">
                    После подобного фиаско карьера агента обычно заканчивается и он навсегда переезжает в архив, но
                    Ксавьер немного похлопотал за брата
                    и позволил ему участвовать в нескольких операциях с крайне высокими шансами на успех. Приписывая
                    себе больше заслуг, чем стоило бы, Салибар смог смыть позор после первого задания и даже получить
                    прозвище «Арман», что многие трактовали как «Ариман», один из богов смерти у древних аламарси.
                </p>
                <p className="card-text text-justify">
                    Подобное преувеличение своих навыков и заслуг сыграло с агентом злую шутку. Имея за спиной поддержку
                    старшего брата, он смог возглавить отряд, отправленный СБК в Монархию во время Михъельмского
                    восстания. И оно даже оказалось успешным (во многом благодаря Ивару де Карме).
                </p>
                <p className="card-text text-justify">
                    Однако чего «Арман» не знал, так это того, что должность в СБК и влиятельные друзья не спасают от
                    выстрела в упор. Когда он разблотал Ивару, что выяснил нечто важное об истинной природе Розали,
                    то моментально получил несколько попаданий в туловище из рельсы. Де Карма застрелил его на Земле,
                    в последний день сущетсвования Великой Монархии.
                </p>
            </>
    }
}