import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Megasthenes() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "people": <span>айлири</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Мегасфен</span> – одна из окраин
                    Доминации, сырьевой придаток империи, в котором можно найти лишь аграрные и промышленные миры.
                    Назван в честь полумифического древнеземного историка, чьи тексты были утеряны задолго до начала
                    световых перелетов.</p>
            </>
    }
}

