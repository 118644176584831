import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Morihei() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "capital": <span>-</span>,
        "people": <span>кидонианцы</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Морихей</span> – окраина
                    Приоритета, часть <TextInlineLink name={"bs_space_free_fire_zone"} text={"Зоны свободного огня"} />.
                    Здесь мало планет с постоянным населением, больше промышленных и горнодобывающих миров.</p>
            </>
    }
}

