import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Mereglis_sonya() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>,
        "name": "Соня Мергелис",
        "born": <span>140 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>139</span>,
        "fullname": <span>Соня Мергелис</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>осужденный военный преступник, <s>гросс-адмирал во флоте <TextInlineLink
            name={"bs_state_the_great_monarchy"} text={"Великой Монархии"}/></s></span>,
        "imgs": [
            {"short": "bs_char_mereglis_sonya_1_jpg_500", "big": "bs_char_mereglis_sonya_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">О героине</h3>
                <p className="card-text text-justify">
                    <span className="strong">Соня</span> - коренная землянка, рожденная в почетной семье боевых
                    офицеров.
                    Ее воспитывали как пламенную сторонницу и агрессивную защитницу режима Алканарра и воспитание дало
                    плоды.
                </p>
                <p className="card-text text-justify">
                    Соня с самого детства стремилась продолжить военную династию и после школы сразу отправилась в
                    военную академию на Месяце, земной луне. После академии - сразу на военный флот, в гущу сражений.
                    Можно сказать, что именно там произошло становление ее личности. Начальство и коллеги-офицеры
                    описывали Соню как волевого, решительного, непреклонного и мотивированного офицера, способного
                    выполнить любой, даже самый тяжелый приказ. При этом абсолютно лояльного земному руководству.
                </p>
                <p className="card-text text-justify">
                    Именно лояльность, а не выдающиеся офицерские навыки привели ее на вершину пищевой цепи Великой
                    Монархии: от младшего лейтенанта, отвечающего за материальное обеспечение на третьесротном
                    фрегате, до гросс-адмирала <TextPopup name={"bs_popups_army_echelon_of_the_fives"}
                                                          text={"Эшелона Пятых"}/>, отвечающего за
                    подавление <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"мятежа"}/> в
                    целой стране.
                </p>
                <p className="card-text text-justify">
                    В <TextInlineLink name={"bs_timeline_galactic_war"} text={"Первую галактическую"}/> прославилась
                    тем, что совершила множество вылазок в пространство <TextInlineLink name={"bs_people_agathonians"}
                                                                                        text={"агатонцев"}/>
                    и нарушила немало логистических цепей военного флота <TextInlineLink name={"bs_state_urtm"}
                                                                                         text={"Объединенных республик"}/>.
                    В ходе этих операций
                    неоднократно нарушала нормы и обычаи ведения войны, но так и не понесла наказания после ее
                    окончания.
                    Самым страшным ее поступком стало превращение <TextInlineLink
                    name={"bs_planet_esghariot_en_nuvnish"} text={"Исгариот-ин-Нувниша"}/> в Мертвый Исгариот. Именно за
                    это <TextInlineLink name={"bs_char_lenisaad_victor"}/> и многие <TextInlineLink
                    name={"bs_people_breezeans"} text={"бризеанцы"}/> возненавидели землян, а вовсе не за развязанную
                    ими войну
                    против <TextInlineLink name={"bs_people_agathonians"} text={"агатонцев"}/>, которых
                    жители <TextInlineLink name={"bs_space_breeze_sector"}
                                           text={"Бриза"}/> тоже не очень любят.
                </p>
                <p className="card-text text-justify">
                    Конец карьеры Сони случился неожиданно, за год до Таманрасетт. Во время Михъельмского восстания,
                    которое она была намерена жестоко подавить, <TextInlineLink name={"bs_char_de_karma_ivar"}/> сумел
                    вывести из строя большую часть ее
                    флота с помощью хакерской атаки. Затем, без особого сопротивления, вторгся в систему <TextInlineLink
                    name={"bs_planet_the_night"} text={"Ньёрун"}/> и
                    взял Соню в плен. После этого она участвовала в заседаниях смещенного земного правительства, но
                    исключительно в качестве советника сброшенного с трона <TextInlineLink
                    name={"bs_char_alkanarra_siel"} text={"Сиеля Алканарра"}/>. Однако близость к бывшему
                    правителю не спасла девушку: через несколько месяцев, когда нестабильность в <TextInlineLink
                    name={"bs_space_the_old_space"} text={"Старом космосе"}/>
                    сошла на нет, <TextInlineLink name={"bs_planet_michelm"} text={"михъельмцы"}/> отправили ее под
                    трибунал.
                </p>
                <p className="card-text text-justify">
                    Соню судили не только за преступления во время восстания, но за поступки, совершенные в
                    Галактическую. Список обвинений был слишком длинным и выходил за рамки юрисдикции михъельмцев.
                    Поэтому
                    после приговора к пожизненному заключению на Земле, девушку экстрадировали на <TextInlineLink
                    name={"bs_planet_agathon"}/>, где к
                    обвинениям
                    добавились "вопросы" от пострадавших жителей <TextInlineLink name={"bs_space_democratic_space"}
                                                                                 text={"Демкосмоса"}/>. Там Соня была
                    приговорена еще к шести
                    пожизненным и отправлена отбывать срок в каторжной колонии.
                </p>
            </>
    }

}