import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Verner_rita() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>,
        "name": "Рита Вернер",
        "fullname": <span>Рита <small>«Ядовитая»</small> Вернер</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_helena"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>телохранитель <TextInlineLink name={"bs_char_lenisaad_victor"}
                                                    text={"Ленисаада"}/>, <strike><TextInlineLink
            name={"bs_comp_bonecollectors"}/></strike></span>,
        "imgs": [{"short": "bs_char_werner_rita_1_jpg_500", "big": "bs_char_werner_rita_1_jpg_full"},],
        "pint": "",
        "appears": [<div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_cydonian_girl"}/>
        </div>, <div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_bonecollectors"}/>
        </div>,],
        "text": <>
            <h3 className="title-bg">О героине</h3>
            <p className="card-text text-justify">
                <span className="strong">Рита</span> - кидонианка с Хелены. Ее предыстория не будет раскрыта раньше
                выхода <TextInlineLink name={"book_bonecollectors"} text={"«Собирателей костей»"}/>, а может и
                дольше. Судьба у персонажа непростая: она задумывалась как одна из главных героев <TextInlineLink
                name={"book_cydonian_girl"} text={"«Кидонианки»"}/>, но из-за перегруженности сюжета и диалогов
                ее было решено вырезать. Вместе с ней и парня по имени Аарон «Шип» Торн, который был второстепенным
                героем.
            </p>
            <p className="card-text text-justify">
                Вместо полноценной предыстории и сюжетной линии Рита получила лишь небольшое появление в
                «Кидонианке»: несколько реплик в сцене с <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Ленисаадом"}/> на <TextInlineLink name={"bs_planet_kyonmar"} text={"Кёнмаре"}/>.
            </p>
            <p className="card-text text-justify">
                Иронично, что многие агрессивные реплики <
                TextInlineLink name={"bs_char_esora_eve"} text={"Эсора"}/> получила
                 именно от Риты, как наследство вырезанного персонажа.
            </p>
            <p className="card-text text-justify">
                Рита скорее всего появится в <TextInlineLink name={"book_bonecollectors"}
                                                             text={"«Собирателях костей»"}/> и <TextInlineLink
                name={"book_the_free_traders"} text={"«Вольных торговцах»"}/>, но это пока только примерный план.
                Реальность может оказаться суровой для героини...
            </p>
        </>
    }
}
