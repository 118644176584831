export function Shield_sound() {
    return {
        "science": <span>физика</span>,
        "use": <span>бытовой комфорт</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Звуковой щит</span> – штука, как
                    атмосферный щит, но она не пропускает колебания среды. То есть, по одну строну можно взорвать
                    гранату, а на другой стороне никто ничего не услышит.</p>
            </>
    }
}
