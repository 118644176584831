export function Paralyzer() {
    return {
        "type": <span>энергетическое оружие</span>,
        "use": <span>люди и существа с похожей нервной системой</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Парализатор</span> выпускает
                    электрический разряд, который перегружает нервную систему и отключает сознание на какое-то
                    время. Скорее его стоило назвать «вырубатель», но это не очень профессионально.</p>
            </>
    }
}
