import cats from "../../../../../components/cms/wiki_cats.json";
import wiki from "../../../../../components/cms/wiki.json";
import {GetConnections} from "../../../../../components/cms/wiki_functions";
import {CrumbsBlackSunSpace} from "../../../../../components/Bread_Crumbs";
import {GetQuotes} from "../../../../../components/Quotes";

import {Ackron} from "./ackron";
import {Agathon} from "./agathon";
import {Bassian} from "./bassian";
import {Alkiviad} from "./alkiviad";
import {Ajliraen} from "./ajliraen";
import {Arville} from "./arville";
import {Avvaline} from "./avvaline";
import {Cartagena} from "./cartagena";
import {Cydonia} from "./cydonia";
import {Cassini} from "./cassini";
import {Daydaris} from "./daydaris";
import {Earth} from "./earth";
import {Vustain} from "./vustain";
import {EsghariotEnNuvnish} from "./esghariot-en-nuvnish";
import {Exile_1} from "./exile_1";
import {Hela} from "./hela";
import {Helena} from "./helena";
import {Khal_vernon} from "./khal_vernon";
import {Kyonmar} from "./kyonmar";
import {Machtobyrat} from "./machtobyrat";
import {Macktobykhar} from "./macktobykhar";
import {Malatia} from "./malatia";
import {Michelm} from "./michelm";
import {Naskarae} from "./naskarae";
import {New_Havana} from "./new_havana";
import {Samborae} from "./samborae";
import {Scellurae} from "./scellurae";
import {Sleeping_suns_maison} from "./sleeping_suns_maison";
import {Surghanne} from "./surghanne";
import {Tajbennae} from "./tajbennae";
import {Talla} from "./talla";
import {The_night} from "./the_night";
import {Tiora} from "./tiora";
import {Tribalon} from "./tribalon";
import {Valoria} from "./valoria";
import {Vitvatersand} from "./vitvatersand";
import {Tamanrasett} from "./tamanrasett";
import {Telenmark} from "./telenmark";
import {GetFancyBoxImg100, GetFancyBoxImgStarsBackground500} from "../../../../../components/cms/wiki_img_functions";
import {BriglioniLeushoffer47} from "./briglioni-leushoffer-4-7";
import {Ellania} from "./ellania";
import {Karuon} from "./karuon";
import {Graces} from "./graces";
import {HasSpoilers} from "../../../../../components/spoilers";

let list = {
    "ackron": Ackron,
    "agathon": Agathon,
    "ellania": Ellania,
    "alkiviad-4": Alkiviad,
    "bassian": Bassian,
    "eiliren": Ajliraen,
    "arville": Arville,
    "avvaline": Avvaline,
    "cartagena": Cartagena,
    "cassini": Cassini,
    "cydonia": Cydonia,
    "daydaris": Daydaris,
    "earth": Earth,
    "vustain": Vustain,
    "esghariot-en-nuvnish": EsghariotEnNuvnish,
    "rogue-1": Exile_1,
    "hela": Hela,
    "helena-at-tellause": Helena,
    "khal-vernon": Khal_vernon,
    "kyonmar": Kyonmar,
    "machtobyrat": Machtobyrat,
    "macktobykhar": Macktobykhar,
    "malatia": Malatia,
    "michelm": Michelm,
    "naskarae": Naskarae,
    "new-havana": New_Havana,
    "samborae": Samborae,
    "scellurae": Scellurae,
    "sleeping-suns-manor": Sleeping_suns_maison,
    "surghanne": Surghanne,
    "tajbennae": Tajbennae,
    "talla": Talla,
    "coal-njorun": The_night,
    "tiora": Tiora,
    "tribalon-at-alamein": Tribalon,
    "valoria": Valoria,
    "vitvatersand": Vitvatersand,
    "tamanrasett": Tamanrasett,
    "wustain": Vustain,
    "telenmark": Telenmark,
    "esghariot-en-nouvnish": EsghariotEnNuvnish,
    "briglioni-leushoffer-4-7": BriglioniLeushoffer47,
    "karuon": Karuon,
    "graces": Graces,
}

/*
return {
        "date": <span></span>,
        "owned": <span></span>,
        "governor": <span></span>,
        "habitats": <span></span>,
        "people": <span></span>,
        "moons": <span></span>,
        "mother": <span></span>,
        "g": <span></span>,
        "atmosphere": <span></span>,
        "biosphere": <span></span>,
        "system": <span></span>,
        "sector": <span></span>,
        "type": <span></span>,
        "region": <span></span>,
        "area": <span></span>,
        "imgs": [{"pict":"","link":"kb/maps/oecumene.jpg"}],
        "appears":
            <>
            </>,
        "text":
            <>
            </>
    }
 */


export function Planets(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_planets"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Кидонианка и Черное солнце"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunSpace name={name}/>

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>{name}</h2></div>
                    <div className="row">
                        <div className="col-lg-4 text-center" data-aos="fade-right">
                            <GetFancyBoxImgStarsBackground500 short={data["imgs"][0].short} big={data["imgs"][0].big}/>
                            {data["imgs"][1] ?
                                <GetFancyBoxImg100 short={data["imgs"][1].short}
                                                   big={data["imgs"][1].big}/>: <></>}
                            {data["imgs"][2] ?
                                <GetFancyBoxImg100 short={data["imgs"][2].short}
                                                   big={data["imgs"][2].big}/>: <></>}
                            {data["imgs"][3] ?
                                <GetFancyBoxImg100 short={data["imgs"][3].short}
                                                   big={data["imgs"][3].big}/>: <></>}
                        </div>
                        <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        {data["date"] ? <li><i className="fas fa-chevron-right"></i> <strong>Год
                                            заселения: </strong> {data["date"]}</li> : <></>}
                                        {data["type"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Тип:</strong>{data["type"]}</li> : <></>}
                                        {data["moons"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Луны:</strong>{data["moons"]}</li> : <></>}
                                        {data["mother"] ? <li><i className="fas fa-chevron-right"></i> <strong>Материнский
                                            мир:</strong>{data["mother"]}</li> : <></>}
                                        {data["g"] ? <li><i className="fas fa-chevron-right"></i> <strong>Сила
                                            тяжести:</strong>{data["g"]}</li> : <></>}
                                        {data["atmosphere"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Атмосфера:</strong>{data["atmosphere"]}</li> : <></>}
                                        {data["biosphere"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Биосфера:</strong>{data["biosphere"]}</li> : <></>}
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        {data["region"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Регион:</strong>{data["region"]}</li> : <></>}
                                        {data["sector"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Сектор:</strong>{data["sector"]}</li> : <></>}
                                        {data["area"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Область:</strong>{data["area"]}</li> : <></>}
                                        {data["system"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Система:</strong>{data["system"]}</li> : <></>}
                                        {data["sector"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Принадлежность: </strong>{data["owned"]}</li> : <></>}
                                        {data["governor"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Губернатор:</strong>{data["governor"]}</li> : <></>}
                                        {data["people"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Народ:</strong>{data["people"]}</li> : <></>}
                                        {data["habitats"] ? <li><i className="fas fa-chevron-right"></i>
                                            <strong>Население:</strong>{data["habitats"]}</li> : <></>}
                                    </ul>
                                </div>
                            </div>
                            {conns.length > 0 ? <p><strong>Связанные статьи:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
                {data["appears"] ?
                    <div className="appears container">
                        <div className="section-title"><h3>Появления <small>({data["appears"].length})</small></h3></div>
                        <div className="row">
                            {data["appears"]}
                        </div>
                    </div>
                    : <></>
                }
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}