import React, {Component} from 'react'
import {TextInlineLink} from "../../../../components/cms/wiki_functions";
import {
    SidebarBSGallery,
    SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSWiki
} from "../../../../components/Sidebar"
import {CrumbsBlackSunBook} from "../../../../components/Bread_Crumbs";

function Unenclosed() {
    return (
        <>
            <PageClass/>
        </>
    );
}

class PageClass extends Component {

    state = {
        quote: {},
    }

    componentDidMount() {
        document.title = "Невошедшее || Кидонианка и Черное солнце"
    }

    render() {
        return (
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBlackSunBook name={<span><i className="fa-solid fa-triangle-exclamation"></i>  Невошедшее</span>}/>
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <div className="row"><h2 className="title-bg">Невошедшее</h2></div>
                        <div className='row'>
                            <div className="alert alert-danger">
                                <p className="card-text text-justify">Осторожно: спойлеры!</p>
                                <p className="card-text text-justify">В этих статьях могут быть материалы, раскрывающие
                                    сюжет серии, так что изучать их стоит осторожно.</p>
                                <p className="card-text text-justify">Раздел будет пополняться по мере выхода новых
                                    романов.</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="well">
                                <p className="card-text text-justify"><span className={"strong"}>Что уже готово?</span>
                                </p>
                                <p className="card-text text-justify"><TextInlineLink
                                    name={"bs_unenclosed_cydonian_girl_covers"} text={"Кидонианка: прототипы обложки"}/>
                                </p>
                                <p className="card-text text-justify"><TextInlineLink
                                    name={"bs_unenclosed_hyperbeacons_editor_comments"}
                                    text={"Похитители маяков: комментарии редактора"}/></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <SidebarBSWiki/>
                            <hr/>
                            <SidebarBSMap/>
                            <hr/>
                            <SidebarBSTimeline/>
                            <hr/>
                            <SidebarBSGallery/>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export {Unenclosed};
