import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Engine_overlight() {
    return {
        "science": <span>квантовая механика, <TextInlineLink name={"bs_science_astromechanics"} text={"астромеханика"}/></span>,
        "use": <span>космические полеты</span>,
        "text": <>
            <p className="card-text text-justify"><span
                className="strong">Сверхсветовой двигатель или «алькубьерка»</span> –
                он же генератор Алькубьерре, он же двигатель Эйнштейна. По большей части представляет собой
                термоядерный реактор, который синтезирует в своих недрах экзотическую материю. Она помогает
                искривлять пространство таким образом, чтобы сматывать его перед кораблем и разматывать сзади,
                тем самым перемещая машину по вселенной быстрее скорости света, пока сам корабль технически как
                бы стоит на месте.</p>
        </>
    }
}
