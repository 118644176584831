export function Privateer() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Капер</span> – гражданин, не
                    состоящий на службе в армии или полиции, но имеющий право находить и отстреливать преступников
                    на территории государства. С юридической точки зрения здесь все сложно, и просто так палить в
                    людей не получится, но, если на человека выписан ордер, капер имеет право задержать его или
                    отправить на тот свет при сопротивлении. Можно сказать, капер – это частный охотник за головами:
                    в условиях, когда в стране тысяча незаселенных миров, без таких людей не обойтись.</p>
            </>
    }
}
