import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ackron_conspiracy() {
    document.title = "Акронский заговор || Кидонианка и Черное солнце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Акронский заговор"}/>

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Акронский заговор</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_ackron_conspiracy"}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"/> <strong>Дата: </strong>
                                            <span>1823 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"/> <strong>Место:</strong>
                                            <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"/> <strong>Участники:</strong>
                                    <span>династия Яветов</span>
                                </li>
                            </ul>
                            <p><strong>Связанные
                                статьи:</strong> <>{GetConnections("bs_timeline_ackron_conspiracy").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">История</h3>
                    <p className='card-text text-justify'><span className='strong'>Акронский заговор</span> – дворцовый
                        переворот в <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                    text={"Айлирэнской Доминации"}/>, который привел к власти семью
                        Яветов. Именно они сделали Доминацию такой,
                        какая она предстает в <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианке»"}/> и
                        продолжениях:
                        деспотичной империей с разделенным на классы обществом, тоталитарной военной машиной и полным
                        неуважением к личности.</p>
                </div>
            </div>
        </>
    )
}
