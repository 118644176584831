import {GetQuotes} from "../../../components/Quotes";
import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {ImgRound3EM, ImgRound3EMFancyBoxArticle} from "../../../components/imgs/imgRound";
import {GetConnections, TextInlineLink} from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";
import {WorkInProgress} from "../../../components/workInProgress";

export function Book_3_Cydonian_autumn() {
    let repo = "book_cydonian_autumn"
    let name = wiki[repo].name
    document.title = "Сергей Крехно - " + name
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"/> Кидонианская осень</span>}/>
            </div>
            <div className="row">
                <ul className="characters-bar">
                </ul>
            </div>
            <div className="row">
                <WorkInProgress />
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_cydonian_autumn_jpg_ru"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt={imgs["book_cydonian_autumn_jpg_ru"].long_text}
                             src={imgs["book_cydonian_autumn_jpg_ru"].link}/>
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Кидонианская осень</h1></div>
                    <div className="row row-alt">
                        <GetQuotes name={repo}/>
                    </div>
                    <div className="row">
                        <div className="alert alert-danger text-justify" role="alert">
                            Книга готовится к выходу
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="47" aria-valuemin="0"
                                     aria-valuemax="100" style={{"width": "47%"}}>Прогресс... ну, он есть
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_browdy_adam_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_jasmine_haervany_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"}/></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"/> <h6>Статус:</h6> <strong><span
                                className="text-info">в работе</span></strong></li>
                            <li><i className="far fa-calendar-alt"/> <h6>Дата выхода:</h6> ~2024 (или 2025)</li>
                            <li><i className="far fa-list-alt"/> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Черное солнце #3"}/></li>
                            <li><i className="fas fa-angle-double-left"/> <h6>Предыдущая:</h6> <TextInlineLink
                                name={"book_hyperbeacons"} text={"Похитители маяков"}/></li>
                            <li><i className="fas fa-angle-double-right"/> <h6>Следующая:</h6> <TextInlineLink
                                name={"book_bonecollectors"} text={"Собиратели костей"}/></li>
                            <li><i className="fa-solid fa-clock-rotate-left"/> <h6>Место в <TextInlineLink
                                name={"bs_timeline"} text={"истории"}/>:</h6> ~11.5 месяцев до <TextInlineLink
                                name={"book_tamanrasett_contact"} text={"Таманрасетт"}/></li>
                            <li><i className="fas fa-user-edit"/> <h6>Редактор:</h6> Валерия Likos</li>
                            <li><i className="fas fa-palette"/> <h6>Художник:</h6> [ДАННЫЕ УДАЛЕНЫ] & Atarina</li>
                            <li><h6>Связанные страницы:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Аннотация</h3>
                    <div className=" well">
                        <p className="text-justify">
                            <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Розали"}/> отбывает
                            на <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонию"}/>, помогать
                            команде <TextInlineLink name={"bs_char_de_karma_ivar"} text={"де Кармы"}/> выслеживать
                            неизвестных существ, способных в одночасье похитить военный флот и даже население целой
                            планеты.
                        </p>
                        <p className='text-justify'>
                            Сам Ивар все глубже погружается в историю <TextInlineLink name={"bs_char_the_whip"}
                                                                                      text={"Бича"}/> и намерен
                            заручиться его поддержкой в предстоящем конфликте с пришельцами. Кидонианец пока не знает,
                            что дочь Последнего из перворожденных притягивает к себе врагов куда более страшных, чем
                            четырехрукие гуманоиды.
                        </p>
                        <p className='text-justify'>
                            Роман стоит читать только после <TextInlineLink name={"book_hyperbeacons"}
                                                                            text={"«Похитителей маяков»"}/>.
                        </p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_1_jpg_full"}
                                                        small={"bs_char_de_levine_rosalie_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_browdy_adam_1_jpg_full"}
                                                        small={"bs_char_browdy_adam_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_karma_ivar_1_jpg_full"}
                                                        small={"bs_char_de_karma_ivar_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_1_jpg_full"}
                                                        small={"bs_char_esora_eve_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_2_jpg_full"}
                                                        small={"bs_char_esora_eve_2_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_3_jpg_full"}
                                                        small={"bs_char_esora_eve_3_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_4_jpg_full"}
                                                        small={"bs_char_esora_eve_4_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_can_beucan_gaereas_1_jpg_full"}
                                                        small={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_colbert_simone_1_jpg_full"}
                                                        small={"bs_char_colbert_simone_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_van_gloria_adel_1_jpg_full"}
                                                        small={"bs_char_van_gloria_adel_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_oliver_1_jpg_full"}
                                                        small={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_vivien_1_jpg_full"}
                                                        small={"bs_char_skarsgard_vivien_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_hartrey_kellays_1_jpg_full"}
                                                        small={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"}
                                                        small={"bs_char_the_whip_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_jasmine_haervany_1_jpg_full"}
                                                        small={"bs_char_jasmine_haervany_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_fa1_jpg_full"}
                                                        small={"bs_char_de_levine_rosalie_fa1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_fa2_jpg_full"}
                                                        small={"bs_char_de_levine_rosalie_fa2_jpg_200"}/></li>
                    </ul>
                </div>
                <div className="row row-alt title-bg"><h3>Занимательные факты</h3></div>
                <div className="row row-alt well">
                    <ul className="book-info">
                        <li className="card-text text-justify"><h6>Многие сцены на Кидонии были вырезаны</h6><br/>Поначалу
                            события книги охватывали целые месяцы жизни Розали на Кидонии и ее знакомство с культурой и
                            бытом прародины. Но в какой-то момент стало ясно, что на фоне этих относительно размеренных
                            сцен основной сюжет, связанный с попытками подготовиться к войне с загадочными пришельцами,
                            полностью потерялся. Чтобы избежать нудности и затянутости, добрую часть текста пришлось
                            удалить, а оставшуюся переделать, ускорив повествование.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
