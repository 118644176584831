import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Cms() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>силовая структура</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Штаб-квартира: </strong> <TextInlineLink
                        name={"bs_planet_agathon"} text={"Агатон"}/>

                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Члены: </strong>
                        <span>
                                                <ul className="characters-bar">
                                                    <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"}/></li>
                                                </ul>
                                            </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                        <span>разведка, контрразведка, диверсии, устранение врагов <TextInlineLink
                            name={"bs_state_republic_of_tiara_minor"} text={"Республики Малой короны"}/></span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Руководитель:</strong>
                        <span>генерал <TextInlineLink name={"bs_char_xavier_kardenos"}
                                                      text={"Ксавьер Карденос"}/></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">СБК</span> – Служба безопасности
                    <TextInlineLink name={"bs_state_republic_of_tiara_minor"} text={"(Республики Малой) Короны"}/>.
                    Самая
                    могущественная организация в галактике, способная устраивать войны и свергать правительства.
                    Колоссальные средства, ежегодно вкладываемые агатонским правительством в слежку за людьми по
                    всей галактике, сбор полученных данных и анализ поведения, позволяют СБК проворачивать безумно
                    сложные операции с помощью всего нескольких оперативников.</p><p
                className="card-text text-justify">Например, известно, что
                <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Восстание Акулы"}/> и последующее свержение
                Монарха не обошлось без помощи СБК: численно и технологически превосходящие земляне потерпели
                поражение от кучки михъельмцев, у которых изначально не было союзников даже внутри страны. Но
                каким-то образом они смогли не только привлечь на свою сторону тысячи чиновников и офицеров по всей
                Монархии, но еще и годами готовить восстание в полной тайне.</p><p> «Короны» в конце пишется с
                большой буквы, хотя в названии государства – с маленькой.</p>
            </>
    }
}










