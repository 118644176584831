import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Anubis_gate_alpha() {
    return {
        "date": <span>1200 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>22</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Бриэнн"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "people": <span>кидонианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>288 млрд.</span>,
        "imgs": [{"short": "bs_wiki_anubis_gate_alpha_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Альфа</span> была обнаружена и
                    освоена больше двух тысяч лет назад, но так и не обзавелась настоящим именем. Она - самая яркая
                    звезда в созвездии Врат Анубиса, различимом только из южных полушарий <TextInlineLink
                        name={"bs_planet_cydonia"} text={"Кидонии"}/> и <TextInlineLink name={"bs_planet_hela"}
                                                                                        text={"Хелы"}/>.</p><p
                className='card-text text-justify'>Звезда находится на окраине страны и пространство вокруг нее
                почти не заселено, за исключением Аввалина, на котором живет 99% населения системы.</p>
            </>
    }
}

