export function Gemini() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Джемини (агатонск. Gemini)</span> –
                    «близняшки». Такой приставкой к названию награждают многие системы с двойными звездами.</p>
            </>
    }
}
