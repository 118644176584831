import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Free_fire_zone() {
    return {
        "date": <span>-</span>,
        "type": <span>территория</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "sector": <span>Морихей</span>,
        "people": <span>кидонианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "imgs": [{"short":"bs_wiki_free_fire_zone_map_ru","big":"bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Зона свободного огня</span> – часть
                    окраинных территорий Приоритета, на границе между кидонианской, айлирэнской и самборианской
                    территориями. Здесь действует пропускной режим: любой корабль без сигнатур расценивается военным
                    флотом как пиратский и расстреливается после первого предупреждения.</p>
            </>
    }
}

