import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function The_shark_revolt() {
    document.title = "Восстание Акулы || Кидонианка и Черное солнце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Восстание Акулы"}/>

            <div className="alert alert-danger">
                <p className="card-text text-justify">
                    <strong>Ниже могут быть
                        спойлеры</strong> к книгам: <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>
                </p>
            </div>
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Восстание Акулы</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_the_shark_revolt"}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"/>
                                            <strong>Даты: </strong> <span>~14-11.5 месяцев <TextInlineLink
                                            name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"/> <strong>Место:</strong>
                                            <span><TextInlineLink name={"bs_state_the_great_monarchy"}/></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"/> <strong>Участники:</strong>
                                    <span><TextPopup name={"bs_popups_state_shark_republic"}
                                                     text={"Михъельмская Республика Акулы"}/>, <TextInlineLink
                                        name={"bs_state_republic_of_tiara_minor"}/>, <TextInlineLink
                                        name={"bs_state_the_great_monarchy"}/>, <TextInlineLink
                                        name={"bs_char_alkanarra_siel"}/>, <TextInlineLink
                                        name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                                        name={"bs_char_de_levine_rosalie"}/>, <TextInlineLink
                                        name={"bs_char_esora_eve"}/>, <TextInlineLink
                                        name={"bs_char_browdy_adam"}/>, <TextInlineLink
                                        name={"bs_char_can_beucan_gaereas"}/>, <TextInlineLink
                                        name={"bs_char_lenisaad_victor"}/>, <TextInlineLink
                                        name={"bs_char_callisto_kindrace"}/>, <TextInlineLink
                                        name={"bs_char_otton_bjorne"}/>, <TextInlineLink
                                        name={"bs_char_kaory_nazil"}/>, <TextInlineLink
                                        name={"bs_char_montgomery_lorena"}/>, <TextInlineLink
                                        name={"bs_char_tom_torvalds"}/>, <TextInlineLink
                                        name={"bs_char_salibar_kardenos"}/>, <TextInlineLink
                                        name={"bs_char_mereglis_sonya"}/></span>
                                </li>
                            </ul>
                            <p><strong>Связанные
                                статьи:</strong> <>{GetConnections("bs_timeline_the_shark_revolt").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">История</h3>
                    <p className='card-text text-justify'><span className='strong'>Восстание Акулы</span> или <span
                        className='strong'>Второй демарш Пауков</span> – насильственное свержение <TextInlineLink
                        name={"bs_char_alkanarra_siel"} text={"Сиеля Алканарра"}/>,
                        последнего Монарха одноименной династии. Оно началось с вооруженного восстания
                        на <TextInlineLink name={"bs_planet_michelm"} text={"Михъельме"}/>, организованного губернатором
                        сектора <TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"}/> <TextInlineLink
                            name={"bs_char_tom_torvalds"} text={"Томасом Торвальдсом"}/> и
                        командующими михъельмского флота (<TextInlineLink
                            name={"bs_char_otton_bjorne"} text={"Оттоном Бьерне"}/> и <TextInlineLink
                            name={"bs_char_montgomery_lorena"} text={"Лореной Монтгомери"}/>). И продолжилось благодаря
                        тайной поддержке <TextInlineLink name={"bs_comp_cms"} text={"агатонских силовиков"}/> и
                        бездействию <TextInlineLink
                            name={"bs_char_van_gloria_adel"} text={"кидонианских"}/> (которые изначально обещали помочь
                        землянам подавить восстание).</p>
                    <p
                        className='card-text text-justify'>Основной боевой силой, выступившей на стороне михъельмцев,
                        был
                        Паучий Эшелон под командованием Бьерне. Кроме этого, Торвальдсу удалось заручиться помощью
                        множества
                        высокопоставленных офицеров в других секторах <TextInlineLink
                            name={"bs_state_the_great_monarchy"} text={"Монархии"}/>: военный потенциал мятежников
                        усилили многочисленные группы сочувствующих. В то же время мощь землян стремительно падала из-за
                        неумелого управления и утраты боевого духа рядовыми солдатами (большинство бойцов были родом из
                        регионов, похожих на Михъельм, и со временем все сильнее симпатизировали восстанию).</p>
                </div>
            </div>

            <section className="resume">
                <div className="container">
                    <h3 className="title-bg">Хронология</h3>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="timeline-item"><h4>1. Подготовка</h4>
                                <p className={"text-justify"}><em><TextInlineLink name={"bs_planet_michelm"}/></em></p>
                                <p className={"text-justify"}>
                                    Торвальдс узнает об ордере на свой арест и нанимает Ивара де Карму для предстоящего
                                    побега с Земли
                                </p>
                            </div>
                            <div className="timeline-item"><h4>2. Начало восстания</h4>
                                <p className={"text-justify"}><em><TextInlineLink
                                    name={"bs_planet_michelm"}/>, <TextInlineLink name={"bs_planet_earth"}/></em></p>
                                <p className={"text-justify"}>
                                    Арест Томаса Торвальдса на <TextInlineLink name={"bs_planet_earth"} text={"Земле"}/>
                                </p>
                                <p className={"text-justify"}>
                                    Побег из тюрьмы, приведший ко множеству невинных жертв и большим разрушениям
                                    в столице (<TextInlineLink name={"bs_comp_galactic_security"}
                                                               text={"Галактическая безопасность"}/> уверена, что к
                                    силовому способу побега причастны <TextInlineLink name={"bs_char_lenisaad_victor"}
                                                                                      text={"Виктор Ленисаад"}/>, <TextInlineLink
                                    name={"bs_char_de_karma_ivar"} text={"Ивар де Карма"}/> и несколько
                                    сообщников)
                                </p>
                                <p className={"text-justify"}>
                                    Гросс-адмирал <TextInlineLink
                                    name={"bs_char_mereglis_sonya"}/> санкционирует массовые аресты офицеров и
                                    чиновников по всей
                                    стране, а также незаконные казни, прозванные «Охотой на акулят»
                                </p>
                                <p className={"text-justify"}>
                                    Мятежники сектора Акулы собираются на Михъельме для встречи с Торвальдсом
                                </p>
                                <p className={"text-justify"}>
                                    Совместное заявление Торвальдса и Оттона Бьерне о выходе сектора Акулы из состава
                                    Монархии
                                </p>
                                <p className={"text-justify"}>
                                    Множество военных флотов в других секторах поддерживают Михъельм, но восстание
                                    лишилось своих лидеров
                                    благодаря мерам Мергелис и не может выступить единой силой. Вместо бескровной смены
                                    власти
                                    под давлением народа, Монархия быстро погружается в хаос гражданской войны:
                                    начинаются конфликты между армиями отдельных планет, подавшимися в пиратство
                                    капитанами,
                                    преступными группировками и пытающимися сохранить хоть какой-то контроль
                                    мятежниками.
                                    В хаос вмешиваются правительственные войска Эшелона Пятых под командованием Мергелис
                                    и один за одним подавляют очаги восстания. За считанные дни армия земных лоялистов
                                    взяла под контроль сектор Ночи и большую часть сектора Рассвета. Понимая, что
                                    михъельмцы сохранили управление почти во всем секторе Акулы, Пятые начинают
                                    подготовку
                                    к маршу на Михъельм, не дожидаясь разгрома более мелких группировок.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="timeline-item"><h4>3. Конфликт за антиматерию</h4>
                                <p className={"text-justify"}><em><TextInlineLink
                                    name={"bs_planet_vitvatersand"}/>, <TextInlineLink
                                    name={"bs_planet_naskarae"}/></em></p>
                                <p className={"text-justify"}>
                                    Михъельмцы похищают антиматериальный арсенал на <TextInlineLink
                                    name={"bs_planet_vitvatersand"} text={"Витватерсанде"}/>
                                </p>
                                <p className={"text-justify"}>
                                    Мятежники из Рассветного Эшелона пытаются провести похожую операцию
                                    на <TextInlineLink name={"bs_planet_naskarae"} text={"Наскаре"}/>, но трагическая
                                    случайность
                                    приводит к полному разрушению планеты
                                </p>
                            </div>
                            <div className="timeline-item"><h4>4. Неожиданный разгром землян</h4>
                                <p className={"text-justify"}><em><TextInlineLink
                                    name={"bs_planet_scellurae"}/>, <TextInlineLink name={"bs_planet_the_night"}/></em>
                                </p>
                                <p className={"text-justify"}>
                                    Мергелис собирает флоты у <TextInlineLink name={"bs_planet_scellurae"}
                                                                              text={"Сцеллуры"}/> для атаки
                                    на <TextInlineLink name={"bs_planet_michelm"} text={"Михъельм"}/>, общая численность
                                    лояльных
                                    Земле войск уже в несколько раз превосходит все, что могут выставить михъельмцы.
                                    Но мятежники, не дожидаясь атаки, сами переходят в наступление, стремясь
                                    нанести максимальный урон до того, как в систему подтянутся остальные корабли
                                    Мергелис.
                                    Михъельмцы проводят тактически
                                    сложную операцию на поверхности Сцеллуры и с помощью уязвимого узла <TextInlineLink
                                    name={"bs_tech_grn"} text={"ИнтерСети"}/> получают доступ ко
                                    внутренним коммуникациям всего земного флота
                                </p>
                                <p className={"text-justify"}>
                                    После этого они выкладывают полученные данные в общий доступ и хакеры со всей
                                    галактики
                                    за считанные часы выводят из строя большинство правительственных кораблей Монарха по
                                    всей стране. Большая часть машин у Сцеллуры оказывается под контролем противников
                                    режима
                                    и флот вынужден прекратить сопротивление
                                </p>
                                <p className={"text-justify"}>
                                    Пользуясь тем, что земной флот парализован, михъельмцы захватывают <TextInlineLink
                                    name={"bs_planet_the_night"} text={"Угольную"}/> и саму <TextInlineLink
                                    name={"bs_char_mereglis_sonya"} text={"Соню Мергелис"}/>, добивая остатки кораблей
                                    Эшелона Пятых,
                                    которые сумели выдержать атаку хакеров
                                </p>
                                <p className={"text-justify"}>
                                    Михъельмцы во главе с Оттоном Бьерне берут в осаду Землю и крайне быстро получают
                                    полный
                                    контроль над уже беззащитной столицей
                                </p>
                                <p className={"text-justify"}>
                                    Монарх Сиель Алканарра отдает приказ своим войскам сложить оружие - гражданская
                                    война
                                    окончена
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
