import {GetConnections} from "../../../../../components/cms/wiki_functions";
import wiki from "../../../../../components/cms/wiki.json";
import cats from "../../../../../components/cms/wiki_cats.json";
import {CrumbsBlackSunMachinery} from "../../../../../components/Bread_Crumbs";
import {GetQuotes} from "../../../../../components/Quotes";
import {Fidget} from "./fidget";
import {Grime} from "./grime";
import {Nyorun_dreams} from "./nyorun_dreams";
import {Ship_classes} from "./ship_classes";
import {Plato} from "./plato";
import {Redeyed} from "./redeyed";
import {HasSpoilers} from "../../../../../components/spoilers";

let list = {
    "fidget": Fidget,
    "grime": Grime,
    "njorun-dreams": Nyorun_dreams,
    "ship-classes": Ship_classes,
    "redeyed": Redeyed,
    "plato": Plato
}


/*
return {
        "is_a_ship": true,
        "is_a_type": false,
        "is_an_article": false,
        "developed":,
        "class": ,
        "type": ,
        "owned": ,
        "captain": ,
        "size": ,
        "conflicts": ,
        "status": ,
        "weapons": ,
        "defence": ,
        "text":
            <>
            </>
    }
 */

export function Machinery(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_machinery"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Кидонианка и Черное солнце"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    if (data["is_a_ship"] === true) {
        return (
            <>
                <CrumbsBlackSunMachinery name={name}/>

                {data["has_spoilers"] ?
                    <HasSpoilers list={data["spoilers_list"]} />
                    :
                    <></>
                }

                <section id="about">
                    <div className="about-me container">
                        <div className="section-title"><h2>{name}</h2></div>
                        <div className="row">
                            <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                                <GetQuotes name={repos[repo]}/>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul>
                                            {data["class"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Класс: </strong> {data["class"]}</li> : <></>}
                                            {data["type"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Тип: </strong> {data["type"]}</li> : <></>}
                                            {data["owned"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Принадлежность: </strong> {data["owned"]}</li> : <></>}
                                            {data["captain"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Капитан: </strong> {data["captain"]}</li> : <></>}
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul>
                                            {data["size"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Размеры:</strong>{data["size"]}</li> : <></>}
                                            {data["conflicts"] ? <li><i className="fas fa-chevron-right"></i> <strong>Военные
                                                конфликты:</strong>{data["conflicts"]}</li> : <></>}
                                            {data["status"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Статус:</strong>{data["status"]}</li> : <></>}
                                        </ul>
                                    </div>
                                </div>
                                <ul>
                                    {data["weapons"] ? <li><i className="fas fa-chevron-right"></i>
                                        <strong>Вооружение: </strong> {data["weapons"]}</li> : <></>}
                                    {data["defence"] ? <li><i className="fas fa-chevron-right"></i>
                                        <strong>Защита: </strong> {data["defence"]}</li> : <></>}

                                </ul>
                                {conns.length > 0 ?
                                    <p><strong>Связанные статьи:</strong> <>{conns.map(conn => (conn))}</>
                                    </p> : <></>}
                            </div>
                        </div>
                    </div>
                </section>

                <div className="row row-alt">
                    <div className="col-lg-12">
                        {data["text"]}
                    </div>
                </div>

            </>
        )
    }

    if (data["is_a_type"] === true) {
        return (
            <>
                <CrumbsBlackSunMachinery name={name}/>

                {data["has_spoilers"] ?
                    <div className="alert alert-danger">
                        <p className="card-text text-justify">
                            <strong>Ниже могут быть спойлеры</strong>
                            {data["spoilers_list"] ?
                                <> к книгам: {data["spoilers_list"]}</>
                                :
                                <></>
                            }
                        </p>
                    </div>
                    :
                    <></>
                }
                <section id="about">
                    <div className="about-me container">
                        <div className="section-title"><h2>{name}</h2></div>
                        <div className="row">
                            <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                                <GetQuotes name={repos[repo]}/>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul>
                                            {data["class"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Класс: </strong> {data["class"]}</li> : <></>}
                                            {data["developed"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Производство: </strong> {data["developed"]}</li> : <></>}
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul>
                                            {data["size"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Размеры:</strong>{data["size"]}</li> : <></>}
                                            {data["conflicts"] ? <li><i className="fas fa-chevron-right"></i> <strong>Военные
                                                конфликты:</strong>{data["conflicts"]}</li> : <></>}
                                            {data["status"] ? <li><i className="fas fa-chevron-right"></i>
                                                <strong>Статус:</strong>{data["status"]}</li> : <></>}
                                        </ul>
                                    </div>
                                </div>
                                <ul>
                                    {data["weapons"] ? <li><i className="fas fa-chevron-right"></i>
                                        <strong>Вооружение: </strong> {data["weapons"]}</li> : <></>}
                                    {data["defence"] ? <li><i className="fas fa-chevron-right"></i>
                                        <strong>Защита: </strong> {data["defence"]}</li> : <></>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="row row-alt">
                    <div className="col-lg-12">
                        {data["text"]}
                    </div>
                </div>
            </>
        )


    }

    if (data["is_an_article"] === true) {
        return (
            <>
                <CrumbsBlackSunMachinery name={name}/>

                {data["has_spoilers"] ?
                    <div className="alert alert-danger">
                        <p className="card-text text-justify">
                            <strong>Ниже могут быть спойлеры</strong>
                            {data["spoilers_list"] ?
                                <> к книгам: {data["spoilers_list"]}</>
                                :
                                <></>
                            }
                        </p>
                    </div>
                    :
                    <></>
                }
                <section id="about">
                    <div className="about-me container">
                        <div className="section-title"><h2>{name}</h2></div>
                        <div className="row">
                            <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                                <GetQuotes name={repos[repo]}/>
                                {conns.length > 0 ?
                                    <p><strong>Связанные статьи:</strong> <>{conns.map(conn => (conn))}</>
                                    </p> : <></>}
                            </div>
                        </div>
                    </div>
                </section>

                <div className="row row-alt">
                    <div className="col-lg-12">
                        {data["text"]}
                    </div>
                </div>

            </>
        )
    }

}