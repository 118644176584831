import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Agathon() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <TextInlineLink name={"bs_state_republic_of_tiara_minor"}/>,
        "habitats": <span>76 млрд. на поверхности + 212 млрд. в системе</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span><TextInlineLink name={"bs_popups_planet_ellania"}/></span>,
        "g": <span>1.06 земной</span>,
        "atmosphere": <span className="text-success">подходит для людей</span>,
        "biosphere": <span>мертва</span>,
        "system": <TextInlineLink name={"bs_star_horus"} text={"Хор"}/>,
        "sector": <TextInlineLink name={"bs_space_vikoveera"} text={"Виковира"}/>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"}/></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "imgs": [{"short": "bs_wiki_agathon_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears": [
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_cydonian_girl"}/>
            </div>,
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_cydonian_autumn"}/>
            </div>
        ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Агатон</span> –
                    относительно молодая колония землян, возрастом около 3800 лет. Изначально
                    позиционировал себя как плацдарм для развития собственной цивилизации, с отличными от
                    земных ценностями и стремлениями. И пока родина человечества скатывалась обратно к
                    абсолютной монархии и милитаризации общества, агатонцы стремились к максимальной
                    свободе.</p>
                <p className="card-text text-justify">Правда, после пары опустошающих вторжений, им все
                    же пришлось завести себе регулярную армию. Находится Агатон в системе звезды <TextInlineLink
                        name={"bs_star_horus"} text={"Хор"}/> (или Гор) глубоко
                    в секторе <TextInlineLink name={"bs_space_vikoveera"} text={"Виковира"}/>. Дикая природа здесь
                    полностью уничтожена, как и в других метрополиях: ее заменяют аккуратные парки и
                    ровные асфальтированные дорожки с клумбами. Но зато на Агатоне местами даже разрешены
                    дожди, что уже необычно для столичной метрополии.</p>
            </>
    }
}
