import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Northern_star_of_niradanhae() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
                                         text={"метапространственная механика"}/></span>,
        "use": <span>перемещение в пространстве</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Северная звезда Нираданхи</span> –
                    устройство
                    , работающее по неизвестной человечеству технологии. Бич называл его «маяком межбранального
                    позиционирования» и утверждал, что Звезда способна выстроить тоннель в любую точку вселенной. Что
                    такое эта
                    Нираданха и какие еще бывают «звезды», никто не в курсе.
                </p>
            </>
    }
}
