import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Lovecraft_zeta() {
    return {
        "date": <span>-</span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>7</span>,
        "habitable": <span>0</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Бриэнн"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Грация"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "people": <span>кидонианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>-</span>,
        "imgs": [{"short": "bs_wiki_lovecraft_zeta_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <p className='card-text text-justify'><span className='strong'>Дзета</span> – пустая окраинная
                    система, в которой больше мониторинговых станций <TextInlineLink name={"bs_tech_grn"} text={"ГРС"}/>,
                    чем планет.</p>
            </>
    }
}

