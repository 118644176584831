import React, {Component} from 'react'
import {CrumbsBlackSunUnenclosed} from "../../../../components/Bread_Crumbs";
import {SidebarBSGallery, SidebarBSMap, SidebarBSTimeline, SidebarBSWiki} from "../../../../components/Sidebar";

function Unenclosed_hyperbeacons_editor() {
    return (
        <>
            <PageClass/>
        </>
    );
}

class PageClass extends Component {

    componentDidMount() {
        document.title = "Похитители маяков, комментарии редактора"
    }

    render() {
        return (
            <div>
                <CrumbsBlackSunUnenclosed name={<span><i className="fa-solid fa-triangle-exclamation"></i> Похитители маяков: комментарии редактора</span>}/>
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <div className="row"><h2 className="title-bg">Похитители маяков: комментарии редактора</h2>
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Очаг вспыхнул моментально и за считанные минуты нагрел воду в стальном чане. надпись
                                    «Собственность кидонианского военного флота» вызвала приятную ностальгию по суровому
                                    быту армейцев. Старик <span className={"strong"}>вылил ее</span> в деревянную бадью
                                    и плюхнулся следом.</p>
                            </blockquote>
                            Вылил ностальгию? Плюхнулся в ностальгию?<br/><br/>
                            <blockquote>
                                <p>Пустой никому не нужный мир на окраине Приоритета – идеальное место, чтобы осесть
                                    после долгой жизни, вспомнить прошлое и разобраться в себе.</p>
                            </blockquote>
                            ... и умереть от холода, плюхнувшись в бадью с ностальгией.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...Скошгард застрял. Он скрежетал бронепластинами по камню и дергал конечностями,
                                    словно пойманный на обеденном столе кот.</p>
                            </blockquote>
                            Это же как ты котов ловишь?.. Расплющив по столу?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p> – Ты будешь ходить в этом безобразии? – спросил Оливер.</p>
                                <p> – Это чтобы тебя не шокировать.</p>
                            </blockquote>
                            ...а так-то я эксгибиционист.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Вскоре Оливер нашел нечто ценнее мертвого пришельца.</p>
                            </blockquote>
                            <p>Это вопрос приоритетов, но очень многие вещи ценнее трупа пришельца...</p>
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>В полутьме и под скрип снега можно даже свою бабушку не заметить, если старушка как
                                    следует зароется в снег. Но пройти мимо такой картины Хельмунд не смог.</p>
                            </blockquote>
                            Мимо картины зарывающейся бабушки?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Бич расхохотался и от его конвульсий кресло окончательно рассыпалось.</p>
                            </blockquote>
                            "Конвульсий"? Он эпилептик что ли? Лучше заменить на "Бича сотрясало от хохота".
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...Бич слегка разжал хатку...</p>
                            </blockquote>
                            Деревенскую?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Эсквайр не без жалости приподнял лапу существа и полоснул кинжалом.</p>
                            </blockquote>
                            А ему нравится отрезать конечности, я смотрю. Гуманоид, собаконь, осьминог потом... Почему
                            он такой маньяк?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Остальные монстры выпустили в мертвеца еще около двадцати залпов, прежде чем он
                                    поднялся, отряхнулся и хрустнул шеей.</p>
                            </blockquote>
                            Может, "в гостя"? Иначе создается впечатление, будто речь о залпах, поднимающих мертвецов.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>К горлу подтупил ком.</p>
                            </blockquote>
                            Ком, не тупи!
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...блондин утонул в складках шубы, как в черной дыре.</p>
                                <p>– О, так у тебя есть закуски! – парень высунулся из убежища и принюхался.</p>
                            </blockquote>
                            "Убежище" лучше в кавычки: шуба ведь не является убежищем? Если ты не моль.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p> – Я сотру, – он обвел рукой горизонт. – Всю вашу цивилизацию.</p>
                            </blockquote>
                            Таких маньяков еще поискать надо.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Проскочил за спину и взял парня в замок.</p>
                            </blockquote>
                            Который с башенками и единорогами?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>В глаза старику посмотрело точно такое же существо морковного цвета.</p>
                            </blockquote>
                            ...как и он сам? Создается ощущение, что "точно такое же" - это о старике.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Возле самого большого костра сидел невысокий старик. Завидев Оливера и Бича, он
                                    радостно поманил их к себе</p>
                                <p> – Сюда, сюда, ребятки, – судя по голосу, их звал старик.</p>
                            </blockquote>
                            ...старик, а не костер?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>В полутьме и хрусте шагов...</p>
                            </blockquote>
                            Какая дикая смесь.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...прерываемой лишь дыханием и шелестом вентиляции...</p>
                            </blockquote>
                            Дыханием вентиляции?..
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Путь обратно оказался таким же быстрым.</p>
                            </blockquote>
                            Спустя три страницы забываешь, что путь "туда" тоже был быстрым, поэтому фраза "таким же"
                            сбивает с толку. Да и "оказался" здесь не смотрится, ведь событие не удивительное.
                            "Маршрутка оказалась чистой" - вот это удивительно.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Кем бы ты ни был, чем бы ни занимался, рано или поздно найдутся люди, которые захотят
                                    тебя убить.</p>
                            </blockquote>
                            Прямиком в мой фонд золотых цитат!
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...на моменте с техническим описанием полученного от инженеров сигнала, он запнулся и
                                    не смог понять ни слова...</p>
                            </blockquote>
                            Как и все мы, как и все мы...
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Его ухмылка, идиотская и абсолютно неуместная, испортила образ загадочного
                                    всемогущего существа. <span className={"strong"}>На ее фоне</span> в корабле
                                    появилось небольшое отверстие, которое быстро превратилось в трап.</p>
                            </blockquote>
                            На фоне улыбки? Он чеширский кот?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Весь корабль пропах его страхом.</p>
                            </blockquote>
                            Двоякая фраза, ох и двоякая...
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Бронированный ботинок сломал бедняжке ребра. Такой удар должен как минимум
                                    парализовать умопомрачающей болью, но Виктория, вопя как сумасшедшая, снова кинулась
                                    на объект своей ненависти. На этот раз бойцы рисковать не стали – выстрел из рельсы
                                    оборвал жизнь несчастной.</p>
                            </blockquote>
                            То бедняжка, то несчастная... Маньячина она!
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...а на уши обрушился гомон мириадов насекомых.</p>
                            </blockquote>
                            Как интересно ты говоришь слово "люди". Гомон - это ведь про голоса. Он может быть у людей
                            или у птиц, а вот у насекомых – вряд ли.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...существо, которое которое заставило...</p>
                            </blockquote>
                            Бог любит троицу, верно?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p> – Принято, эсквайр. Хорошей охоты.</p>
                            </blockquote>
                            "Хорошей охоты, Каа(с)"
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <SidebarBSWiki/>
                            <hr/>
                            <SidebarBSMap/>
                            <hr/>
                            <SidebarBSTimeline/>
                            <hr/>
                            <SidebarBSGallery/>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export {Unenclosed_hyperbeacons_editor};
