import {CrumbsBSCategory} from "../../../../components/Bread_Crumbs";
import {
    GetWikiArticlesByCustomFilter,
    TextInlineLink
} from "../../../../components/cms/wiki_functions";
import {GlossaryPictureItem} from "./Glossary";
import imgs from "../../../../components/cms/images_repo.json";

export function Places() {
    document.title = "Комос и места || Кидонианка и Черное солнце"

    let places = GetWikiArticlesByCustomFilter("bs_place", "true")
    let vikoveera_places = GetWikiArticlesByCustomFilter("vikoveera_places", "true")
    let brize_places = GetWikiArticlesByCustomFilter("brize_places", "true")
    let demspace_places = GetWikiArticlesByCustomFilter("demspace_places", "true")
    let dawn_places = GetWikiArticlesByCustomFilter("dawn_places", "true")
    let zero_places = GetWikiArticlesByCustomFilter("zero_places", "true")
    let night_places = GetWikiArticlesByCustomFilter("night_places", "true")
    let shark_places = GetWikiArticlesByCustomFilter("shark_places", "true")
    let old_other_places = GetWikiArticlesByCustomFilter("old_other_places", "true")
    let brienne_other_places = GetWikiArticlesByCustomFilter("brienne_other_places", "true")
    let ardemar_places = GetWikiArticlesByCustomFilter("ardemar_places", "true")
    let grace_places = GetWikiArticlesByCustomFilter("grace_places", "true")
    let ariadne_places = GetWikiArticlesByCustomFilter("ariadne_places", "true")
    let kyrikia_places = GetWikiArticlesByCustomFilter("kyrikia_places", "true")
    let megasthenes_places = GetWikiArticlesByCustomFilter("megasthenes_places", "true")
    let morihei_places = GetWikiArticlesByCustomFilter("morihei_places", "true")
    let samborae_places = GetWikiArticlesByCustomFilter("samborae_places", "true")
    let travalsar_places = GetWikiArticlesByCustomFilter("travalsar_places", "true")
    let fleur_places = GetWikiArticlesByCustomFilter("fleur_places", "true")
    let mistral_places = GetWikiArticlesByCustomFilter("mistral_places", "true")
    let millie_places = GetWikiArticlesByCustomFilter("millie_places", "true")
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBSCategory name={<span><i className="fa-solid fa-earth-asia"/>  Места</span>}/>
            </div>
            <div className="row ">
                <div className="col-lg-12 well" style={{"paddingBottom": "1em"}}>
                    <p className="card-text text-justify">На этой странице собраны все места, которые хотя бы раз
                        упоминались
                        в книгах. Даже если какой-то мир здесь не указан, он рано или поздно сюда попадет. Обычно места
                        появляются
                        раньше, чем выходит новая часть, так что могут быть небольшие спойлеры.
                    </p>
                    <p className="card-text text-justify">Для удобства миры разбиты по метоснахождению, но ниже есть <a
                        href={"#full_list"}>полный
                        алфавитный список</a>.
                    </p>
                </div>
            </div>

            <div className='row'>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_wiki_ajliraenian_domination_map_ru"].link}
                                         repo={"bs_state_ajliraenian_domination"}
                                         descr={<span>Айлирэнская Доминация – конституционная монахия в кластере Бриэнн с крайне сильной властью императора и столицей на Айлирэне</span>}/>
                    <GlossaryPictureItem img={imgs["bs_wiki_republic_of_tiara_minor_map_ru"].link}
                                         repo={"bs_state_republic_of_tiara_minor"}
                                         descr={<span>Республика Малой короны – влиятельнейшее государство Демкосмоса со столицей на Агатоне</span>}/>
                </div>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_wiki_cydonians_map_ru"].link} repo={"bs_state_cydonian_priority"}
                                         descr={<span>Кидонианский Приоритет – меритократия в кластере Бриэнн со столицией на Кидонии</span>}/>
                    <GlossaryPictureItem img={imgs["bs_wiki_the_great_monarchy_map_ru"].link} repo={"bs_state_the_great_monarchy"}
                                         descr={<span>Великая Монархия – более несуществующее государство в Старом космосе со столицей на Земле</span>}/>
                </div>
            </div>

            <h4 className="title-bg text-center">ДРУГИЕ ГОСУДАРСТВА</h4>
            <div key="mainRow" className="row ">
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{"marginBottom": "0"}}>
                        <li><TextInlineLink name={"bs_state_free_worlds"}/></li>
                        <li><TextInlineLink name={"bs_state_galactic_democratic_alliance"}
                                            text={"Галактическое демократическое содружество"}/></li>
                    </ul>
                </div>
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{"marginBottom": "0"}}>
                        <li><TextInlineLink name={"bs_state_tribalon_republic"} text={"Трайбалонская республика"}/></li>
                        <li><TextInlineLink name={"bs_state_urtm"} text={"Объединенные республики Малой короны"}/></li>
                    </ul>
                </div>
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{"marginBottom": "0"}}>
                        <li><TextInlineLink name={"bs_state_tallesian_principality"}/></li>
                        <li><TextInlineLink name={"bs_state_aethurian_republic"}/></li>
                    </ul>
                </div>
            </div>

            <h4 className="title-bg text-center" style={{"marginTop": "1em"}}>МЕСТА</h4>
            <h5 className="text-center title-bg"><TextInlineLink name={"bs_space_brienne_cluster"}
                                                                 text={"КЛАСТЕР БРИЭНН"}/></h5>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_ardemar_sector"} text={"Ардемар"}/></h6>
            <div className="row ">
                {ardemar_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_ariadne_sector"} text={"Ариадны"}/></h6>
            <div className="row ">
                {ariadne_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_grace_sector"} text={"Грация"}/></h6>
            <div className="row ">
                {grace_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink name={"bs_space_kyrikia"}
                                                                                              text={"Кирикия"}/></h6>
            <div className="row ">
                {kyrikia_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_megasthenes"} text={"Мегасфен"}/></h6>
            <div className="row ">
                {megasthenes_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_millie_sector"} text={"Милли"}/></h6>
            <div className="row ">
                {millie_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_mistral_sector"} text={"Мистраль"}/></h6>
            <div className="row ">
                {mistral_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink name={"bs_space_morihei"}
                                                                                              text={"Морихей"}/></h6>
            <div className="row ">
                {morihei_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_samborae_sector"} text={"Самбора"}/></h6>
            <div className="row ">
                {samborae_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_travalsar"} text={"Травалсар"}/></h6>
            <div className="row ">
                {travalsar_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_fleur_sector"} text={"Флёр"}/></h6>
            <div className="row ">
                {fleur_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}>Другие</h6>
            <div className="row ">
                {brienne_other_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>

            <h5 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_democratic_space"} text={"ДЕМКОСМОС"}/></h5>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_vikoveera"} text={"Виковира"}/></h6>
            <div className="row ">
                {vikoveera_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_breeze_sector"} text={"Бриз"}/></h6>
            <div className="row ">
                {brize_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}>Другие места</h6>
            <div className="row ">
                {demspace_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>

            <h5 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_the_old_space"} text={"СТАРЫЙ КОСМОС"}/></h5>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_shark_sector"} text={"Акулы"}/></h6>
            <div className="row ">
                {shark_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_the_night_sector"} text={"Ночи"}/></h6>
            <div className="row ">
                {night_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_sector_zero"} text={"Нулевой"}/></h6>
            <div className="row ">
                {zero_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}><TextInlineLink
                name={"bs_space_sunrise_sector"} text={"Рассвета"}/></h6>
            <div className="row ">
                {dawn_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{"marginTop": "1em"}}>Другие</h6>
            <div className="row ">
                {old_other_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{"marginBottom": "0"}}>
                            <li><TextInlineLink name={item.repo_id}/></li>
                        </ul>
                    </div>
                ))}
            </div>

            <div id="full_list" className="row" style={{"marginTop": "1em"}}>
                <div className="col-lg-12">
                    <div className='well'>
                        <h4 className='text-center'>ПОЛНЫЙ СПИСОК МЕСТ
                        </h4>
                        <table className="table centered">
                            <thead>
                            <tr>
                                <th scope="col">Название</th>
                                <th scope="col">Категория</th>
                            </tr>
                            </thead>
                            <tbody>
                            {places.map(item => (
                                <tr>
                                    <td><TextInlineLink name={item.repo_id}/></td>
                                    <td>{item.cat_display}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

