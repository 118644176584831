import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Mirage() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>11</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ариадны"}/></span>,
        "area": <span>Хеллесианский рейд</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "people": <span>кидонианцы, бассианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>192 млрд.</span>,
        "imgs": [{"short": "bs_wiki_mirage_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Мираж</span> – желто-белая звезда
                    класса F в кластере Бриэнн. Название переводится с кидонианского как «особая красота» или
                    «великолепие» и имеет женский род (поэтому нужно говорить «система Мираж», а не «Миража»). Дом
                    Кидонии и еще десятка некогда безжизненных планет.</p><p
                className="card-text text-justify">Древние кидонианцы их успешно терраформировали для сельского
                хозяйства и местами какой-никакой промышленности, хотя жизнь на них теплится по большей части под
                землей из-за проблем с температурой поверхности. Особой промышленной мощи там нет, но все товары
                первой необходимости на Кидонию поставляют прямо с соседних планет, так что систему Мираж можно
                назвать одной большой метрополией. Самодостаточность – редкая вещь для людских миров.</p>
            </>
    }
}

