import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';


export function De_levine_clara() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "is_not_ready": false,
        "name": "Клара де Левин",
        "fullname": <span>Клара Алисия де Левин</span>,
        "born": <span>79 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>78</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <span>Флорен де Монтье и Джессика де Левин</span>,
        "daughter": <span><TextInlineLink name={"bs_char_de_levine_rosalie"}/></span>,
        "imgs": [
            {"short": "bs_char_de_levine_clara_1_jpg_500", "big": "bs_char_de_levine_clara_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    Биография <span className="strong">Клары</span> не пестрит яркими событиями. Если верить
                    изысканиям <TextInlineLink name={"bs_char_de_karma_ivar"} text={"де Кармы"}
                                               popup={"...который нанял людей следить за ней несколько месяцев"}/>,
                    Клара прилетела с Кидонии на <TextInlineLink name={"bs_planet_earth"} text={"Землю"}/> примерно
                    за 30 лет до <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"}/>, в самый
                    разгар <TextInlineLink name={"bs_timeline_galactic_war"} text={"Войны"}/>.
                    Изначально девушку направили в командировку на несколько месяцев, аудитором в одно из
                    представительств
                    концерна <TextInlineLink name={"bs_comp_briglioni_leushoffer"} text={"«Брильони–Леусхоффер»"}/>, но
                    в первые дни пребывания на родине человечества она встретила <TextInlineLink
                    name={"bs_char_the_whip"} text={"необычного молодого человека"}/> и
                    решила остаться. Клару не смутили даже опасения, что боевые действия могут приблизиться к Земле.
                </p>
                <p className="card-text text-justify">
                    Через несколько лет, все еще в разгар войны, они решили завести ребенка, но затем случилось
                    нечто, поссорившее Клару с Бичом.
                    Неизвестно, знала ли кидонианка, что имеет дело с пришельцем, использующим человеческую оболочку,
                    или догадалась только когда стало слишком поздно. Наверняка ясно одно: в последние
                    месяцы беременности, когда Розали уже готова была появиться в этой галактике, Клару не засекла ни
                    одна земная камера наблюдения. Девушка переехала со съемной квартиры в общежитие для
                    сотрудников <TextInlineLink name={"bs_comp_briglioni_leushoffer"} text={"«Брильони–Леусхоффер»"}/> и
                    не
                    выходила из здания ни на шаг до самого дня рождения Розали. Несмотря на положение, она продолжала
                    работать в
                    офисе, который располагался несколькими этажами выше.
                    А когда маленькая де Левин появилась на свет, Клара оставила ее в больнице и в тот же день улетела
                    с Земли на частном эвакуационном транспорте для сотрудников <TextInlineLink
                    name={"bs_comp_briglioni_leushoffer"} text={"«Брильони–Леусхоффер»"}/>.
                </p>
                <p className="card-text text-justify">
                    С тех пор девушка живет в доме родителей на Кидонии, который до рождения Розали планировала продать.
                    У нее нет романтических связей или друзей и она продолжает работать аудитором на Леусхоффер.
                    25 лет с рождения дочери Клара провела так, словно жизнь остановилась: никаких новых хобби,
                    поездок в отпуск или других вещей, которые обычно есть у обеспеченных сотрудников одной из
                    крупнейших
                    корпораций в галактике.
                </p>
                <p className="card-text text-justify">
                    Тем не менее, несмотря на режим «работа-дом-работа», есть в ее поведении примечательная деталь:
                    Клара регулярно меняет маршрут передвижения, не ходит в одни и те же места больше 2 раз подряд
                    и не заказывает доставку у одного и того же заведения чаще, чем раз в месяц.
                </p>
            </>
    }
}
