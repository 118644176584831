import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Telenmark() {
    return {
        "date": <span>2122 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_aethurian_republic"}/></span>,
        "habitats": <span>53 млрд. (37 на поверхности)</span>,
        "people": <span>этурийцы, агатонцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>2</span>,
        "g": <span>1.0 земной</span>,
        "atmosphere": <span><span className='badge bg-success'>подходит для людей</span></span>,
        "biosphere": <span>мертва</span>,
        "system": <span>Теленмарк-Джемини</span>,
        "sector": <span>-</span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "area": <span></span>,
        "imgs": [{"short": "bs_wiki_telenmark_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Теленмарк</span> – метрополия в
                    центре Этурийской республики. Служит перевалочной базой для многих торговых гипертрасс
                    между <TextInlineLink name={"bs_space_the_old_space"} text={"Старым"}/> и <TextInlineLink
                        name={"bs_space_democratic_space"} text={"Демократическим космосом"}/>, т.к.
                    расположен вблизи <TextInlineLink name={"bs_tech_hyperrun"}
                                                      text={"трассы"}/> «Земля-Агатон-Тернерианский
                    предел».</p>
            </>
    }
}

