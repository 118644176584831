import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Doab() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">День всех бомб</span> – самый любимый
                    в <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритете"}/> праздник, эдакий
                    эквивалент земного Нового года. Отмечается в честь обретения независимости от этих самых землян
                    много веков назад и вместе с этим объединения всех заселенных миров
                    сектора <TextInlineLink name={"bs_space_ariadne_sector"} text={"Ариадны"}/> под одним флагом.
                    Считается днем
                    создания такого во всех отношениях замечательного государства, как Приоритет (и еще не до конца
                    ясно,
                    сарказм это или нет).</p>
            </>
    }
}
