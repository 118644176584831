import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vitruvianus() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>, <TextInlineLink
            name={"book_hyperbeacons"} text={"«Похитители маяков»"}/></>,
        "homeworld": <span>неизвестно</span>,
        "state": <span>неизвестно</span>,
        "langs": <span><TextInlineLink name={"bs_lang_earth"} text={"земной"}/>, неизвестно</span>,
        "size": <span>антропоид, гораздо крупнее человека</span>,
        "type": <span>разумное</span>,
        "bs_universe_biom": <span>совместимый с земным</span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><strong>Витрувианы</strong> - существа, созданные неизвестными
                    пришельцами
                    из человеческих детей. Взрослые витрувианы плохо владеют земным языком и не чувствуют никакой связи
                    со своими бывшими братьями-людьми.</p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'><strong>Информация появится в процессе
                        написания <TextInlineLink name={"book_cydonian_autumn"} text={"«Кидонианской осени»"}/></strong>
                    </p>
                </div>
            </>
    }
}