import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Scs() {
    return {
        "top":
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>межправительственная организация</span>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Штаб-квартира: </strong> <TextInlineLink
                            name={"bs_planet_agathon"}/>

                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Члены: </strong>
                            <span>
                                                <ul className="characters-bar">
                                                    <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_javet_bejsenaer_1_jpg_200"}/></li>
                                                </ul>
                                            </span>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                            <span>аналог ООН</span>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Председатель:</strong>
                            <span>регулярно меняется</span>
                        </li>
                    </ul>
                </div>
            </div>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span
                    className="strong">Совет коллективной безопасности</span> или <span
                    className="strong">СКБ</span> – международный совещательный орган, нечто вроде галактического
                    ООН. Позиционирует себя как единственный способ избежать Второй Галактической, хотя
                    с <TextInlineLink name={"bs_timeline_galactic_war"} text={"Первой"}/> это не сработало.</p>
            </>
    }
}
