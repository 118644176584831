import {TextInlineLinkCustomClass} from './cms/wiki_functions';

export function WikiFavourites() {
    return (
        <>
            <h5 className='footer-h5'>ИЗБРАННОЕ</h5>
            <ul className="post-list">
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"book_cydonian_autumn"}
                                               text={"«Кидонианская осень»"} popup={""}/></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_timeline"}
                                               text={"История мира «Черного солнца»"} popup={""}/></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_planet_cydonia"} text={"Кидония"} popup={""}/></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_tech_grn"}
                                               text={"Галактическая ретрансляторная сеть"} popup={""}/></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_creature_hydra"} text={"Стальная Гидра"} popup={""}/>
                </li>
            </ul>
        </>
    )

}