import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Laser() {
    return {
        "type": <span>энергетическое оружие</span>,
        "use": <span>любые цели</span>,
        "text":
            <>
                <h4 className="title-bg">Лазеры во времена <TextInlineLink
                    name={"book_cydonian_girl"} text={"«Кидонианки»"}/>: 1 год <TextInlineLink
                    name={"bs_timeline"} text={"ДТ"}/></h4>
                <p className="card-text text-justify">
                    <span className="strong">Лазер </span> или <span className="strong">«эмиссионка»</span> –
                    оружие, ипускающее когерентную лучистую энергию. Само слово «laser» <TextInlineLink
                    name={"bs_lang_arum"} text={"агатонский"}/> акроним от –
                    «light amplification by stimulated emission of radiation» («усиление света путем вынужденного
                    излучения»). По сути, лазерная пушка передает энергию от источника питания в некую рабочую среду
                    (напр. газ) и превращает ее в поток излучения. Во времена «Кидонианки» техническое развитие
                    человечества не
                    позволяло
                    сделать лазер, способный за приемлемое время пробить броню корабля: <TextInlineLink
                    name={"bs_tech_space_steel"} text={"армейская сталь"}/> слишком
                    прочная и на ее разрез потребуется слишком много энергии. Кроме этого, маневровые двигатели
                    позволяли менять положение корабля достаточно быстро, чтобы броня успевала «остыть» с помощью
                    внутренних систем охлаждения. То же самое касалось пехоты: доспехи и <TextInlineLink
                    name={"bs_tech_ils"} text={"КИЖи"}/> достаточно хорошо защищали солдат от
                    современных лазеров, которые, к тому же, требовали более деликатного обращения, чем другие системы
                    вооружения.
                </p>
                <p className="card-text text-justify">
                    Поэтому хоть лазеры активно использовались в досветовые времена и на <TextInlineLink
                    name={"bs_timeline_light_era_dawn"} text={"Заре световых перелетов"}/>, их
                    применение после изобретения более совершенных систем защиты быстро сошло на нет. Из-за этого
                    абсолютное большинство боевых машин
                    используют рельсовые пушки для нападения, а лазерные – для защиты от ракетного оружия, космических
                    мин и астероидов.
                </p>
                <p className="card-text text-justify">
                    С другой стороны, разработки в
                    области <TextInlineLink name={"bs_tech_shield_kinetic"} text={"кинетических щитов"}/>, способных
                    останавливать снаряды, грозили привести к возвращению лазерам былой славы – поле регрессии может
                    остановить лишь твердый предмет и малоэффективно против излучения.
                </p>
            </>
    }
}
