import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Engine_shunting() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"астромеханика"}/></span>,
        "use": <span>космические полеты</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Маневровые двигатели</span> –
                    небольшие реактивные двигатели по всей площади корабля, которые помогают менять вектор движения
                    машины или удерживать ее в одном положении. Управляются обычно бортовым компьютером, который сам
                    решает, сколько и на какую мощность нужно включить, чтобы принять заданный пилотом вектор.</p>
            </>
    }
}
