import {BooksBlacksunPart1} from "../components/BooksBlacksunPart1"
import {BooksBlacksunPart2} from "../components/BooksBlacksunPart2"
import {CrumbsLev2} from "../components/Bread_Crumbs";

function Books() {
    document.title = "Все книги Сергея Крехно"
    return (
        <>
            <div className="row" key="crumbs">
                <CrumbsLev2 name={<span><i className="fa-solid fa-book"></i>  Книги</span>}/>
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12">
                    <h1 className="title-bg">Все книги Сергея Крехно</h1>
                    <BooksBlacksunPart1/>
                    <BooksBlacksunPart2/>
                </div>
            </div>
        </>
    )
}

export {Books};