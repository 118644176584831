import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Bassian() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <TextInlineLink name={"bs_state_cydonian_priority"}/>,
        "habitats": <span>12 млн.</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "mother": <span>Митридат</span>,
        "g": <span>0.18 земной</span>,
        "atmosphere": <span className="text-danger">нет</span>,
        "biosphere": <span>нет</span>,
        "system": <TextInlineLink name={"bs_star_mirage"}/>,
        "sector": <TextInlineLink name={"bs_space_ariadne_sector"} text={"Ариадны"}/>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "type": <span>луна-<TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "imgs": [{"short": "bs_wiki_bassian_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Бассиан</span> заселен
                    почти одновременно с Кидонией. Изначально современники <TextInlineLink
                        name={"bs_char_brienne_de_estrada"} text={"Бриэнны де Эстрада"}/> обустроили
                    на нем лагерь для промышленников, добывавших газ из атмосферы Митридата. Там же они развернули
                    научный центр, в котором искали способы более эффективной добычи и переработки. Со временем
                    этот центр разросся до настоящего научного города, а позже - отдельной колонии.
                </p>
                <p className="card-text text-justify">Именно в его стенах зародилась Бассианская Академия Содружества,
                    известная как место учебы <TextInlineLink name={"bs_char_van_gloria_adel"}
                                                              text={"Адель ван Глории"}/> и других
                    знаменитых кидонианских лидеров.
                </p>
                <p className="card-text text-justify">Бассианцы перестали считать себя отдельной нацией и стали
                    частью Приоритета после окончания <TextInlineLink name={"bs_timeline_war_300"}
                                                                      text={"Трехсотлетней войны"}/>.
                </p>
            </>
    }
}
