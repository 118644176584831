import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Abspace() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
                                         text={"метапространственная механика"}/></span>,
        "use": <span>системы связи, в перспективе - двигатели кораблей</span>,
        "text":
            <>
                <p className='card-text text-justify'><span className='strong'>Надпространство</span> – соседняя
                    (условно) вселенная, до которой рукой подать из нашей. В нем больше измерений, чем в мире людей,
                    и потому перемещение в тамошнем пространстве-времени происходит по совершенно другим законам.
                    Благодаря этому Надпространство, можно использовать для ускорения путешествий по нашей
                    вселенной: расстояние, которое в привычном мире мы проходим за год, в Надпространстве можно
                    преодолеть мгновенно. Этот эффект отлично работает с радиоволнами, но местные законы физики
                    мешают существованию привычной людям материи, а потому отправлять туда плотные объекты пока
                    невозможно. К тому же, количество энергии и экзоматерии, необходимое для создания даже крохотной
                    червоточины, слишком велико, чтобы применять этот эффект для открытия больших порталов.</p>
            </>
    }
}
