import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Van_gloria_adel() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"}/>, <TextInlineLink name={"book_hyperbeacons"} /></>,
        "born": <span>71 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>70</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианка"}/></span>,
        "name": "Адель ван Глория",
        "fullname": <span>Адель Бриджит Кристина ван Глория</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>королева <TextInlineLink name={"bs_state_cydonian_priority"}
                                               text={"Кидонианского Приоритета"}/></span>,
        "imgs": [
            {"short": "bs_char_van_gloria_adel_1_jpg_500", "big": "bs_char_van_gloria_adel_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Юность</h3>
                <p className="card-text text-justify">
                    <span className="strong">Адель</span> родилась на Кидонии в семье юристов. Молодость она провела
                    в частной школе, той же, в которую ходили <TextInlineLink
                    name={"bs_char_de_karma_ivar"}/> и <TextInlineLink name={"bs_char_skarsgard_oliver"}/>. Там девушка
                    имела
                    несчастье связать себя узами дружбы с этой семьей. А немного позже, в университетские времена,
                    Адель и вовсе закрутила роман с Иваром, который перерос в долгую и полную драмы историю.
                </p>
                <p className="card-text text-justify">
                    С юных лет Адель тяготела к социологии, науке менеджмента и, в особенности, экономике. Еще в школе
                    она плотно занималась изучением сложных финансовых теорий и даже написала статью об
                    экономике <TextInlineLink name={"bs_space_the_old_space"} text={"Старого космоса"}/>: <code>«Земное
                    государство времен <TextInlineLink name={"bs_char_alkanarra_tristan"} text={"Тристана"}/> как модель
                    милитаристской
                    корпоратократии»</code>. Девушка мечтала связать свою жизнь именно с этой сферой.
                </p>
                <p className="card-text text-justify">
                    Училась Адель в <TextPopup name={"bs_state_cydonian_priority_science"} text={"БАС"}
                                               popup={"Бассианской академии Содружества"}/>, сначала в кампусе на родной
                    Кидонии, а затем на самоей луне <TextInlineLink name={"bs_planet_bassian"}/>. Там она приобрела
                    немало друзей, но еще больше врагов,
                    среди которых была <TextInlineLink name={"bs_char_de_silgoni_ursula"}/> и даже <TextInlineLink
                    name={"bs_char_scalligan_lloyd"} text={"Ллойд Скаллиган"}/>, на тот момент преподававший в Академии
                    математику. Уже тогда ее горячие споры с преподавателями и другими студентами стали публичными
                    шоу, на которые собирались поглазеть тысячи людей. В архивах Бассианского дискуссионного клуба
                    сохранились десятки словесных потасовок между юной ван Глорией и самыми разными деятелями науки.
                    Не раз во время этих «бесед» рождались новые матерные слова (несколько даже получили широкое
                    использование и попали в словари).
                </p>
                <div className="iteresting-fact">
                    <blockquote>
                        <p className="card-text text-justify">
                            Мы как раз познакомились в кабинете школьного психолога – она была там частой гостьей. И
                            матерный слог
                            умела загнуть такой, что с факультета филологии приходили консультироваться
                        </p>
                        <small>Ивар де Карма, <TextInlineLink name={"book_cydonian_girl"}/></small>
                    </blockquote>
                </div>
                <p className="card-text text-justify">
                    С таким подходом Адель никогда бы не получила высшее образование, но, как однажды выразился
                    Скаллиган, она «слишком хорошо знала, о чем говорит». В <TextInlineLink
                    name={"bs_state_cydonian_priority"} text={"Приоритете"}/> экзаменацию проводит
                    искусственный
                    интеллект, поэтому ван Глория, даже будучи не в ладах с преподавателями, без труда получила степень.
                    Ее бакалаврская работа 38 года ДТ, <code>«Экономика планеты-государства как частный случай замкнутой
                    финансовой
                    системы де Грасса»</code>, оказалась настолько глубоко проработанной, что Адель сразу после
                    выпуска пригласили в штат преподавателей.
                </p>
                <p className="card-text text-justify">
                    Девушка провела там несколько лет и получила кандидатскую по экономике. Затем, собрав
                    рекомендации, ушла в Королевскую налоговую инспекцию и вернулась на Кидонию. За это время успела
                    начаться <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактическая"}/> и весь Приоритет
                    с ужасом следил за погружением соседней страны в
                    хаос. Адель боялась, что война докатится до кидонианцев и что Ивара, на тот момент ее возлюбленного,
                    отправят на фронт. Так и случилось. За год до конца боевых действий, в 26 ДТ, де Карма отправился
                    на передовую.
                </p>
                <p className="card-text text-justify">
                    После его возвращения отношения уже не были прежними: Ивар, до этого не знавший, что такое настоящая
                    война, вернулся со сломанной психикой. Он не был приспособлен к мирной жизни и вопреки уговорам
                    ван Глории выбрал путь боевого офицера, отказавшись бросать службу. На этом их пути разошлись, хотя
                    и не до конца: еще много лет они переписывались и проводили время вместе, но ничего серьезного
                    в итоге не вышло.
                </p>
                <h3 className="title-bg">Восхождение на трон</h3>
                <p className="card-text text-justify">
                    За 19 лет до Таманрасетт, когда Адель было всего 52, глобальный ИИ Приоритета, «Система доверия»,
                    выбрал ее в качестве
                    кандидата на престол. Предыдущий монарх, Иветта Шеридан, лишилась поста за злоупотребление властью
                    и военные авантюры. Среди них было вступление Приоритета в Галактическую для помощи землянам,
                    которую многие считали
                    бессмысленной тратой жизней, а также короткое, но кровавое подавление
                    активности <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатого змея"}/> в <
                    TextInlineLink name={"bs_space_samborian_traverse"} text={"Самборианском траверсе"}/>.
                </p>
                <p className="card-text text-justify">
                    Кроме Адель в список кандидатов попали и некоторые ее знакомые, в том числе Скаллиган и де Сильони.
                    Когда победила
                    ван Глория, это уничтожило любые намеки на прощение старых обид. Против нее моментально
                    сформировалась
                    целая политическая сила, состоявшая из бывших студентов и преподавателей БАС. Многие из этих людей
                    обладали связями и средствами для организованного саботажа ее решений.
                </p>
                <p className="card-text text-justify">
                    В управлении страной Адель себе не изменяла. Она вела резкую, бескомпромиссную политику, часто
                    грубила членам <TextInlineLink name={"bs_state_cydonian_priority_priumviratas"}
                                                   text={"Приумвирата"}/> и даже лидерам других государств. Однако
                    знала, как общаться с
                    людьми и как создать себе хороший медийный образ: несмотря на неприязнь среди высших чинов страны,
                    сместить ее не было возможности из-за крайне высокого одобрения среди населения.
                </p>
                <p className="card-text text-justify">
                    Кроме этого, она приложила немало усилий по искоренению кумовства в Приумвирате и экспертных советах
                    при правительстве. Сотни людей лишились теплого местечка, стабильной зарплаты, а некоторые еще и
                    свободы. Отдел по борьбе с должностными преступлениями ликовал, а вот Адель нажила себе еще больше
                    врагов.
                </p>
                <p className="card-text text-justify">
                    На момент <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"}/>, примерно за год
                    до Таманрасетт, против ван Глории уже в открытую выступали целые политические партии. Скаллиган
                    и вовсе несколько лет строил свою риторику исключительно на несогласии с королевой, даже когда это
                    выглядело полным абсурдом.
                </p>
                <p className="card-text text-justify">
                    Поэтому когда ван Глория сначала убедила Приумвират помочь <TextInlineLink
                    name={"bs_char_alkanarra_siel"} text={"Монарху"}/> подавить бунт, а затем резко
                    отказалась от этого решения (по тайной просьбе де Кармы), ей пришлось несладко. Правительство
                    дало девушке полную свободу действий, однако она предпочла остаться в стороне и позволить земному
                    государству окончательно развалиться. Это подкосило ее рейтинги и дало повод усомниться в
                    адекватности королевы.
                </p>
                <p className="card-text text-justify">
                    При этом она не сообщила Приумвирату про исчезновение 171-го пограничного флота в
                    секторе <TextInlineLink name={"bs_space_fleur_sector"} text={"Флёр"}/> и
                    убедила <TextInlineLink name={"bs_state_cydonian_priority_army"}
                                            text={"Генштаб Приоритета"}/> ничего не сообщать народу. Вместо этого
                    королева, пользуясь влиянием
                    Эсквариата, фактически взяла армию под контроль и полностью засекретила все подобные инциденты.
                    За пределы военного флота не вышло даже массовое исчезновение людей у <TextInlineLink
                    name={"bs_star_last_exodus"} text={"Последнего Исхода"}/>, хотя
                    расползание слухов среди гражданских скрыть не удалось.
                </p>
                <p className="card-text text-justify">
                    Она также санкционировала тайную операцию, прозванную в будущем <TextInlineLink
                    name={"bs_timeline_tajbennae_incident"} text={"«Инцидент на Тайбенне»"}/> и примерно
                    за год до Таманрасетт сделала Приоритет первым государством в истории, вступившим в контакт с
                    другой разумной расой. Контакт и перестрелку.
                </p>
                <p className="card-text text-justify">
                    После этого она попросила Ивара де Карму о помощи, поскольку знала о его выдающихся талантах и
                    репутации человека, способного провернуть тайную операцию любой сложности. Предстояло разобраться,
                    что это за пришельцы и чего они хотят...
                </p>
            </>
    }
}
