export function Casr() {
    return {
        "type": <span>ракетное оружие</span>,
        "use": <span>небольшие маневренные цели</span>,
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong">Умная противокорабельная ракета</span> – нужна как раз для тех случаев, когда
                    стрелять обычными снарядами нельзя из-за опасности попасть в мирных людей. Мерзкая штука, если
                    вздумаешь бежать от полиции в каменных джунглях.</p>
            </>
    }
}
