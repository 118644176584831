import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Currencies() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong">Далмация (айлирэнский денарий)</span> –
                    валюта <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Доминации"}/>, почитать
                    можно <TextInlineLink name={"bs_currency_dalmatia"} text={"тут"}/>.</p>
                <p
                    className="card-text text-justify"><span className="strong">Земной империум</span> или <span
                    className="strong">нулевой</span> (в честь Нулевого солнца) – <TextInlineLink
                    name={"bs_currency_imperium"} text={"здесь"}/>.</p>

                <p className="card-text text-justify"><span
                    className="strong">Терос</span> – <TextInlineLink name={"bs_currency_teros"} text={"теперь здесь"}/>.
                </p>

                <p
                    className="card-text text-justify"><span className="strong">Шарм</span> –
                    валюта <TextInlineLink name={"bs_state_cydonian_priority"} text={"кидонианцев"}/>,
                    находится <TextInlineLink name={"bs_currency_charmo"} text={"здесь"}/>.</p>
            </>
    }
}











