import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hyperrun() {
    return {
        "science": <span>космография</span>,
        "use": <span>навигация</span>,
        "text":
            <>
                <p className='card-text text-justify'>
                    <strong>Гипертрасса</strong> – трасса, по которой движутся
                    быстрее скорости света. На ней гарантированно нет больших объектов, в которые можно врезаться.
                </p>
                <p className='card-text text-justify'>
                    <strong>Крупнейшие гипертрассы:</strong>
                </p>
                <p
                    className='card-text text-justify'>
                    Земля–Агатон–Тернерианский предел: основной торговый маршрут
                    между <TextInlineLink name={"bs_space_the_old_space"} text={"Старым"}/> и <TextInlineLink
                    name={"bs_space_democratic_space"} text={"Демократическим космосом"}/>.
                </p>
                <p
                    className='card-text text-justify'>Хеллесианский рейд–Акронская небула: самый загруженный маршрут
                    в кластере <TextInlineLink name={"bs_space_brienne_cluster"} text={"Бриэнн"}/>, по нему проходит
                    большая
                    часть трафика между <TextInlineLink name={"bs_state_cydonian_priority"}
                                                        text={"Приоритетом"}/> и <TextInlineLink
                        name={"bs_state_ajliraenian_domination"} text={"Доминацией"}/>.
                </p>
                <p
                    className='card-text text-justify'>Хал-Вернон–Новая Гавана: крупнейшая трасса в <TextInlineLink
                    name={"bs_state_free_worlds"} text={"Свободных мирах"}/>, соединяющая два самых
                    заселенных и экономически развитых региона <TextInlineLink name={"bs_space_samborae_sector"}
                                                                               text={"Самборы"}/>.
                </p>
                <p className='card-text text-justify'>Киренайский путь: несколько связанных гипертрасс между кластером
                    Бриэнн и Демкосмосом. Начинается на <TextInlineLink name={"bs_planet_cyrenaic"}
                                                                        text={"Киренаике"}/> в <TextInlineLink
                        name={"bs_space_arthuri_belt"} text={"Поясе Артура"}/> и проходит через миры
                    сектора <TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"}/>,
                    <TextInlineLink name={"bs_space_the_night_sector"} text={"Ночи"}/>, этурийскую территорию и
                    заканчивается на <TextInlineLink
                        name={"bs_planet_tribalon_at_alamein"} text={"Трайбалоне-у-Аламейн"}/>.
                </p>
            </>
    }
}
