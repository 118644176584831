import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Xavier_kardenos() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Ксавьер Карденос",
        "fullname": <span>Ксавьер <small>«Трискелион»</small> Карденос</span>,
        "born": <span>93 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>92</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонец"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "relatives": <span><TextInlineLink name={"bs_char_salibar_kardenos"}/></span>,
        "work": <span>директор <TextInlineLink name={"bs_comp_cms"} text={"СБК"}/> в звании генерала диверсионных войск</span>,
        "imgs": [
            {"short": "bs_char_xavier_kardenos_1_jpg_500", "big": "bs_char_xavier_kardenos_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Работа в СБК и <TextInlineLink name={"book_cydonian_girl"}
                                                                        text={"«Кидонианка»"}/></h3>
                <p className="card-text text-justify">
                    <span className="strong">Ксавьер</span> – один из тех самых «слишком правильных» агентов. В его
                    биографии нет белых пятен (для тех, у кого есть к ней доступ), сомнительных связей, вредных привычек
                    и даже выговоров от начальства. С самых первых дней в разведке (а его завербовали еще в
                    университете),
                    Карденос показывал отменные результаты работы и быстрый карьерный рост. И уже этот факт вызывает
                    здоровое недоверие к официальной биографии.
                </p>
                <p className="card-text text-justify">
                    Директором СБК он стал примерно за 18 лет до Таманрасетт. Под его началом агатонцы разработали
                    несколько сомнительных сценариев смены власти в крупных монархических державах,
                    включая <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритет"}/>
                    (хотя кидонианское государство, технически, является мериторкратией и на монархию не тянет).
                    Именно благодаря усилиям Ксавьера служба много лет помогала <TextInlineLink
                    name={"bs_char_tom_torvalds"} text={"Торвальдсу"}/> готовить мятеж против
                    землян. Эта операция, первая из трех, должна была покончить с <TextInlineLink
                    name={"bs_state_the_great_monarchy"} text={"Монархией"}/> и стать триумфом в карьере
                    как самого Карденоса, так и тех, кто его поставил на эту должность. Канцлер <TextInlineLink
                    name={"bs_char_callisto_kindrace"} text={"Киндрейс"}/> лично следил
                    за всеми этапами подготовки и выполнения, в какой-то момент даже взяв на себя командование.
                </p>
                <p className="card-text text-justify">
                    Именно по этой причине Ксавьер поставил руководить «в поле» своего брата, Салибара. Тот должен был
                    успешно пройти по всем ступеням плана и тоже взлететь по карьерной лестнице. Однако, когда все
                    <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"пошло наперекосяк"}/>, рычагов
                    управления ситуацией у Ксавьера не оказалось.
                </p>
                <p className="card-text text-justify">
                    Несмотря на успех и полную капитуляцию землян, большое количество жертв разубедило агатонских
                    руководителей в том, что идея операций по свержению монархов так уж хороша. Над Ксавьером нависла
                    угроза стать козлом отпущения. Кроме этого, триумф его карьеры (если можно так назвать) стал могилой
                    для брата: <TextInlineLink name={"bs_char_de_karma_ivar"}/> застрелил Салибара на <TextInlineLink
                    name={"bs_planet_earth"} text={"Земле"}/>, в последний день операции. Точных данных
                    из-за
                    чего это случилось, СБК так и не получила.
                </p>
                <p className="card-text text-justify">
                    Ксавьер запланировал привлечь Ивара к ответственности любыми средствами, вплоть до абсолютно
                    нелегальных. Начать решил с <TextInlineLink name={"bs_char_esora_eve"} text={"Эсоры"}/>, которая
                    была близка с дерзким кидонианцем. Он добился
                    обвинительного приговора
                    трибунала и упрятал девушку на 40 лет. Затем принялся за де Карму.
                </p>
            </>
    }
}