import wiki from './cms/wiki.json';
import imgs from './cms/images_repo.json';

export function BooksBlacksunPart2() {
    return (
        <>
            <div className="row row-alt">
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_helleon_cold"].link}>Холодное солнце</a><br/>~2025</h5>
                    <a href={wiki["book_helleon_cold"].link}>
                        <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
                            <img style={{"maxWidth": "100%"}} alt="Обложка Холодного солнца Сергея Крехно"
                                 src={imgs["book_helleon_cold_200_286_ru"].link}/>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_tamanrasett_contact"].link}>Контакт у
                        Таманрасетт</a><br/>~2026</h5>
                    <a href={wiki["book_tamanrasett_contact"].link}>
                        <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
                            <img style={{"maxWidth": "100%"}} alt="Обложка Контакт у Таманрасетт Сергея Крехно"
                                 src={imgs["book_tamanrasett_contact_200_286_ru"].link}/>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_the_free_traders"].link}>Вольные торговцы</a><br/>~2026
                    </h5>
                    <a href={wiki["book_the_free_traders"].link}>
                        <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
                            <img style={{"maxWidth": "100%"}} alt="Обложка Вольных торговцев Сергея Крехно"
                                 src={imgs["book_free_traders_200_286_ru"].link}/>
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_black_sun"].link}>Черное солнце</a><br/>~2027</h5>
                    <a href={wiki["book_black_sun"].link}>
                        <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
                            <img style={{"maxWidth": "100%"}} alt="Обложка Черного солнца Сергея Крехно"
                                 src={imgs["book_black_sun_200_286_ru"].link}/>
                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}