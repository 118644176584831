export function Magnetic_cannon() {

    return {
        "type": <span>энергетическое оружие</span>,
        "use": <span>электронные устройства и начинка космических кораблей</span>,
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong">Магнетрон (магнитная пушка, ЭМ-ловушка)</span> –
                    орудие, использующее электромагнитное поле для повреждения электроники врага. Обычно применяется
                    для обезвреживания ракет и мелких кораблей, но легко сработает и против ручного оружия или
                    средств связи, если ударить им по пехоте. Против крупных целей со множеством дублирующих цепей
                    питания или на больших расстояниях почти бесполезно.</p>
                <p className="card-text text-justify">ЭМ-ловушкой также зовут компактный переносной магнетрон в виде
                    гранаты, предназначенный для обезвреживания вражеской техники в ближнем бою.</p>
            </>
    }
}
