import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ajliraenian_domination() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>,
        "form": <span>конституционная монархия</span>,
        "currency": <span><TextInlineLink name={"bs_currency_dalmatia"}
                                          text={"далмация (айлирэнский денарий)"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"}/>, <TextInlineLink name={"bs_lang_telpimary"}
                                                                                              text={"тельпимари"}/></span>,
        "leader": <span>Император <TextInlineLink name={"bs_char_javet_bejsenaer"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "sectors": <span>26</span>,
        "worlds": <span>3 695 (278 метрополии)</span>,
        "people": <span>айлири, кидонианцы</span>,
        "imgs": [{"short": "bs_wiki_ajliraenian_domination_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Айлирэнская Доминация</span>
                    – абсолютная монархия с налетом недодемократии в кластере <TextInlineLink
                        name={"bs_space_brienne_cluster"}
                        text={"Бриэнн"}/>. В
                    Доминации есть некое подобие классовой
                    системы, которая закреплена на законодательном уровне: люди делятся на полноценных граждан айлири (и
                    квиритов (квириты). Первые имеют все права, которые есть у граждан других развитых стран, а вторые –
                    только
                    часть. Например, квириты не могут занимать руководящие должности или вести бизнес. Чтобы стать
                    гражданами,
                    им нужно или отработать пятьдесят лет на каком-нибудь непривлекательном производстве (на нормальную
                    работу
                    их редко берут) или отслужить двадцать пять лет в армии. Других легальных путей нет, разве что
                    получить
                    гражданство лично от Императора за особые заслуги.
                </p>
                <p className="card-text text-justify">Граничит с <TextInlineLink name={"bs_state_cydonian_priority"}
                                                                                 text={"Кидонианским Приоритетом"}/> по
                    рукаву Персея, с <TextInlineLink name={"bs_space_samborae_sector"} text={"Самборой"}/> по
                    рукавам Персея и Стрельца, и с <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                                   text={"Монархией"}/> по
                    рукаву Ориона.</p>
                <h3 id={"currency"} className="title-bg">Валюта</h3>
                <p className="card-text text-justify"><span
                    className="strong">Далмация (айлирэнский денарий)</span> –
                    валюта <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Доминации"}/>, очень
                    волатильная и
                    ненадежная: периодически обваливается из-за регулярных репрессий и подъема милитаризма в стране.
                    Даже мощная промышленность и сильная экономика не делает эту страну такой же привлекательной для
                    инвесторов,
                    как <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритет"}/> или <TextInlineLink
                        name={"bs_state_republic_of_tiara_minor"} text={"Республика Малой короны"}/>.</p>
            </>
    }
}

