export function Bs_science_planet_engineering() {
    return {
        "knowledge_area": <span>геология, планетология, тектоника, геохимия</span>,
        "use": <span>терраформирование планет, стабилизация условий</span>,
        "text": <>
            <h4 className="title-bg">О науке
            </h4>
            <p className="card-text text-justify">
                <span className="strong">Планетарная инженерия</span> – комплекс наук, благодаря которым людям удалось
                приспособить к жизни асболютно адские миры, а также не дать планетам развалиться в процессе добычи
                ресурсов.
            </p>
            <p className="card-text text-justify">
                Планетарная инженерия занимается как геологическими процессами в толще планет, так и окружающими их
                атмосферой и биосферой. Она подразделяется на множество сфер знания, зачастую слабо между собой
                связанных.
            </p>
            <p className="card-text text-justify">
                Основная задача, которую решает этот комплекс наук – терраформация землеподобных миров и карликовых
                планет. Первые чаще всего используются для жизни или добычи ресурсов, вторые - только для добычи. В
                обоих случаях необходимо предусмотреть множество факторов, каждый из которых способен принести немало
                проблем.
            </p>
            <p className="card-text text-justify">
                В мирах со своей биосферой планетарные инженеры стремятся сохранить хрупкий экобаланс, при этом
                убирая из окружающей среды опасные виды и явления. На планетах без биосферы они возводят купола, в
                которых
                культивируют новые виды, приспосабливая их к жизни в уникальных для каждой планеты условиях, а затем
                расширяют жилые зоны, постепенно превращая всю планету в сад.
            </p>
            <p className="card-text text-justify">
                В загрязненных мирах инженеры ставят атмосферные и водные процессоры, убирающие вредные вещества из
                окружающей среды. А на планетах, которые не будут использоваться для жизни, они следят за соблюдением
                правил геобезопасности, чтобы не дать жадным горнорудным корпорациям разломать планету на части или
                случайно
                взорвать стратовулкан.
            </p>
            <p className="card-text text-justify">
                Несмотря на очевидную пользу этого комплекса наук, у него есть и темная сторона. Начиная с первых
                колонизированных миров, за человечеством тянется вереница истребленных видов, разрушенных экосистем
                и разнообразия, замененного бетоном.
            </p>
        </>
    }
}
