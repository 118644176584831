import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hela() {

    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>162 млрд. (25 на поверхности)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/>, <TextInlineLink
            name={"bs_people_hellesians"} text={"хеллесианцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>нет</span>,
        "g": <span>1.2 земной</span>,
        "atmosphere": <span className='badge bg-success'>подходит для людей</span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ариадны"}/></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"}/></span>,
        "imgs": [{"short": "bs_wiki_hela_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Хела</span> – вторая метрополия
                    Хеллесианского рейда и вторая по значимости планета в Приоритете. Здесь находятся все резервные
                    стратегические объекты страны: дворец, штаб <TextInlineLink name={"bs_comp_esquirete"}
                                                                                text={"Эсквариата"}/>, различные
                    лаборатории и
                    производственные комплексы для всех базовых товаров.</p><p
                className='card-text text-justify'>Небо на Хеле оранжево-красное, за что её ещё зовут Огненный или
                Рубиновый лис. <TextInlineLink name={"bs_people_alamarsie"}/> же называют её
                Дафа-Хару,
                что переводится как Красный камень или Рубин. Сам небосклон иногда пронизывает большая
                молочно-белая полоса, которая светится в ночи, затмевая звёзды. Это поток газа от одной звезды к
                другой, яркий подобно третьему солнцу.</p>
            </>
    }
}

