import imgs from './cms/images_repo.json';

function Books(){
    return {
        "book_cydonian_girl":{"name":"Кидонианка","link":"/black_sun/books/cydonian-girl","image":"book_cydonian_girl_200_ru"},
        "book_hyperbeacons":{"name":"Похитители маяков","link":"/black_sun/books/hyperbeacons","image":"book_hyperbeacons_200_ru"},
        "book_cydonian_autumn":{"name":"Кидонианская осень","link":"/black_sun/books/cydonian-autumn","image":"book_cydonian_autumn_200_ru"},
        "book_bonecollectors":{"name":"Собиратели костей","link":"/black_sun/books/bonecollectors","image":"book_bonecollectors_200_ru"},
        "book_helleon_cold":{"name":"Холодное солнце","link":"/black_sun/books/helleon-cold","image":"book_helleon_cold_200_ru"},
        "book_tamanrasett_contact":{"name":"Контакт у Таманрасетт","link":"/black_sun/books/the-tamanrasett-contact","image":"book_tamanrasett_contact_200_ru"},
        "book_the_free_traders":{"name":"Вольные торговцы","link":"/black_sun/books/the-free-traders","image":"book_free_traders_200_ru"},
        "book_black_sun":{"name":"Черное солнце","link":"/black_sun/books/the_black_sun","image":"book_black_sun_200_ru"}
    }
}

function Appearance(props) {
    let b = Books()
    if (imgs[b[props.name].image].format === "PNG") {
        return (
            <div className="img-thumbnail-v2" style={{"maxWidth": "220px", "margin":"auto"}}>
                <div className="bg-image-sm" style={{"backgroundImage": "url("+imgs["background_stars_sm"].link+")"}}>
                    <a href={b[props.name].link}>
                        <img style={{"maxWidth": "100%"}} alt={b[props.name].name} src={imgs[b[props.name].image].link} />
                    </a>
                </div>
            </div>
        )
    }

    return(
        <a href={b[props.name].link} className="">
            <img className="img-thumbnail-v2" alt={b[props.name].name} src={imgs[b[props.name].image].link} />
        </a>
    )
}

export {Appearance}