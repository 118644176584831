import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_night_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"}/></span>,
        "owned": <span>под вопросом</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "capital": <span><TextInlineLink name={"bs_planet_the_night"} text={"Угольная Ньёрун"}/></span>,
        "people": <span>земляне</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Сектор Ночи</span> – кусок Старого
                    космоса,
                    рядом с <TextInlineLink name={"bs_space_sector_zero"} text={"Нулевым сектором"}/>. В нем очень много
                    миров,
                    заселенных еще в начале световой эпохи. На них сплошь и рядом следы первых поселенцев и неумелых
                    попыток терраформации, большая часть которых провалилась, а поселенцы умерли, но это другая
                    история.</p><p
                className="card-text text-justify"><span className="strong">Последствия «Кидонианки»:</span>
            </p>
                <p className=
                       "card-text text-justify"><TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                text={"Восстание Пауков"}/> привело к тому, что
                    администрация сектора на Ночи (Угольной Ньёрун) была
                    парализована. Большая часть планет объявила о желании провести референдум по вопросу
                    независимости от <TextInlineLink name={"bs_planet_earth"} text={"Земли"}/> и с тех пор почти все
                    «ночные миры» находятся в подвешенном юридическом состоянии.</p>
            </>
    }
}

