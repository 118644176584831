import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Colbert_michel() {
    return {
        "name": "Михель Колберт",
        "fullname": <span>Михель Колберт</span>,
        "born": <span>185 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>184</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"хеллесианец"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "daughter": <span><TextInlineLink name={"bs_char_colbert_simone"}/></span>,
        "work": <span>губернатор системы <TextInlineLink
            name={"bs_star_hela_gemini"}/></span>,
        "imgs": [
            {"short": "bs_char_colbert_michel_1_jpg_500", "big": "bs_char_colbert_michel_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Михель</span> – отец Симоны Колберт и видный активист в <TextPopup
                    name={"bs_popups_priority_politics_4p"}
                    text={"4П"}/>. Ярый
                    противник <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Адель ван Глории"}/>. Вместе с
                    лордом <TextInlineLink name={"bs_char_scalligan_lloyd"} text={"Скаллиганом"}/> и <TextInlineLink
                    name={"bs_char_de_silgoni_ursula"} text={"Урсулой де Сильони"}/> выступает за
                    реформацию <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритета"}/> с упразднением
                    меритократии в пользу прямых выборов и отмену запрета на
                    агитацию.
                </p>
                <p className="card-text text-justify">
                    Когда его дочь пошла работать на <TextInlineLink name={"bs_comp_esquirete"}
                                                                     text={"Эсквариат"}/> королевы, Михель едва не
                    порвал связи с наследницей
                    фамилии. С тех пор, уже много лет, они видятся только по праздникам и лишь для совместного
                    фото на вечеринке, полной лицемерия.
                </p>
            </>
    }
}
