import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Ajliree() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>,
        "state": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_javet_bejsenaer_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_javet_eijenora_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_javet_erbees_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_javet_pauline_1_jpg_200"}/></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_telpimary"} text={"тельпимари"}/>, <TextInlineLink
            name={"bs_lang_earth"} text={"земной"}/></span>,
        "text": <><h3 className="title-bg">История</h3>
            <p className="card-text text-justify"><span
                className="strong">Айлири (не называть их айлирэнцами!)</span> – в широком смысле жители Айлирэнской
                Доминации,
                в более узком – полноправные граждане Доминации. Они очень не любят, когда их называют айлирэнцами,
                поскольку в языке тельпимари подобные обобщения считаются оскорбительными. Существует планета,
                существует народ на этой планете и существует народ в целом государстве.
                Последние два понятия с течением веков
                объединились в слове "айлири", хотя юридически общество Доминации все еще разделено на неравные
                группы, одна из которых, будучи прямыми потомками первых жителей Айлирэна, имеет гораздо больше прав
                и свобод.
            </p>
            <p className="card-text text-justify">
                Занимательно, что они не оскорбляются таким терминам как "айлирэнский". Скорее даже поощряют его,
                так как на земном термин "айлирианский" звучит более сложно и вычурно.
            </p>
            <h3 id={"language"} className="title-bg">Язык</h3>
            <p className="card-text text-justify">
                Язык айлири зовется тельпимари (или тельпимарийский, если хочешь звучать как сельский болван),
                что буквально означает "говор улетевших". "Улетевшими" они
                назвали себя через несколько веков после того, как покинули Землю: в какой-то момент различия
                в призношении между их современниками и видеозаписями предков стали настолько сильны, что
                новые поколения уже практически не понимали земных прародителей. Язык стремительно мутировал
                и только с появлением относительно быстрой связи с другими населенными мирами тельпимари стал
                понемногу возвращаться к истокам. Тем не менее, в Доминации все еще очень много людей, которые
                с трудом изъясняются на общепринятом в галактике земном языке, а некоторые и вовсе не стремятся
                его выучить.
            </p></>
    }
}