import wiki from '../components/cms/wiki.json';

export function Not_Found() {
    document.title = "Страница не найдена || Кидонианка и Черное солнце"
    return (
        <div className="alert alert-danger">
            <p>Нет такой страницы 🤕</p>
            <p>Можно начать с <a className="ordinary" href="/">Главной</a> или <a className="ordinary" href={wiki["all_books"].link}>Списка книг</a>.
            <br />
            А можно сразу полистать цикл <a className="ordinary" href={wiki["bs_series"].link}>«Черное солнце»</a> и готовые книги <a className="ordinary" href={wiki["book_cydonian_girl"].link}>«Кидонианка»</a> / <a className="ordinary" href={wiki["book_hyperbeacons"].link}>«Похитители маяков»</a> / <a className="ordinary" href={wiki["book_cydonian_autumn"].link}>«Кидонианская осень»</a>
            </p>
        </div>
    )
}
