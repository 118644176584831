import imgs from "./images_repo.json";
import { Image } from 'antd';

export function GetFancyBoxImgStarsBackground500(props){
    if (imgs[props.short].format === "PNG"){
        return(
            <>
                <div style={{"maxWidth": "500px", "margin":"auto", "padding": "0.25rem", "backgroundColor": "#fff", "border": "1px solid #dee2e6"}}>
                    <div className="bg-image-sm" style={{"backgroundImage": "url("+imgs["background_stars_sm_350_500"].link+")"}}>
                        <Image
                            maxWidth={500}
                            maxHeight={500}
                            backgroundImage={"url("+imgs["background_stars_sm_350_500"].link+")"}
                            src={imgs[props.short].link}
                            preview={{
                                src: imgs[props.big].link,
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
    if (imgs[props.short].format === "JPG"){
        return(
            <>
                <div style={{"maxWidth": "500px", "margin":"auto", "padding": "0.25rem", "backgroundColor": "#fff", "border": "1px solid #dee2e6"}}>
                    <Image
                        maxWidth={500}
                        maxHeight={500}
                        src={imgs[props.short].link}
                        preview={{
                            src: imgs[props.big].link,
                        }}
                    />
                </div>
            </>
        )
    }

    return(
        <>
            <div style={{"maxWidth": "500px", "margin":"auto"}}>
                <div className="bg-image-sm" style={{"backgroundImage": "url("+imgs["background_stars_sm_350_500"].link+")"}}>
                    <a data-caption={imgs[props.big].short_text} data-fancybox="true" href={imgs[props.big].link}>
                        <img className="img-max-full-width"  alt={imgs[props.big].short_text} src={imgs[props.short].link} />
                    </a>
                </div>
            </div>
        </>
    )
}

export function GetFancyBoxImg100(props){
    return(
        <>
            <Image
                src={imgs[props.short].link}
                preview={{
                    src: imgs[props.big].link,
                }}
                style={{"maxWidth": "100px", "padding": "0.25rem", "backgroundColor": "#fff", "border": "1px solid #dee2e6"}}
            />
        </>
    )
}

export function GetImg(props){
    let alt = imgs[props.img].short_text_ru
    if (props.text !== undefined){
        alt = props.text
    }
    return(
        <img alt={alt} src={imgs[props.img].link}/>
    )
}