import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Engine_main() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"астромеханика"}/></span>,
        "use": <span>космические полеты</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Маршевые двигатели (разгонные / тягачи / бустеры)</span> –
                    мощные двигатели с огромными соплами, которые дают кораблю основное ускорение. Жрут топливо как
                    инфляция доходы, зато могут довести почти до околосветовых скоростей, если только на корабле
                    работает компенсатор инерции. Без последнего экипаж превратится в кровавые лужи на стенах где-то
                    на пятой минуте полета, а машина развалится на части.</p>
            </>
    }
}
