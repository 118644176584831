import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Montgomery_lorena() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"} />,
        "name": "Лорена Монтгомери",
        "fullname": <span>Лорена Монтгомери</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>адмирал 77-го михъельмского флота</span>,
        "img_trans": false,
        "born": <span>49 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>48</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"}/></span>,
        "nation": <span>михъельмианка</span>,
        "imgs": [
            {"short": "bs_char_montgomery_lorena_1_jpg_500", "big": "bs_char_montgomery_lorena_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">О героине</h3>
                <p className="card-text text-justify">
                    <span className="strong">Лорена</span> родилась на Михъельме, но выросла на <TextInlineLink
                    name={"bs_planet_earth"} text={"Земле"}/>. Во флот ее
                    привела сама судьба: мать и отец были ветеранами <TextInlineLink name={"bs_timeline_galactic_war"}
                                                                                     text={"Галактической"}/> и после ее
                    окончания остались служить
                    в земном оборонном периметре. Дочь, следуя по стопам родителей, поступила в военную академию на
                    Месяце (земной луне), едва ли не самое элитное учебное заведение в стране. После окончания девушку
                    отправили служить в родной <TextInlineLink name={"bs_space_shark_sector"} text={"сектор Акулы"}/>,
                    где она сразу попала под начало харизматичного
                    гросс-адмирала <TextInlineLink name={"bs_char_otton_bjorne"} text={"Оттона Бьерне"}/>. Именно его
                    взгляды и
                    пламенные речи о глупости земных правителей помогли девушке
                    принять сторону мятежников во время <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                        text={"Восстания Акулы"}/>.
                </p>
                <p className="card-text text-justify">
                    После ареста <TextInlineLink name={"bs_char_tom_torvalds"} text={"Торвальдса"}/>, когда большинство
                    высокопоставленных офицеров сбежало из страны, Лорена
                    оказалась одной из немногих, кому хватило мужества стоять до конца. Несмотря на катастрофическую
                    нехватку оружия, техники и финансирования, а также полное отсутствие опыта командования большим
                    флотом, она сумела привести Михъельм к победе. Не без помощи Оттона Бьерне и <TextInlineLink
                    name={"bs_char_de_karma_ivar"} text={"Ивара де Кармы"}/>,
                    разумеется.
                </p>
            </>
    }
}
