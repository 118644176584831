export function Gray_market() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Серый рынок</span> –
                    обобщенное название мест, где можно купить личную или засекреченную информацию. Рынок
                    очень широк и разнообразен: тут тебе и фото со взломанных терминалов звезд, и
                    секретные гипертрассы контрабандистов, и даже планы захвата планет со стрелочками и
                    всяким таким военным.
                </p>
            </>
    }
}
