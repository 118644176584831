import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ariadne_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "capital": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы, хеллесианцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Ариадны</span> – большой почти
                    ровный куб посреди Кидонианского Приоритета. Здесь можно найти саму Кидонию, ее сестру Хелу и
                    еще пару крупных <TextInlineLink name={"bs_universe_planet_types"} text={"метрополий"}/>. Про кучу
                    мелких планет промолчу – их просто очень много.</p>
            </>
    }
}

