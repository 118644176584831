import {GetQuotes} from "../../../components/Quotes";
import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {ImgRound3EM, ImgRound3EMFancyBoxArticle} from "../../../components/imgs/imgRound";
import {GetConnections, TextInlineLink} from "../../../components/cms/wiki_functions";
import imgs from "../../../components/cms/images_repo.json";
import {Button, Flex} from "antd";
import {DownloadOutlined, LoadingOutlined} from "@ant-design/icons";
import {AddDownloadRecord, GetDownloads} from "../../../components/requests";
import {useEffect, useState} from "react";

export function Book_2_Hyperbeacons() {
    document.title = "Сергей Крехно - Похитители маяков"

    const [downloadedCount, setDownloadedCount] = useState(<LoadingOutlined spin />);

    useEffect(() => {
        GetDownloads(
            "hyperbeacons",
            setDownloadedCount,
        )
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"/>  Похитители маяков</span>}/>
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_hyperbeacons_jpg_ru"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Обложка Похитители маяков Сергея Крехно"
                             src={imgs["book_hyperbeacons_jpg_ru"].link}/>
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Похитители маяков</h1></div>
                    <div className="row">
                        <GetQuotes name={"book_hyperbeacons"}/>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_2_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_4_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"}/></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"/> <h6>Статус:</h6> <strong><span
                                className="text-success">опубликован</span></strong></li>
                            <li><i className="far fa-calendar-alt"/> <h6>Дата выхода:</h6> 09.09.2021</li>
                            <li><i className="far fa-list-alt"/> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Черное солнце #2"}/></li>
                            <li><i className="fas fa-angle-double-left"/> <h6>Предыдущая:</h6> <TextInlineLink
                                name={"book_cydonian_girl"} text={"Кидонианка"}/></li>
                            <li><i className="fas fa-angle-double-right"/> <h6>Следующая:</h6> <TextInlineLink
                                name={"book_cydonian_autumn"} text={"Кидонианская осень"}/></li>
                            <li><i className="fa-solid fa-clock-rotate-left"/> <h6>Место в <TextInlineLink
                                name={"bs_timeline"} text={"истории"}/>:</h6> ~12 месяцев до <TextInlineLink
                                name={"book_tamanrasett_contact"} text={"Таманрасетт"}/></li>
                            <li><i className="fas fa-book-open"/> <h6>Объем:</h6> ~90 стр.</li>
                            <li><i className="fas fa-book"/> <h6>Издательство:</h6> нет</li>
                            <li><i className="fas fa-user-edit"/> <h6>Редактор:</h6> Валерия Likos</li>
                            <li><i className="fas fa-palette"/> <h6>Художник:</h6> [ДАННЫЕ УДАЛЕНЫ] & Atarina</li>
                            <li><i className="fas fa-download"/> <h6>Скачано (примерно):</h6> {downloadedCount} раз</li>
                            <li><h6>Связанные
                                страницы:</h6><>{GetConnections("book_hyperbeacons").map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <h3 className="title-bg">Скачать</h3>
                    <Flex gap="small" wrap>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a4",
                                    "hyperbeacons",
                                    "ru",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://adele.sergey-krekhno.com/Черное солнце 2 - Похитители маяков (А4).pdf')
                            }}
                        >
                            PDF A4
                        </Button>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a5",
                                    "hyperbeacons",
                                    "ru",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://adele.sergey-krekhno.com/Черное солнце 2 - Похитители маяков (А5).pdf')
                            }}
                        >
                            PDF A5
                        </Button>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a6",
                                    "hyperbeacons",
                                    "ru",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://adele.sergey-krekhno.com/Черное солнце 2 - Похитители маяков (А6).pdf')
                            }}
                        >
                            PDF A6
                        </Button>
                    </Flex>
                </div>
            </div>

            <div className="row row-alt">
                <div className="col-lg-9">
                    <h3 className="title-bg">Аннотация</h3>
                    <div className=" well"><p className="card-text text-justify"><TextInlineLink
                        name={"bs_char_hartrey_kellays"}/> – инженер, отвечающий за работоспособность <TextInlineLink
                        name={"bs_tech_grn"} text={"ИнтерСети"}/> в отдаленном
                        регионе <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритета"}/>. Его дни
                        скучны и заполнены
                        лишь рабочей рутиной. Однажды он становится свидетелем одномоментного исчезновения миллионов
                        людей и решает во что бы то ни стало выяснить причину катастрофы.</p>
                        <p className="card-text text-justify">Немного позже в хмурых таежных лесах <TextInlineLink
                            name={"bs_planet_tajbennae"} text={"Тайбенны"}/> терпит крушение транспортный корабль
                            с кидонианским десантом. Единственный уцелевший, эсквайр Ее Величества <TextInlineLink
                                name={"bs_char_skarsgard_oliver"} text={"Оливер Скошгард"}/>, должен
                            объединиться с таинственным существом, зовущим себя <TextInlineLink
                                name={"bs_char_the_whip"} text={"Бичом"}/>, если хочет понять причину
                            массовых похищений и вернуться домой не по частям.</p>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_oliver_1_jpg_full"}
                                                        small={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"}
                                                        small={"bs_char_the_whip_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_hartrey_kellays_1_jpg_full"}
                                                        small={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_colbert_simone_1_jpg_full"}
                                                        small={"bs_char_colbert_simone_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_vaal_leon_1_jpg_full"}
                                                        small={"bs_char_de_vaal_leon_1_jpg_200"}/></li>
                    </ul>
                </div>
            </div>
            <div className="row row-alt"><h3 className="title-bg">Занимательные факты</h3></div>
            <div className="row row-alt well">
                <ul className="book-info">
                    <li className="card-text text-justify"><h6>«Похитители» планировались как часть романа</h6><br/>Изначально
                        рассказ был частью сюжетной линии «Кидонианки», но его пришлось вырезать, т.к. он плохо
                        смотрелся
                        как в виде цельного контрапункта в конце книги, так и разрезанным на несколько сцен.
                    </li>
                    <li className="card-text text-justify"><h6>Трудный выбор названия</h6><br/>Изначально рассказ
                        назывался «Смотритель гипермаяков» – даже обложка есть! Но пришлось переименовать, потому что
                        читается довольно сложно. Да и что такое вообще этот «гипермаяк»? Страшно же звучит...
                    </li>
                    <li className="card-text text-justify"><h6>Альтернативная версия описания</h6><br/><TextInlineLink
                        name={"bs_char_hartrey_kellays"} text={"Келлес Хартри"}/>, незадачливый
                        инженер-интерсетевик, по наивности направляет чересчур самоуверенного гвардейца <TextInlineLink
                            name={"bs_char_skarsgard_oliver"} text={"Оливера Скошгарда"}/> на далекую <TextInlineLink
                            name={"bs_planet_tajbennae"} text={"Тайбенну"}/>. Здесь несчастного ждут традиционные
                        таежные забавы: праздношатания по лесам, питание подножным кормом, игра в снежки с местной
                        разновидностью йети, негостеприимные отшельники и попытки спастись от полуголых культуристов с
                        суровыми лицами. Компанию в этих развлечениях ему составит <TextInlineLink
                            name={"bs_char_the_whip"} text={"Бич"}/>, таинственное существо, способное в
                        одиночку умертвить легион врагов и обладающее нездоровой страстью к подражанию фильмам про
                        оборотней. Перестрелки в наличии.
                    </li>
                </ul>
            </div>
        </div>
    )
}
