import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_tech_space_steel() {
    return {
        "science": <span>физика, сопромат</span>,
        "use": <span>кораблестроение</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Корабельная сталь</span>, она же <span
                    className="strong">космосталь</span> (иногда <span className="strong">армейская сталь</span>) –
                    высокопрочный сплав, обеспечивающий защиту от физических
                    нагрузок, перепадов температур, вредных излучений и других опасных воздействий. Применяется
                    преимущественно
                    в космостроении: для создания скелета и обшивки транспорта и станций.
                </p>
                <p className="card-text text-justify">
                    Сталь крайне устойчива к ударным нагрузкам, что делает ее отличным материалом для брони против
                    шнеков (снарядов для <TextInlineLink name={"bs_weaponry_railgun"} text={"рельсовых пушек"}/>). Кроме
                    этого, она выдерживает крайне высокие температуры, что
                    дает прекрасную защиту от <TextInlineLink name={"bs_weaponry_laser"}
                                                              text={"энергетического оружия"}/>. Именно после
                    изобретения современных корабельных
                    сплавов применение лазеров в военном флоте сошло на нет и снова уступило место рельсовым пушкам:
                    выяснилось, что серия снарядов в борт является большей угрозой для космостали, чем лазерный луч.
                </p>
                <p className="card-text text-justify">
                    Еще одно незаменимое свойство – отсутствие рикошета. Сталь поглощает энергию шнеков из ручных рельс
                    и орудий средней тяжести (таких, что ставятся на <TextInlineLink name={"bs_ships_ship_classes"}
                                                                                     text={"легких канонерках"}/>). Это
                    делает ее идеальным
                    материалом и для внутренней обшивки военных кораблей: выстрел из рельсы в металлическом коридоре
                    не приведет к рикошету, если шнек попадет в стену.
                </p>
                <p className="card-text text-justify">
                    Из корабельной стали также создаются некоторые наземные структуры военного флота и хранилища
                    взрывоопасных материалов.
                </p>
            </>
    }
}
