import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Kindrace() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/></>,
        "name": "Каллисто Киндрейс",
        "born": <span>226 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>225</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонец"}/></span>,
        "fullname": <span>Каллисто <small>«Старикан»</small> Киндрейс</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>канцлер <TextInlineLink name={"bs_state_republic_of_tiara_minor"}
                                              text={"Республики Малой короны"}/></span>,
        "imgs": [
            {"short": "bs_char_callisto_kindrace_1_jpg_500", "big": "bs_char_callisto_kindrace_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Каллисто</span> – злобный дед агатонской внешней политики. В молодости
                    он был офицером военного флота и немало времени провел в стычках с землянами на
                    рубеже <TextInlineLink name={"bs_space_the_old_space"} text={"Старого"}/> и <
                    TextInlineLink name={"bs_space_democratic_space"} text={"Демкосмоса"}/>
                    . Его личность и взгляды
                    на жизнь сформировались во времена, когда
                    бывшая <TextInlineLink name={"bs_state_the_great_monarchy"}/> открыто
                    конфликтовала с агатонцами и даже кидонианцами. Уже тогда он понял, что с землянами
                    нужно разобраться как можно быстрее, пока не случилось нечто катастрофическое.
                </p>
                <p className="card-text text-justify">
                    С линии фронта Киндрейс ушел прямиком в политику, но внести свой вклад не успел: пока он взбирался
                    по карьерной лестнице и только начал метить на более-менее серьезную должность,
                    грянула <TextInlineLink name={"bs_comp_galactic_security"} text={"Галактическая"}/>. Каллисто
                    переборол
                    желание вернуться в строй и продолжил работать в правительстве
                    <TextInlineLink name={"bs_state_republic_of_tiara_minor"} text={"Республики"}/>. Там он познакомился
                    с такими личностями как генерал <TextInlineLink name={"bs_char_xavier_kardenos"}
                                                                    text={"Карденос"}/> и Григориус Эсора,
                    отец <TextInlineLink name={"bs_char_esora_eve"} text={"Евы"}/>.
                </p>
                <p className="card-text text-justify">
                    После Галактической Киндрейсу удалось занять кресло канцлера. Он победил благодаря умиротворяющей
                    риторике и обещаниям никогда не допустить повторения этих 12 адских лет. Однако с первых же дней
                    на должности стал планировать операции по смене конституционного строя в Монархии, <TextInlineLink
                    name={"bs_state_ajliraenian_domination"} text={"Доминации"}/>  и <
                    TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритете"}/>.
                </p>
                <p className="card-text text-justify">
                    С Монархией получилось: во время <TextInlineLink name={"book_cydonian_girl"}
                                                                     text={"«Кидонианки»"}/> Киндрейс добился своего и
                    помог михъельмцам сместить
                    тирана <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Алканарра"}/> с трона. Однако
                    количество жертв и опасность новой Галактической были слишком
                    высоки.
                </p>

            </>
    }

}