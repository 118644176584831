import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Uns() {
    return {
        "science": <span>информатика</span>,
        "use": <span>навигация, системы распознавания</span>,
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong">Универсальные сигнатуры</span> или <span className="strong">УНС</span> –
                    система строго определенных кодов, которые передаются кораблем по радиосвязи в окружающее
                    пространство на протяжении всего полета. В сигнатуры встраивают тип и класс машины, название,
                    имя капитана, порт приписки (страна, сектор, планета) и другую информацию, в зависимости от
                    назначения корабля. Пассажирские лайнеры, например, передают количество людей на борту и маршрут
                    следования. Обычно сигнатуры представляют собой длинную строку кода, которую перед прочтением
                    нужно расшифровать, чтобы понять содержимое.</p><p className="card-text text-justify">Корабль
                без сигнатур автоматически расценивается военным флотом как пиратский: его без лишних вопросов
                обдают <TextInlineLink name={"bs_weaponry_magnetic_cannon"} text={"ЭМ-вспышкой"}/> и берут на
                абордаж. В случае малейшего сопротивления расстреливают из тяжелых орудий. Поэтому дома блок с
                сигнатурами лучше не забывать.</p><p className="card-text text-justify">Подделать их сложно, потому
                что кроме текстовой информации к УНС прилагается поле с уникальным сертификатом, полученным от
                авторизационного центра: при обнаружении другого корабля, ботовой компьютер обычно уточняет
                в <TextInlineLink name={"bs_tech_grn_2"} text={"ИнтерСети"}/>, выдан ли такой сертификат на самом
                деле.</p>
            </>
    }
}
