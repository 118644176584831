import {The_hydra} from "./the_hydra";
import {The_cyllopus} from "./the_cyllopus";
import {Hartrhon} from "./hartrhon";
import {Vernon_cat} from "./vernon_cat";
import {CrumbsBlackSunCreatures} from "../../../../../components/Bread_Crumbs";
import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from "../../../../../components/cms/wiki_functions";
import {Vitruvianus} from "./vitruvianus";
import {Humans} from "./humans";

import wiki from "../../../../../components/cms/wiki.json";
import cats from "../../../../../components/cms/wiki_cats.json";
import {Firstborns} from "./firstborns";
import {HasSpoilers} from "../../../../../components/spoilers";

let list = {
    "vernon-cat": Vernon_cat,
    "hartrhon": Hartrhon,
    "the-cyllopus": The_cyllopus,
    "the-steel-hydra": The_hydra,
    "homo-vitruvianus": Vitruvianus,
    "homo-sapiens": Humans,
    "firstborns": Firstborns
}

/*
return {
        "homeworld": ,
        "size": ,
        "type": ,
        "bs_universe_biom": ,
        "pantheon": ,
        "text":
            <>
            </>
    }
 */

export function Creature(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_creatures"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Кидонианка и Черное солнце"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunCreatures name={name}/>

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>{name}</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="fas fa-chevron-right"></i> <strong>Родной
                                            мир: </strong> {data["homeworld"]}</li>
                                        <li><i className="fas fa-chevron-right"></i>
                                            <strong>Размеры:</strong>{data["size"]}</li>
                                        {data["langs"] ?
                                            <li><i className="fas fa-chevron-right"></i>
                                                <strong>Язык:</strong> {data["langs"]}</li>
                                            :
                                            ""
                                        }
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="fas fa-chevron-right"></i> <strong>Тип
                                            существа:</strong>{data["type"]}</li>
                                        {data["pantheon"] ?
                                            <li><i className="fas fa-chevron-right"></i>
                                                <strong>Пантеон:</strong>{data["pantheon"]}</li>
                                            :
                                            ""
                                        }
                                        {data["bs_universe_biom"] ?
                                            <li><i className="fas fa-chevron-right"></i> <strong><TextInlineLink
                                                name={"bs_universe_biom"}/>:</strong> {data["bs_universe_biom"]}</li>
                                            :
                                            ""
                                        }
                                        {data["state"] ?
                                            <li><i className="fas fa-chevron-right"></i>
                                                <strong>Государство:</strong> {data["state"]}</li>
                                            :
                                            ""
                                        }


                                    </ul>
                                </div>
                            </div>
                            {conns.length > 0 ? <p><strong>Связанные статьи:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}