import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Khal_vernon() {

    return {
        "date": <span>1480 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"}/></span>,
        "habitats": <span>16 млрд. (12 на поверхности)</span>,
        "people": <span>самборианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>3</span>,
        "g": <span>0.98 земной</span>,
        "atmosphere": <span><span className='badge bg-success'>подходит для людей</span></span>,
        "biosphere": <span>терраформирована под земную</span>,
        "system": <span>Вернонская</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "area": <span></span>,
        "imgs": [{"short": "bs_wiki_khal_vernon_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Хал-Вернон</span> – тот самый мир,
                    где делают легендарный самборианский (он же вернонский) виски, который ловкачи
                    навроде <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Ленисаада"}/> таскают по всей
                    галактике в обход таможни (официально это пойло мало кто разрешает провозить, чтобы не
                    поддерживать экономику <TextInlineLink name={"bs_state_free_worlds"} text={"Свободных миров"}/>).
                    Находится в системе Хала и Вернона, в глубине Свободных миров. Там очень короткая ночь, а
                    большая часть суток – это день или некое подобие рассвето-заката. А еще отсюда родом <TextInlineLink
                        name={"bs_spec_vernon_cat"} text={"вернонские кошки"}/>.</p>
            </>
    }
}

