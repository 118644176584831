import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ackron() {
    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "habitats": <span>211 млрд. (111 на поверхности)</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлири"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>3</span>,
        "g": <span>1.15 земной</span>,
        "atmosphere": <span className="text-success">подходит для людей</span>,
        "biosphere": <span>мертва</span>,
        "system": <span>-</span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Травалсар"}/></span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_ackron_nebulae"}/></span>,
        "imgs": [{"short": "bs_wiki_ackron_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Акрон</span> – мир в Доминации, в честь
                    которого названа Акронская небула.</p>
                <div className="alert alert-danger">
                    <p>Продолжение следует</p>
                </div>
            </>
    }
}
