import {Component} from 'react'
import {
    SidebarBSGallery, SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSUnenclosed, SidebarBSWiki
} from "../components/Sidebar"
import wiki from '../components/cms/wiki.json';

function PageInConstruction(){
    return (
        <>
            <PageClass />
        </>
    );
}

class PageClass extends Component{

    componentDidMount(){
        document.title = "Кидонианка и Черное солнце"
    }

  render(){
    return (
    <div>
      <div className="row" key="crumbs">
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item" aria-current="page"><a href={wiki["page_main"].link}>Главная</a></li>
            <li className="breadcrumb-item active" aria-current="page">Страница пока не готова</li>
        </ol>
        </nav>
      </div>
      <div key="mainRow" className="row">
        <div className="col-lg-8">
            <div className="row"><h1 className="title-bg">Упс, страница еще в процессе написания</h1></div>
            <div className="alert alert-danger">
                <p>Скоро она будет готова, честное слово!</p>
                <p>Пока предлагаем вернуться на <a className="ordinary" href={wiki["page_main"].link}>Главную</a> или в <a className="ordinary" href={wiki["bs_glossary"].link}>Энциклопедию</a>.
                </p>
            </div>
        </div>
          <div className='col-lg-4'>
              <div className="well">
                  <SidebarBSWiki />
                  <hr />
                  <SidebarBSTimeline />
                  <hr />
                  <SidebarBSMap />
                  <hr />
                  <SidebarBSGallery />
                  <hr />
                  <SidebarBSUnenclosed />
              </div>
          </div>
      </div>
    </div>
    )
  }
}

export { PageInConstruction };
