export function Somnambule() {
    return {
        "science": <span>биохимия</span>,
        "use": <span>спецслужбы, армия</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Сомнамбула</span> – коктейль для
                    ускорения всех видов обмена веществ и мозгового кровообращения, помогает заменить полноценный
                    ночной сон на 15-20 минут дремы, иногда в самых неожиданных позах. Принимают преимущественно
                    солдаты «в поле» – офицеры и пилоты любят более мягкие стимуляторы.</p>
            </>
    }
}
