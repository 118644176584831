import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Serpentara_emplumara() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong>
                        <span>криминальная организация</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Руководитель: </strong>
                        <span>барон <TextInlineLink name={"bs_char_hector_salinger"}/></span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Численность: </strong> <span>неизвестна</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Деятельность:</strong>
                        <span>пиратство, наемные убийства, частная охрана</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Штаб:</strong>
                        <span><TextInlineLink name={"bs_planet_samborae"} text={"Самбора"}/></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <img style={{"maxWidth": "300px", "float": "left"}} alt="Эмблема Пернатого змея"
                     src={imgs["bs_wiki_serpentara_emplumara"].link}
                     fetchpriority="low"/>
                <p className="card-text text-justify">
                    <span className="strong">Пернатый змей</span> (<TextInlineLink name={"bs_lang_samborian"}
                                                                                   text={"самборианск"}/>. Serpentara
                    emplumara) – синдикат,
                    промышляющий работорговлей, пиратством, торговлей наркотиками и оружием. Входит в <TextInlineLink
                    name={"bs_comp_free_trade_league"} text={"Лигу свободной торговли"}/>, считается ее
                    радикальным крылом и основной ударной силой.
                </p>
                <p className="card-text text-justify">
                    Точная дата основания неизвестна. История Змея начинается с небольшой шайки рэкетиров, промышлявших
                    на окраинных территориях <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                             text={"Доминации"}/>.
                    Некоторое время она действовала незаметно для айлирэнского правительства, ничем не выделяясь на фоне
                    коллег. Первое официальное упоминание о Змее можно найти в полицейских протоколах <TextInlineLink
                    name={"bs_planet_daydaris"} text={"Дейдариса"}/>, примерно за 130 лет до <TextInlineLink
                    name={"book_cydonian_girl"} text={"«Кидонианки»"}/>: <TextInlineLink name={"bs_people_ajliree"}
                                                                                         text={"айлири"}/> поймали
                    несколько очень самоуверенных молодых
                    людей с татуировками Уробороса на груди.
                </p>
                <p className="card-text text-justify">
                    Все они были казнены спустя несколько месяцев и дальше след теряется, пока примерно 40 лет назад
                    в <TextInlineLink name={"bs_state_free_worlds"} text={"Свободных мирах"}/>, на <TextInlineLink
                    name={"bs_planet_samborae"} text={"Самборе"}/>, не объявился Гектор Селинджер. Молодой
                    амбициозный пират устроил в столице планеты настоящую уличную войну и в жестоких боях
                    принудил <TextInlineLink name={"bs_comp_free_trade_league"} text={"Лигу свободной торговли"}/> не
                    только принять
                    его шайку, но и выделить ей щедрое финансирование.
                </p>
                <p className="card-text text-justify">
                    Около 20 лет назад Змей понес большие потери (в том числе репутационные) в боях с Кидонианским
                    военным флотом за <TextInlineLink name={"bs_space_samborian_traverse"}
                                                      text={"Самборианский траверс"}/>:
                    кидонианцы отвоевали у пиратов всю территорию траверса и уничтожили больше половины бойцов
                    синдиката. Тем не менее, он быстро восстановился и с тех пор вынашивает планы мести, усиливая
                    давление на пограничные территории <TextInlineLink name={"bs_state_cydonian_priority"}
                                                                       text={"Приоритета"}/>.
                </p>
                <div className="bg-image" style={{
                    "padding": "1em",
                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                }}>
                </div>
                <p className="card-text text-justify">
                    Ниже - портрет барона Селинджера собственной персоной.
                </p>
                <img style={{"maxWidth": "300px", "float": "left", "margin": "0.3em 0.3em 0 0"}}
                     alt="Барон Гектор Селинджер"
                     src={imgs["bs_char_hector_salinger_1_jpg_500"].link}
                     fetchpriority="low"/>

                <p className="card-text text-justify">
                    Как и полагается уважающей себя криминальной организации, все ее члены носят татуировку: изображение
                    Уробороса (змея, пожирающего свой хвост). Некоторые понимают название буквально и пытаются добавить
                    к нему еще и крылья (или, упаси <TextInlineLink name={"bs_creature_hydra"} text={"Гидра"}/>, перья
                    по всему
                    телу), но такие бойцы надолго в Синдикате не задерживаются.
                </p>
                <p className="card-text text-justify">
                    Основное кредо синдиката можно сформулировать как «Вместе навсегда»: его бойцы считают себя
                    настоящей семьей, даже проходят нечто вроде обряда братания, суть которого держится в тайне. Многие
                    попадают в него в раннем детстве и растут в атмосфере не только безоговорочного подчинения старшим
                    товарищам, но и полного доверия к другим солдатам Змея. С первых дней пребывания в организации ее
                    члены разделяются на отряды, внутри которых прививается кооперация, бескорыстная помощь и
                    взаимовыручка.
                </p>
                <p className="card-text text-justify">
                    Селинджер добился не просто лояльности подчиненных - он фактически стал для них отцом.
                    Полурелигиозная преданность позволила ему не только пережить тяжелое поражение от кидонианской
                    армии, но и научиться на ошибках. В ситуации, когда любой другой криминальный лидер теряет контроль,
                    а его банда разбегается по галактике, Змей сумел восстановить силы и численность за считанные годы.
                    Он также не утратил своего положения в Лиге свободной торговли - никто не решился бросить вызов
                    сумасшедшему синдикату.
                </p>
            </>
    }
}
