import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Bs_people_hellesians() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_hela"}/>,
        "state": <TextInlineLink name={"bs_state_cydonian_priority"}/>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_colbert_michel_1_jpg_200"}/></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_cydonian"} text={"кидонианский"}/>, <TextInlineLink
            name={"bs_lang_earth"} text={"земной"}/></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Хеллесианцы</span> – народ,
                    постепенно и бескровно ассимилированный <TextInlineLink name={"bs_people_cydonians"}
                                                                            text={"кидонианцами"}/> после образования
                    Приоритета.
                </p>
                <p className="card-text text-justify">
                    Из-за близости Хелы и <TextInlineLink name={"bs_planet_cydonia"} text={"Кидонии"}/>, а также
                    столичного шарма второй, у хеллесианцев не было шанса
                    сохранить идентичность. Сейчас об их существовании мало кто знает, часто называя кидонианцами.
                </p>
                <p className="card-text text-justify">
                    На Хеле никогда не было движения за независимость, поскольку заселили ее сами кидонианцы, еще
                    на <TextInlineLink name={"bs_timeline_light_era_dawn"} text={"Заре световой эпохи"}/>. Поэтому даже во
                    времена деспотии «зеленомордых», когда в первые годы
                    существования <TextInlineLink name={"bs_state_cydonian_priority"} text={"Приоритета"}/> элиты
                    пытались подмять под себя влияние во всех соседних мирах, в
                    системе <TextInlineLink name={"bs_star_hela_gemini"} text={"Хела-Джемини"}/> даже не требовалось
                    оставлять боевой флот.
                </p>
                <p className="card-text text-justify">
                    Характерной чертой современных хеллесианцев является высокий рост, иногда аномальный. Повышенная
                    гравитация родного мира плохо влияет на развитие человека, поэтому детям дают стимуляторы,
                    ускоряющие рост мышц и костей. Если в период действия препаратов ребенок переедет на планету
                    с более низкой гравитацией, он легко может вымахать на голову выше сверстников. От этого, например,
                    пострадал <TextInlineLink name={"bs_char_skarsgard_oliver"}/>.
                </p>
            </>
    }
}
