export function Amnesiac() {
    return {
        "science": <span>биохимия</span>,
        "use": <span>спецслужбы, психиатрия</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Амнезиаки</span> – препараты, нарушающие
                    работу памяти, которые могут стереть старые воспоминания или помешать человеку запомнить то, что он
                    только что увидел. Крайне дорогие и редкие вещества, запрещенные в любом виде в большинстве миров
                    галактики.
                </p>
            </>
    }
}
