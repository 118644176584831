import wiki from './cms/wiki.json';
import { SearchOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

const items = [
    {
        label:
        <a className="nav-link ordinary" href={wiki["page_main"].link}>
            <i className="fas fa-home"/>
        </a>,
        key: '/',
    },
    {
        label:
            <a className="nav-link ordinary" href={wiki["all_books"].link}>
                <i className="fa-solid fa-book"/> Книги
            </a>,
    },
    {
        label: <span className="nav-link ordinary"><i className="fa-solid fa-sun"/> Цикл «Черное солнце»</span>,
        key: 'bs_series_black_sun',
        children: [
            {
                label:
                    <a className="ordinary" href={wiki["bs_series"].link}>
                        О цикле
                    </a>,
                key: '/books/series/black_sun',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_cydonian_girl"].link}>
                        «Кидонианка»
                    </a>,
                key: '/black_sun/books/cydonian-girl',
            },
            {
                label:
                    <a className="ordinary" href={ wiki["book_hyperbeacons"].link}>
                        «Похитители маяков»
                    </a>,
                key: '/black_sun/books/hyperbeacons',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_cydonian_autumn"].link}>
                        «Кидонианская осень»
                    </a>,
                key: '/black_sun/books/cydonian-autumn',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_bonecollectors"].link}>
                        «Собиратели костей»
                    </a>,
                key: '/black_sun/books/bonecollectors',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_helleon_cold"].link}>
                        «Холодное солнце»
                    </a>,
                key: '/black_sun/books/helleon-cold',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_tamanrasett_contact"].link}>
                        «Контакт у Таманрасетт»
                    </a>,
                key: '/black_sun/books/the-tamanrasett-contact',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_the_free_traders"].link}>
                        «Вольные торговцы»
                    </a>,
                key: '/black_sun/books/the-free-traders',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_black_sun"].link}>
                        «Черное солнце»
                    </a>,
                key: '/black_sun/books/the-black-sun',
            },
        ],
    },
    {
        label: <span className="nav-link ordinary"><i className="fa-solid fa-info"/> Мир «Черного солнца»</span>,
        key: 'bs_glossary',
        children: [
            {
                type: 'group',
                label: 'Мир',
                children: [
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_glossary"].link}>
                                <i className="fa-solid fa-circle-question"/> Энциклопедия
                            </a>,
                        key: '/black_sun/glossary',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_characters"].link}>
                                <i className="fa-solid fa-user-tie"/> Герои
                            </a>,
                        key: '/black_sun/glossary/characters',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_timeline"].link}>
                                <i className="fa-solid fa-clock-rotate-left"/> История мира
                            </a>,
                        key: '/black_sun/glossary/timeline',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_maps"].link}>
                                <i className="fa-solid fa-map-location-dot"/> Карта мира
                            </a>,
                        key: '/black_sun/map',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_gallery"].link}>
                                <i className="fa-solid fa-image"/> Галерея
                            </a>,
                        key: '/black_sun/gallery',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_unenclosed"].link}>
                                <i className="fa-solid fa-info"/> Невошедшее
                            </a>,
                        key: '/black_sun/unincluded',
                    },
                ],
            },
        ],
    },
    {
        label: <a className="nav-link ordinary" href={"/search"}>
            <SearchOutlined /> Поиск
        </a>,
        key: 'bs_unincluded',
    },
];

export function MainMenu() {
    return <>
        <div style={{marginBottom: "1.5rem", alignItems: "center"}} key="menuMain">
            <Menu theme={"dark"} selectedKeys={['s']} mode="horizontal" items={items} />
        </div>
   </>
}