import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vernon_cat() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_khal_vernon"}/>,
        "size": <span>представь себе кошку</span>,
        "type": <span>одомашненное</span>,
        "bs_universe_biom": <span className='text-success'>совместимый с земным</span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Вернонская кошка</span> – существо,
                    сильно напоминающее формой тела и размерами земную кошку, но <span
                        className='strike'>не жрет обои</span> вместо
                    шерсти покрыто красновато-желтой, розовой или фиолетовой чешуей <span className='strike'>и не гадит в обувь</span>.
                    Вполне безобидно, но, если разозлить – кусается, как последняя тварь. Все
                    <TextInlineLink name={"bs_comp_free_trade_league"} text={"вольные торговцы"}/> держат вернонскую
                    кошку, но не все, кто держат вернонскую кошу – вольные торговцы!</p><p
                className='card-text text-justify'>А еще, они сбрасывают кожу, как земные змеи 🐉</p>
            </>
    }
}
