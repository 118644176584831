import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Grace_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_cassini"} /></span>,
        "people": <span>кидонианцы</span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Грация</span> – окраинная
                    часть Приоритета, один из самых малонаселенных регионов страны.</p>
            </>
    }
}

