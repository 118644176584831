import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Horus() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>желтый карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>7</span>,
        "habitable": <span>1</span>,

        "region": <span>Демократический космос</span>,
        "sector": <span>Виковира</span>,
        "area": <span>Туманность Малой короны</span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"}/></span>,
        "people": <span>агатонцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>288 млрд.</span>,
        "imgs": [{"short": "bs_wiki_horus_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Хор</span> – желтый карлик в трех
                    парсеках
                    от <TextInlineLink name={"bs_space_tiara_minor_nebulae"} text={"Туманности Малой короны"}/>. Дом для
                    Агатона и еще шести безжизненных планет. Все кроме Агатона сильно пострадали от людской
                    жадности: если смотреть на них из космоса, бедные шарики похожи на изъеденные червями яблоки
                    (если черви могут быть толщиной восемьсот километров).</p>
            </>
    }
}

