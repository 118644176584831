import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Millie_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографическая единица"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "capital": <span>-</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/></span>,

        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Сектор Милли</span> – кидонианская территория в <TextPopup
                    name={"bs_popups_perseus_arm"} text={"рукаве Персея"}/>, средних
                    размеров участок космоса, заполненный в основном промышленными и туристическими мирами. Здесь нет
                    крупных метрополий из-за отдаленности от столицы, зато очень много торговых маршрутов.
                </p>
                <p className='card-text text-justify'>
                    Через Милли проходит несколько веток <TextInlineLink name={"bs_tech_hyperrun"}
                                                                         text={"Киренайского пути"}/>, а также
                    множество других маршрутов, которые пользуются популярностью как у прилежных бизнесменов, так и у
                    мрачных дельцов <TextInlineLink name={"bs_comp_free_trade_league"}
                                                    text={"Лиги свободной торговли"}/>. Последние часто обустраивают в
                    секторе свои схроны,
                    используя
                    промышленные миры как перевалочные базы: обилие отработанной радиоактивной породы и низкая плотность
                    населения позволяют легко прятаться как от сканеров, так и от посторонних глаз.
                </p>
            </>
    }
}

