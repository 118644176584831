import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Great_vernon_and_khal() {
    return {
        "date": <span>1480 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>желтый карлик и красный гигант</span>,
        "stars": <span>2</span>,
        "planets": <span>8</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "people": <span>самборианцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>16 млрд.</span>,
        "imgs": [{"short": "bs_wiki_great_vernon_and_khal_map_ru", "big": "bs_wiki_oecumene_map_ru"}],
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className='card-text text-justify'><span className='strong'>Большой Вернон и Хал</span> – парные
                    недоблизняшки в Вернонской системе в глубине сектора Самбора. Красный гигант и желтый карлик.
                    Визуально они довольно
                    близко друг к другу, но на практике этого недостаточно, чтобы обмениваться веществом <span
                        className='strike'>если понимаешь, о чем я</span>. Дом планеты Хал-Вернон, где и варят «тот
                    самый» самборианский виски.</p>
            </>
    }
}

