export function Shield_atmospheric() {
    return {
        "science": <span>физика</span>,
        "use": <span>охрана труда, бытовой комфорт</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Атмосферный щит</span> – устройство,
                    создающее силовое поле определенной плотности, которое изолирует одну часть газовой среды от
                    другой. Обычно атмосферный щит дополняет отопление, потому что разворачивается по границе здания
                    и не дает холодному воздуху соприкасаться со стенами.</p>
                <p className="card-text text-justify">Более дорогие и энергозатратные версии щита заменяют собой
                    отопление,
                    нагревая или охлаждая воздух перед его попаданием в помещение. Обычно подобную систему могут
                    позволить
                    себе дорогие отели, а частные дома - почти никогда.</p>
            </>
    }
}
