import wiki from "../cms/wiki.json";
import imgs from "../cms/images_repo.json";
import {TextInlineLink} from "../cms/wiki_functions";

let planets = [
    {
        "link":wiki["bs_planet_tamanrasett"].link,
        "picture":imgs["tamanrasett_500"].link,
        "text":"Таманрасетт",
        "text_big":<span><strong>Таманрасетт</strong> – мир на самом краю Тернерианского предела, в системе Диконира, у самой периферии обжитых территорий</span>
    },
    {
        "link":wiki["bs_planet_arville"].link,
        "picture":imgs["arville_500"].link,
        "text":"Арвилль",
        "text_big":<span><strong>Арвилль</strong> – мифический мир где-то в Разломе, предположительно заселен несколько тысяч лет назад людьми, не имевшими с тех пор контакта с другими народами</span>
    },
    {
        "link":wiki["bs_planet_avvaline"].link,
        "picture":imgs["avvaline_500"].link,
        "text":"Аввалин",
        "text_big":<span><strong>Аввалин</strong> – планета-курорт в кидонианском секторе Ардемар, система Альфы Врат Анубиса</span>
    },
    {
        "link":wiki["bs_planet_cydonia"].link,
        "picture":imgs["cydonia_500"].link,
        "text":"Кидония",
        "text_big":<span><strong>Кидония</strong> – метрополия в секторе Ариадны, столица Кидонианского Приоритета</span>
    },
    {
        "link":wiki["bs_planet_new_havana"].link,
        "picture":imgs["new_havana_500"].link,
        "text":"Новая Гавана",
        "text_big":<span><strong>Новая Гавана</strong> – одна из неофициальных столиц Свободных миров и дом могущественных баронов Самборы</span>
    },
    {
        "link":wiki["bs_planet_valoria"].link,
        "picture":imgs["valoria_500"].link,
        "text":"Валория",
        "text_big":<span><strong>Валория</strong> – шахтерский мир в системе Бакарты в кидонианском секторе Морихей</span>
    }
]

export function BSRandomWikiPicturePlanet(){
    let pict = {}
    let pict_num = Math.floor(Math.random() * planets.length)
    pict = planets[pict_num]

    return (<>
        <div className="card">
            <a href={pict.link}>
                <div className=" card-img-top" style={{"width":"100%","height":"100%", "margin": "auto"}}>
                    <div className="bg-image" style={{"maxWidth":"100%","height":"100%","backgroundImage":"url("+imgs["background_stars_sm"].link+")"}}>
                        <img style={{"width":"100%"}}  src={pict.picture} alt={pict.text} />
                    </div>
                </div>
            </a>
                <div className="card-body">
                    <p className="card-text">{pict.text_big}</p>
                </div>
        </div>
        </>
    )
}


let characters = [
    {
        "link":wiki["bs_char_de_levine_rosalie"].link,
        "picture":imgs["bs_char_de_levine_rosalie_1_png_500_t"].link,
        "text":"Розали де Левин",
        "text_big":<span><strong>Розали де Левин</strong> – дочь <TextInlineLink name={"bs_char_the_whip"} text={"Бича"}  popup={""} /> и Клары де Левин, та самая кидонианка из <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"}  popup={""} /></span>
    },
    {
        "link":wiki["bs_char_ursula_adams"].link,
        "picture":imgs["bs_char_ursula_adams_1_jpg_500"].link,
        "text":"Урсула Адамс",
        "text_big":<span><strong>Урсула Адамс</strong> – автор «Путеводителя по Млечному Пути для перелетных птиц», героиня <TextInlineLink name={"book_bonecollectors"} text={"«Собирателей костей»"} popup={""} /> и <TextInlineLink name={"book_the_free_traders"} text={"«Вольных торговцев»"} popup={""} /></span>
    },
    {
        "link":wiki["bs_char_browdy_adam"].link,
        "picture":imgs["bs_char_browdy_adam_1_png_500"].link,
        "text":"Адам Броуди",
        "text_big":<span><strong>Адам Броуди</strong> – пират, контрабандист, аламарси, немного алкоголик</span>
    },
    {
        "link":wiki["bs_char_can_beucan_gaereas"].link,
        "picture":imgs["bs_char_can_beucan_gaereas_1_png_500"].link,
        "text":"Гэри кан Бьекен",
        "text_big":<span><strong>Гэри Бьекен</strong> – землянин, дуболом, дерзкий бородач, «планетарный пирожочек»</span>
    },
    {
        "link":wiki["bs_char_de_karma_ivar"].link,
        "picture":imgs["bs_char_de_karma_ivar_1_png_500"].link,
        "text":"Ивар де Карма",
        "text_big":<span><strong>Ивар де Карма</strong> – отставной кидонианский адмирал, рекламатор, специалист по затреванию на враждебных планетах</span>
    },
    {
        "link":wiki["bs_char_esora_eve"].link,
        "picture":imgs["bs_char_esora_eve_1_png_500"].link,
        "text":"Ева Эсора",
        "text_big":<span><strong>Ева Эсора</strong> – ворчливая и крайне опасная <TextInlineLink name={"bs_people_agathonians"} text={"агатонка"} popup={""} />, героиня  <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианки»"}  popup={""} />,  <TextInlineLink name={"book_cydonian_autumn"} text={"«Кидонианской осени»"}  popup={""} /> и других романов</span>
    },
    {
        "link":wiki["bs_char_hartrey_kellays"].link,
        "picture":imgs["bs_char_hartrey_kellays_1_png_500"].link,
        "text":"Келлес Хартри",
        "text_big":<span><strong>Келлес Хартри</strong> – инженер-интерсетевик, кидонианец, техногений</span>
    },
    {
        "link":wiki["bs_char_skarsgard_oliver"].link,
        "picture":imgs["bs_char_skarsgard_oliver_1_png_500"].link,
        "text":"Оливер Скошгард",
        "text_big":<span><strong>Оливер Скошгард</strong> – руководитель ударного крыла Эсквариата Её Величества королевы Адель ван Глории</span>
    },
    {
        "link":wiki["bs_char_the_whip"].link,
        "picture":imgs["bs_char_the_whip_1_png_500"].link,
        "text":"Бич",
        "text_big":<span><strong>Бич</strong> – пришелец-нудист, щуплый, но очень агрессивный, герой всех частей «Черного солнца»</span>
    }
]

export function BSRandomWikiPictureCharacter(){
    let pict = {}
    let pict_num = Math.floor(Math.random() * characters.length)
    pict = characters[pict_num]

    return (<>
            <div className="card">
                <a href={pict.link}>
                    <div className=" card-img-top" style={{"width":"100%","height":"100%", "margin": "auto"}}>
                        <div className="bg-image" style={{"maxWidth":"100%","height":"100%","backgroundImage":"url("+imgs["background_stars_sm"].link+")"}}>
                            <img style={{"width":"100%"}}  src={pict.picture} alt={pict.text} />
                        </div>
                    </div>
                </a>
                <div className="card-body">
                    <p className="card-text">{pict.text_big}</p>
                </div>
            </div>
        </>
    )
}
