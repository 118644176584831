import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hiveship() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"астромеханика"}/>, социология, психология</span>,
        "use": <span>космические полеты</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Улей</span> – особый тип корабля,
                    предназначенный для жизни огромного количества людей. Обычно представляет собой полноценное
                    государство со своими традициями, социальными проблемами, культурными особенностями и другими
                    небольшими отличиями, которые делают одно человеческое общество непохожим на другое.</p><p
                className="card-text text-justify">Такую машину сложнее построить, чем любой боевой кресер: мощность
                систем жизнеобеспечения и их дубликатов должна быть колоссальной. А чтобы он мог обогнать свет,
                потребуются целая ферма реакторов и дублирующих цепей питания, способных с помощью <TextInlineLink
                    name={"bs_tech_mpg"} text={"ГМТ"}/> защитить хрупкое человеческое тело от
                перегрузок.</p><p className="card-text text-justify">Первые ульи были неповоротливыми машинами,
                способными лишь поддерживать жизнь в хрупкой закрытой экосистеме. Спустя долгие века
                эволюции <TextInlineLink name={"bs_people_alamarsie"} text={"аламарси"}/> превратили их в настоящие
                летающие миры-крепости: сегодня улей не только поддерживает жизнь десятков или сотен тысяч людей, но
                и способен в одиночку выдержать напор целого флота, попутно давая сдачи. Даже без флота поддержи
                такая машина умеет собирать ресурсы с астероидов и производить количество еды, в несколько раз
                превышающее потребности.</p><p className="card-text text-justify">Матерые пираты редко решаются
                напасть на корабль-улей: огневая мощь некоторых летающих государств способна стереть с лица
                галактики пиратский флот за считанные часы, а поддержка пилотов-алмарси, накачанных ноотропами,
                делает подобную миссию практически самоубийственной.</p><p className="card-text text-justify">В
                современной истории есть только один известный случай успешной атаки пиратов на улей:
                корабль <TextInlineLink name={"bs_char_browdy_adam"} text={"«Анираадха»"}/> попал под массированный
                налет
                синдиката <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатый змей"}/> и был
                практически уничтожен. Хотя потери среди бандитов оказались настолько высоки, что они больше никогда
                не решались повторить подобный «подвиг».</p>
            </>
    }
}
