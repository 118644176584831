export function Space_force() {
    return {
        "type": <span>род войск</span>,
        "use": <span>штурм планет, абордаж, охрана</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Космическая пехота</span> (коспехи) –
                    стандартный род войск для эпохи широкой космической экспансии. Фактически, она заменяет любые
                    другие виды пехоты: коспехи служат на кораблях, охраняют наземные и космические постройки,
                    штурмуют вражеские суда и объекты на поверхности планет.</p>
                <p className="card-text text-justify">Подразделяется на множество ветвей и специализаций в зависимости
                от страны. Отдельно стоит выделить штурмовую пехоту: тяжело бронированные штурмовые батальоны
                занимают города и важные объекты на планетах, в условях, когда поддержка флота невозможна или
                ограничена. Обычно это происходит в районах с густой растительностью, электромагнитными штормами или
                сильными снежными бурями.</p>
            </>
    }
}
