import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hartrhon() {
    return {
        "homeworld": <span>неизвестно</span>,
        "size": <span>неизвестно</span>,
        "type": <span>мифологическое</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} text={"Артурианство"}/></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Гартрон</span> – исполинский ни то
                    паук, ни то осьминог, ни то медуза. Никаких «официальных» сведений о нем не сохранилось, только
                    упоминания в фольклоре аламарси. Сложно сказать, существовал(-ет) ли он когда-то в глубинах
                    космоса или был выдуман. Ему приписывали способность путешествовать между планетами и пожирать
                    органическую жизнь и электронику – сильное заявление, ничего не скажешь. Некоторые фанаты
                    мифологии до сих пор верят, что он где-то там, подстерегает одинокие корабли на гипертрассах.
                    Они же рассказывают, что Гартрона создала некая древняя могучая цивилизация, которую он
                    благополучно съел.</p>
            </>
    }
}
